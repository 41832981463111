import React, { useEffect, useState } from "react";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { LuReplyAll } from "react-icons/lu";
import { MdOutlineCancelScheduleSend } from "react-icons/md";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Style from "../broadcast.module.css";
import useChatMessage from "src/components/apps/liveChat/hooks/useChatMessage";
import { useSelector } from "react-redux";
import { pickFirstLetters } from "src/utils/Validator";
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";


const statusMapping = {
  SENT: { label: "Sent", icon: <CheckIcon /> },
  DELIVERED: { label: "Delivered", icon: <DoneAllIcon /> },
  READ: { label: "Read", icon: <RemoveRedEyeOutlinedIcon /> },
  REPLIED: { label: "Replied", icon: <LuReplyAll /> },
  FAILED: { label: "Failed", icon: <MdOutlineCancelScheduleSend /> },
};

const BroadCastDetailCard = ({ data, index, rowPerPage, page, cardStatus, chatLoading, chatConvirations, fetchRepliedChat }) => {

  const navigate = useNavigate();


  const userObj = useSelector((state) => state.auth.user);

  const { fetchAssignUsers, allAssignUser } = useChatMessage();

  useEffect(() => { fetchAssignUsers(); }, [])


  const [showWidth, setShowWidth] = useState(false);
  const status = data?.messageStatus;
  const statusInfo = statusMapping[status];
  const [anchorEl, setAnchorEl] = useState(null);

  const handlerpopover = (event, id) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopOverClose = () => { setAnchorEl(null); };


  const openpopover = Boolean(anchorEl);
  const id = openpopover ? "simple-popover" : undefined;

  function TooltipSpilitName(name, maxLength) {
    if (name?.length > maxLength) {
      return name?.substring(0, maxLength - 3) + "...";
    } else {
      return name;
    }
  }

  const toggleWidth = (chatId) => {
    fetchRepliedChat(chatId);
    setShowWidth((prevState) => !prevState);
  };

  useEffect(() => {
    if (cardStatus) { setShowWidth(true); }
    else { setShowWidth(false); }
  }, [cardStatus])

  function truncateText(str, maxLength) {
    if (str?.length > maxLength) {
      return str?.slice(0, maxLength) + '...';
    }
    return str;
  }


  return (
    <>
      <tr>
        <td>{(page - 1) * rowPerPage + index + 1}</td>
        <td>

          {
            data?.contactDeletedAt !== null && <div className="d-flex align-items-center"> <small className="batge bg-danger rounded-pill text-white px-2" title={moment(data?.contactDeletedAt).format('DD MMM, YYYY hh:mm A')}>Removed</small></div>
          }

          {
            data?.contactDeletedAt === null && <div className="d-flex align-items-center"> <span className={Style.contact_name} title={data?.leadContactId?.name}>{data?.leadContactId?.name}</span>
              <span className={Style.contact_phone}>(<span>{data?.leadContactId?.phone}</span>)</span></div>
          }

        </td>
        <td style={{ textTransform: "capitalize", width: "80px" }}>
          <div className={Style.contact} style={{ background: "none", cursor: "default" }}>
            <div className={Style.contact_tag} style={{ overflow: "hidden", }}>
              {data?.contactDeletedAt !== null && "NA"}

              {data?.leadContactId?.tags?.length > 0 && (
                <>
                  {data?.leadContactId?.tags?.slice(0, 2)?.map((tag, index) => {
                    const formattedTagValue = tag?.replace(/-/g, ' ');
                    return (
                      <div key={index} className={Style.tag_box2}>
                        <Tooltip title={formattedTagValue?.toString()}>
                          <span className={Style.TootltipName}>
                            {TooltipSpilitName(formattedTagValue?.toString().toLowerCase(), 20)}
                          </span>
                        </Tooltip>
                      </div>
                    );
                  })}
                  {data?.leadContactId?.tags?.length > 2 && (
                    <div style={{ cursor: "pointer" }} onClick={(e) => { handlerpopover(e); }}
                      className={Style.tag_box2}> +{data?.leadContactId?.tags?.length - 2} more</div>
                  )}
                  <Popover
                    id={id}
                    open={openpopover}
                    anchorEl={anchorEl}
                    onClose={handlePopOverClose}

                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                    style={{ marginLeft: '4px' }}
                    slotProps={{
                      paper: {
                        sx: {
                          overflow: "visible", boxShadow: "none",
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: "50%",
                            left: -7,
                            width: 16,
                            height: 16,
                            background: "white",
                            transform: "translateY(-50%) rotate(45deg)",
                            // boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
                            borderLeft: "2px solid #ececec",
                            borderBottom: "2px solid #ececec",
                          },
                        },
                      },
                    }}
                  >
                    <div className={Style.appointment_popover}>

                      {data?.leadContactId?.tags?.slice(2)?.map((chip, index) => (
                        <>
                          <Chip
                            key={index}
                            label={<Tooltip title={chip?.toString()}>
                              <span className={Style.TootltipName}>
                                {TooltipSpilitName(chip?.toString().toLowerCase(), 20)}
                              </span>
                            </Tooltip>}
                            variant={index === 1 ? "outlined" : "outlined"}
                            sx={{
                              marginRight: "8px",
                              backgroundColor: "#4EA3FF42",
                              color: "#00336B",
                              border: 'none',
                              marginBottom: '10px',
                              '& .MuiChip-deleteIcon': {
                                // color: '#D1E7FF', // If you want to adjust the delete icon color
                              },
                            }}
                          />
                        </>
                      ))}

                    </div>
                  </Popover>
                </>
              )}
            </div>
          </div>
        </td>
        <td className={Style.BoradcastBody1}>
          {data?.contactDeletedAt !== null ? <div className={" d-flex align-items-center"}>NA</div> :
            <div className={" d-flex align-items-center"}>
              <div className={Style.detail_status + " d-flex align-items-center w-100"} style={{ cursor: "pointer" }}>
                <div className={Style.detail_status + " d-flex align-items-center"} style={{ cursor: "pointer" }}>
                  {statusInfo?.icon && (<div style={{ marginRight: "8px" }}>{statusInfo?.icon}</div>)}
                  {statusInfo?.label && <div style={{ fontSize: "12px", marginTop: "3px" }}>{statusInfo?.label}</div>}
                </div>
                {statusInfo?.label === "Replied" && <div onClick={() => {
                  toggleWidth(data?.chatId)
                }} className={Style.message + " d-flex align-items-center justify-content-between w-100"}>
                  <span title={data?.repliedMessage} style={{ color: '#2379F0' }}>{truncateText(data?.repliedMessage, 16)}</span>
                  <KeyboardArrowDownIcon />
                </div>}
              </div>
            </div>
          }
        </td>
        <td>
          {(data?.timestamps?.sentAt || data?.timestamps?.failedAt || data?.timestamps?.deliveredAt || data?.timestamps?.readAt || data?.timestamps?.repliedAt) ?
            <span title={moment(data?.timestamps?.sentAt || data?.timestamps?.failedAt || data?.timestamps?.deliveredAt || data?.timestamps?.readAt || data?.timestamps?.repliedAt).format('DD MMM, YYYY hh:mm A')}>{moment(data?.timestamps?.sentAt || data?.timestamps?.failedAt || data?.timestamps?.deliveredAt || data?.timestamps?.readAt || data?.timestamps?.repliedAt).format('DD MMM, YYYY hh:mm A')}</span> : "NA"}
        </td>
      </tr>

      {showWidth && (
        <tr>
          <td colSpan="5" className={Style.replied_row}>
            <div className="d-flex align-items-center justify-content-end">
              <div onClick={() => navigate(`/livechat?chatId=${data?.chatId}`)} className={Style.overview_box + " d-flex align-items-center bg-white rounded-pill "} style={{ width: "45px", width: "45px", cursor: "pointer", }}><RemoveRedEyeOutlinedIcon fontSize="50" /></div>
            </div>
            {chatConvirations?.chatMessages && chatConvirations?.chatMessages?.map((chatMessage) => {
              const isUserMessage = ([userObj, { _id: userObj?.agency }, ...allAssignUser,]?.filter((data) => data?._id === chatMessage?.sender)?.length === 0) && (chatMessage?.isBot !== true);;

              return isUserMessage ? (<div className={Style.chatleft_box}>

                <div className={Style.chat_message_avatar}>
                  <div> {pickFirstLetters(data?.leadContactId?.name)}</div>
                </div>
                <div className={Style.chatleft}>
                  <div className={Style.chatMessage_left_messageContent}
                    dangerouslySetInnerHTML={{
                      __html: chatMessage?.message?.replace(
                        /\n/g,
                        " <br/> "
                      ),
                    }}></div>
                  <div className={Style.chatMessage_left_messageinfo}>
                    {moment(chatMessage?.createdAt).format("hh:mm A")}
                  </div>
                </div>
              </div>) :
                (<div className={Style.chatright_box}>
                  <div className={Style.chatright}>
                    <div
                      className={Style.chatMessage_messageContent}
                      dangerouslySetInnerHTML={{
                        __html: chatMessage?.message?.replace(
                          /\n/g,
                          " <br/> "
                        ),
                      }}></div>
                    <div className={Style.chatMessage_messageinfo}>
                      {moment(chatMessage?.createdAt).format("hh:mm A")}
                      <span>
                        <DoneAllIcon />
                      </span>
                    </div>
                  </div>
                </div>
                )
            })
            }
            <div >
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default BroadCastDetailCard;
