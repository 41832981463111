import React from "react";
import { TextField } from "@mui/material";

const TextInput = ({
  customClass,
  type,
  value,
  onChange,
  placeholder,
  variant,
  name,
  onBlur,
  minDate,
  min,
  label,
  multiline,
  rows,
  size,
  disabled,
  onFocus,
  branch,
  onKeyDown
}) => {
  return (
    <TextField
      id="outlined-basic"
      name={name}
      className={`${customClass}`}
      size={size ? size : ""}
      type={type}
      value={value}
      variant={variant ? variant : "outlined"}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      multiline={multiline ? true : false}
      minRows={rows}
      placeholder={placeholder}
      autoComplete="off"
      label={label ? label : ""}
      // InputLabelProps={(type === "date" || type === "time") && { shrink: true }}
      InputLabelProps={type === "date" || type === "time" ? { shrink: true } : {}}
      disabled={disabled}
      InputProps={
        type === "number"
          ? { inputProps: { min: min ? min : 0 } }
          : type === "date"
            ? { inputProps: { min: minDate ? minDate : "" } }
            : type === "tel"
              ? {
                inputProps: {
                  inputMode: "numeric",
                  maxLength: 13,
                  minLength: 10,
                  number: true,
                },
              }
              : {}

      }

      sx={{
        // Remove border-radius for all variants of TextField
        "& .MuiOutlinedInput-root": {
          borderRadius: branch === "Branch" ? 0 : "4px",
        },
        ...(variant === "standard" && {
          // input:{
          //   color:"#787F85",
          //   fontSize:"17px",
          //   marginBottom:"15px"
          // },
          // "& .MuiInput-underline:before": {
          //   borderBottomColor: "#a259ff", // color of the underline when inactive
          //   borderWidth:"2px"
          // },
          // "& .MuiInput-underline:after": {
          //   borderBottomColor: "#a259ff", // color of the underline when active
          //   borderWidth:"2px"
          // },
          // "& .MuiInput-underline:hover:before": {
          //   borderBottomColor: "#a259ff", // color of the underline on hover
          //   borderWidth:"2px"
          // },
          // Add additional styles for the 'standard' variant if needed
        }),
      }}
      onKeyDown={onKeyDown}
    />
  );
};

export default TextInput;
