import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "src/utils/axios";
import Toast from "src/components/custom/Toast";

const useBroadcast = () => {
  const navigate = useNavigate();
  const [broadcastData, setBroadcastData] = useState(null);
  const [broadcastList, setBroadcastList] = useState(null);
  const [broadcastCount, setBroadcastCount] = useState(null);
  const [broadcastTotalCount, setBroadcastTotalCount] = useState(null);
  const [contactTags, setContactTags] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [broadcastDetailData, setBroadcastDetaildata] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [iscontactloading, setIscontactloading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [chatConvirations, setChatConvirations] = useState(false);
  const [contactUsers, setContactUsers] = useState([]);

  const [broadcastTag, setBroadcastTage] = useState(null)

  const fetchBroadcastData = async () => {
    setIsDataLoading(true);
    try {
      const response = await Axios.get(`/whatsapp/broadcast/create?isActive=true`);
      setBroadcastData(response?.data?.data || []);
      setIsDataLoading(false);
    } catch (error) {
      Toast(error, "error");
      setIsDataLoading(false);
    }
  };

  const fetchBroadcastList = async (search, sortBy, selectedRange, fromDate, toDate, page, limit) => {
    let params = {
      search: search,
      sortBy: sortBy,
      ...(selectedRange !== "custom" && { days: selectedRange }),
      startDate: fromDate,
      endDate: toDate,
      page: page,
      limit: limit
    }
    setIsLoading(true);
    try {
      const response = await Axios.get(`/whatsapp/broadcast-new`, { params });
      setBroadcastList(response?.data?.data?.broadcast || []);
      setBroadcastCount(response?.data?.data?.totalStats);
      setBroadcastTotalCount(response?.data?.data?.total);
      setIsLoading(false);
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };

  const handleAddBroadcast = async (values) => {
    setIsLoading(true);
    try {
      await Axios.post(`whatsapp/broadcast-new`, values);
      Toast('Broadcast Added successfully.', "success");
      navigate('/broadcast-new');
      setIsLoading(false);
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };

  const fetchBroadcastDetail = async (id, status, search, page, limit) => {
    let updateStatus = "";
    if (status === "all") {
      updateStatus = "";
    }
    else {
      updateStatus = status;
    }
    let params = {
      campaignId: id,
      ...(updateStatus && { status: updateStatus }),
      ...(search && { search: search }),
      page: page,
      limit, limit
    }
    setIsLoading(true);
    try {
      const response = await Axios.get(`/whatsapp/broadcast-new/details`, { params });
      setBroadcastDetaildata(response?.data?.data || {});
      setIsLoading(false);
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };

  const fetchRepliedChat = async (chatId) => {
    setChatLoading(true)
    try {
      const response = await Axios.get(`/whatsapp/broadcast-repliedchat?chatId=${chatId}`);
      setChatConvirations(response?.data?.data || {});
      setChatLoading(false);
    } catch (error) {
      Toast(error, "error");
      setChatLoading(false);
    }
  }

  const handleDelete = (id, setShowdelete, handleBroadcastData) => {
    setIsDeleting(true);

    Axios.post(`/whatsapp/broadcast-new/inactive`, { campaignId: id })
      .then(() => {
        setShowdelete(false);
        handleBroadcastData();
        Toast("Broadcast deleted successfully.", "success");
      })
      .catch((err) => {
        Toast(err, "error");
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const getContactList = (filters, search) => {
    setIscontactloading(true);
    // Prepare query string based on filters
    const params = new URLSearchParams();

    params.append('city', filters.displayCity);
    params.append('postalCode', filters.postCode);
    params.append('location', filters.location);
    params.append('radius', filters.radius);
    params.append('furnishing', filters.furnishType);

    if (filters.minBathroom < filters.maxBathroom || filters.maxBathroom === 0 || filters.minBathroom === 0) {
      params.append('maxBathrooms', filters.maxBathroom);
      params.append('minBathrooms', filters.minBathroom);
    }
    if (filters.minBedroom < filters.maxBedroom || filters.minBedroom === 0 || filters.maxBedroom === 0) {
      params.append('minBedrooms', filters.minBedroom);
      params.append('maxBedrooms', filters.maxBedroom);
    }
    if (filters.minPrice < filters.maxPrice || filters.minPrice === 0 || filters.maxPrice === 0) {
      params.append('minPrice', filters.minPrice);
      params.append('maxPrice', filters.maxPrice);
    }

    if (filters.priceType) params.append('priceType', filters.priceType);
    // Add search tags (array)
    if (filters?.propertyType && filters?.propertyType?.length > 0) {
      filters.propertyType?.forEach(data => {
        params.append('propertyType[]', data);  // Appends each tag as 'tags[]'
      });
    }
    // Add search tags (array)
    if (filters?.tags && filters?.tags?.length > 0) {
      filters.tags.forEach(tag => {
        params.append('tags[]', tag);  // Appends each tag as 'tags[]'
      });
    }

    // Add search term if present
    params.append('search', search);

    Axios.get(`/whatsapp/broadcast/get-contacts?${params.size > 0 ? params.toString() : ""}`)
      .then((response) => {
        setContactUsers(response.data.data);
      })
      .catch((err) => {
        Toast(err, "error");
      })
      .finally(() => {
        setIscontactloading(false);
      });
  };


  return {
    handleAddBroadcast,
    fetchBroadcastData,
    isLoading,
    broadcastData,
    fetchBroadcastList,
    broadcastList,
    broadcastCount,
    broadcastTotalCount,
    contactTags,
    fetchBroadcastDetail,
    broadcastDetailData,
    isDataLoading,
    handleDelete,
    isDeleting,
    broadcastTag,
    setBroadcastTage,
    getContactList,
    iscontactloading,
    contactUsers,
    setContactUsers,
    chatLoading,
    chatConvirations,
    fetchRepliedChat
  };
};
export default useBroadcast;
