import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import Downshift from "downshift";
import CloseIcon from "@mui/icons-material/Close";

export default function BroadcastTag({
    tags,
    setValue,
    handleDelete,
    rows,
    multiline,
    handleText,
    customClass,
    fieldCustomClass,
    ...props
}) {
    const { placeholder, ...other } = props;

    const [inputValue, setInputValue] = useState("");

    const CustomChip = styled(Chip)({
        backgroundColor: "#F5F9FA",
        color: "#33475B",
        fontFamily: "poppins",
        fontWeight: "500",
        fontSize: "12px",
        marginRight: "5px",
        border: "1px solid #668195",
        borderRadius: "3px",
        height: "25px",
    });

    const CloseStyle = styled(CloseIcon)({
        fill: "#33475B",
        fontSize: "10px",
        width: "18px",
    });

    function handleKeyDown(event) {
        if (event.key === "Enter" && inputValue.trim()) {
            event.preventDefault(); // Prevent form submission or any default behavior
            if (!tags?.includes(inputValue.trim())) {
                setValue(inputValue?.trim()); // Add the new tag
            }
            setInputValue(""); // Clear the input
        }
    }

    return (
        <React.Fragment>
            <Downshift
                id="downshift-single"
                inputValue={inputValue}
                onChange={(item) => setValue(item)}
            >
                {({ getInputProps }) => {
                    const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                        placeholder,
                    });

                    return (
                        <div className={""} style={{ position: "relative" }}>
                            <TextField
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: tags?.map((tag, index) => (
                                        <CustomChip
                                            key={index}
                                            tabIndex={-1}
                                            label={tag}
                                            onDelete={() => handleDelete(tag)}
                                            deleteIcon={<CloseStyle />}
                                        />
                                    )),
                                    onBlur,
                                    onChange: (event) => {
                                        setInputValue(event.target.value);
                                        onChange(event);
                                    },
                                    onFocus,
                                    onKeyDown: handleKeyDown, // Handle "Enter" keypress
                                }}
                                {...other}
                                {...inputProps}
                                size="small"
                                multiline={multiline ? true : false}
                                minRows={rows}
                                className={customClass}
                                value={inputValue} // Set input value
                            />
                        </div>
                    );
                }}
            </Downshift>
        </React.Fragment>
    );
}
