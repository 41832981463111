import React, { useEffect, useState } from "react";
import { useFormik, FormikProvider, FieldArray, Formik } from "formik";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as Yup from "yup";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import styles from "../chatbot.module.css";
import useChatbotAction from "../hooks/useChatbotAction";
import { CHATBOT_NODE_TYPE } from "src/constants/chatbotConstants";
import CustomIconButton from "src/components/custom/CustomIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { WaitingTime, agentType, propertyType } from "src/constants/formPicker";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import RestoreIcon from "@mui/icons-material/Restore";
import { Col, Row } from "reactstrap";
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from "react-redux";
import { removeTextInParentheses } from "src/helper/commonHelp";

const AddNodeModal = ({ toggleAddChildForm, orgData, selectedNode, closeModal, onSuccess, setPublishedChatbot,
  categories, categoriesProperty, chatbotId, chatBotData, agencyData, captureFields }) => {

  const branchList = useSelector((state) => state.auth.branchList);
  const { loading, addNewNodetoDB } = useChatbotAction();
  const [error, setError] = useState(false);
  const [isLocationNotExists, setIsLocationNotExists] = useState(false);
  const [isvisitorNotExists, setIsvisitorNotExists] = useState(false);
  const [ispropertyNotExists, setIspropertyNotExists] = useState(false);
  const [updatedCustomFields, setUpdatedCustomFields] = useState([])

  const handleAddChild = async (values, { resetForm }) => {

    if (selectedNode?.nodeType === values?.nodeType &&
      (values?.nodeType === "live-chat" || values?.nodeType === "capture-location" || values?.nodeType === "capture-user-details" || values?.nodeType === "arrange-callback" || values?.nodeType === "schedule-valuation")) {
      setError("You cannot create another node of this type. Please choose a different node type.");
      return 0;
    }

    let locationvalue = false;
    let propertyValue = false;
    let visitorValue = false;

    if (values?.nodeType === "capture-visitor") {

      if (!await checkNodeVisitorValue(selectedNode)) {
        setError("You cannot create another node of capture visitor type.");
        return 0;
      }
    }

    if (values?.nodeType === "capture-property") {

      if (! await checkNodePropertyValue(selectedNode)) {
        setError("You cannot create another node of capture property type.");
        return 0;
      }
    }

    if ((values?.nodeType === "live-chat" || values?.nodeType === "arrange-callback" || values?.nodeType === "schedule-valuation" || values?.nodeType === "property-viewing") && chatBotData.chatbotPurpose === "inbound" && branchList?.length > 1) {

      if (chatBotData.category === "all") {
        visitorValue = await checkNodeVisitorValue(selectedNode);
        setIsvisitorNotExists(visitorValue);

        if (categoriesProperty?.length > 1) {
          propertyValue = await checkNodePropertyValue(selectedNode);
          setIspropertyNotExists(propertyValue);
        }
      }

      if (agencyData?.branches?.length > 1) {
        locationvalue = await checkNodeTypeLocation(selectedNode);
        setIsLocationNotExists(locationvalue);
      }
    }

    if (!locationvalue && !visitorValue && !propertyValue) {
      const newNodeObj = await addNewNodetoDB(orgData, values, selectedNode, chatbotId);

      if (newNodeObj) {
        onSuccess(newNodeObj);
        setPublishedChatbot(false);
        resetForm();
        formikAddChild.handleReset();
        setError(false);
      }
    }
    else { return 0; }

  };

  const handleRemoveOption = (index) => {
    const newOptions = [...formikAddChild.values.questionOptions];
    newOptions.splice(index, 1); // Remove the option at the specified index
    formikAddChild.setFieldValue("questionOptions", newOptions); // Update formik values with the new options
  };

  const addNodeValidateSchema = Yup.object().shape({
    nodeDesc: Yup.string().when("nodeType", {
      is: (value) => value === "live-chat",
      then: () => Yup.string().required("Online message is required.")
        .test(
          "not-start-with-space",
          "Online message cannot start with a space.",
          (value) => {
            return !value || !value.startsWith(" ");
          }
        ),
      otherwise: () => Yup.string().when("nodeType", {
        is: (value) => (value !== "capture-user-details" && value !== "property-details"),
        then: () => Yup.string().required("Description is required.")
          .test(
            "not-start-with-space",
            "Description cannot start with a space.",
            (value) => {
              return !value || !value.startsWith(" ");
            }
          ),
        otherwise: () => Yup.string().notRequired()
      }),
    }),
    offlineMessage: Yup.string().when("nodeType", {
      is: (value) => value === "live-chat",
      then: () => Yup.string().required("Offline message is required.")
        .test(
          "not-start-with-space",
          "Offline message cannot start with a space.",
          (value) => {
            return !value || !value.startsWith(" ");
          }
        ),
      otherwise: () => Yup.string().notRequired(), // Node Description is not required
    }),

    waitingTime: Yup.string().when("nodeType", {
      is: (value) => value === "live-chat",
      then: () => Yup.number().min(1, 'Waiting time must be at least 1 minute.').required('Waiting time is required for live chat.'),
      otherwise: () => Yup.string().notRequired(), // Node Description is not required
    }),
    nodeType: Yup.string().required("Type is required."),
    questionOptions: Yup.array().when("nodeType", {
      is: (value) => ["question", "capture-visitor", "capture-property"].includes(value),
      then: () =>
        Yup.array().of(
          Yup.object().shape({
            questionDesc: Yup.string().required("Option is required.").test(
              'is-trimmed',
              'Option should not have leading or trailing spaces.',
              value => value === value.trim()
            ),
          })
        ).min(1, "At least one option is required."),
    }),
    attributes: Yup.object().shape({
    }),
    customFieldTitle: Yup.string().when("isCustomField", {
      is: true,
      then: () => Yup.string().required("Capture lable is required."),
      otherwise: () => Yup.string().notRequired(), // Node custom field is not required
    }),
  });

  const formikAddChild = useFormik({
    initialValues: {
      nodeDesc: "",
      nodeType: selectedNode?.nodeType === "live-chat" || selectedNode?.nodeType === "arrange-callback" || selectedNode?.nodeType === "schedule-valuation" || selectedNode?.nodeType === "property-viewing" ? "closing" : "",
      questionOptions: [{ questionDesc: "", questionType: "" }],
      attributes: {
        isEmail: false,
        emailAddress: "",
      },
      waitingTime: 0,
      offlineMessage: "",
      isCustomField: false,
      customFieldTitle: ""
    },
    validationSchema: addNodeValidateSchema,
    onSubmit: handleAddChild,
  });

  const handleAddOption = () => {
    formikAddChild.setValues({
      ...formikAddChild.values,
      questionOptions: [
        ...formikAddChild.values.questionOptions,
        { questionDesc: "", questionType: (formikAddChild.values.nodeType === "capture-property" && categoriesProperty?.length === 1) ? categoriesProperty[0] : "" },
      ],
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const questionOptionsCopy = [...formikAddChild.values.questionOptions];
    const [removed] = questionOptionsCopy.splice(result.source.index, 1);
    questionOptionsCopy.splice(result.destination.index, 0, removed);
    formikAddChild.setFieldValue("questionOptions", questionOptionsCopy);
  };

  const findNodeById = (data, id) => {
    for (let node of data) {
      if (node._id === id) { return node };
      if (node.children) {
        const result = findNodeById(node.children, id);
        if (result) { return result };
      }
    }
    return null;
  }

  const getNodeVisitorValue = async (selectedNode) => {
    let currentNode = selectedNode;

    while (currentNode.parentId) {
      const parentNode = findNodeById(orgData, currentNode.parentId);
      if (parentNode) {
        if (parentNode.nodeType === 'capture-visitor') {
          return currentNode?.visitorCategory;
        };
        currentNode = parentNode;
      } else {
        break;
      }
    }
    return false;
  }

  const checkNodeVisitorValue = async (selectedNode) => {
    let currentNode = selectedNode;
    if (currentNode.nodeType === 'capture-visitor') {
      return false;
    }
    while (currentNode.parentId) {
      const parentNode = findNodeById(orgData, currentNode.parentId);
      if (parentNode) {
        if (parentNode.nodeType === 'capture-visitor') { return false; };
        currentNode = parentNode;
      } else { break; }
    }
    return true;
  }

  const checkNodePropertyValue = async (selectedNode) => {
    let currentNode = selectedNode;
    if (currentNode.nodeType === 'capture-property') {
      return false;
    }
    while (currentNode.parentId) {
      const parentNode = findNodeById(orgData, currentNode.parentId);
      if (parentNode) {
        if (parentNode.nodeType === 'capture-property') { return false; };
        currentNode = parentNode;
      } else { break; }
    }
    return true;
  }

  const checkNodeTypeLocation = async (selectedNode) => {
    let currentNode = selectedNode;
    if (currentNode.nodeType === 'capture-location') {
      return false;
    }
    while (currentNode.parentId) {
      const parentNode = findNodeById(orgData, currentNode.parentId);
      if (parentNode) {
        if (parentNode.nodeType === 'capture-location') { return false; };
        currentNode = parentNode;
      } else { break; }
    }
    return true;
  }

  useEffect(() => {
    if (toggleAddChildForm) {
      formikAddChild.handleReset();
      setError(false);
      setIsLocationNotExists(false);
      setIspropertyNotExists(false);
      setIsvisitorNotExists(false);
    }
  }, [toggleAddChildForm]);

  useEffect(() => {
    const { nodeType } = formikAddChild.values;
    if (nodeType !== "live-chat" && nodeType !== "arrange-callback" && nodeType !== "schedule-valuation" && nodeType !== "property-viewing") {
      setIsLocationNotExists(false);
      setIspropertyNotExists(false);
      setIsvisitorNotExists(false);
    }
  }, [formikAddChild.values.nodeType]);

  function findParent(tree, childId, parents = []) {
    // Add the current node to the parents array
    const updatedParents = [...parents, tree];
    // If the current node has children, search for the child in its children
    if (tree.children) {
      for (const child of tree.children) {
        // If the child is found in this branch, return the current parents array
        if (child?._id === childId) {
          return updatedParents;
        }
        // Recursively search in the children of the current node
        const parent = findParent(child, childId, updatedParents);
        // If the child is found in this branch, return its parent
        if (parent.length > 0) {
          return parent;
        }
      }
    }
    // If the child is not found in this branch, return an empty array
    return [];
  }

  useEffect(() => {

    if (findParent(orgData[0], selectedNode?._id)?.filter((node) => node.nodeType === 'question').length === 1 && categoriesProperty?.length === 1) {
      formikAddChild.setFieldValue('questionOptions', [{ questionDesc: "", questionType: categoriesProperty[0] }])
    }
    formikAddChild.setFieldValue('nodeType', selectedNode?.nodeType === "live-chat" || selectedNode?.nodeType === "arrange-callback" || selectedNode?.nodeType === "schedule-valuation" || selectedNode?.nodeType === "property-viewing" ? "closing" : "",)
  }, [selectedNode])

  useEffect(() => {
    setError(false)
  }, [formikAddChild.values.nodeType])

  const updatecaptureFields = async () => {
    if (captureFields && captureFields?.length > 0) {
      const visitorType = await getNodeVisitorValue(selectedNode);

      if (visitorType) {
        setUpdatedCustomFields(captureFields?.filter((data) => data?.userType?.includes(visitorType))?.map((data) => {
          return { ...data, displayField: { ...data.displayField, label: removeTextInParentheses(data.displayField.label) } }
        }))
      } else {
        setUpdatedCustomFields(captureFields)
      }
    }
  }

  useEffect(() => {
    updatecaptureFields()
  }, [captureFields, selectedNode])

  return (
    <Dialog
      open={toggleAddChildForm}
      onClose={closeModal}
      className={styles.modal_wrapper}
    >
      <FormikProvider value={formikAddChild}>
        <div
          className={styles.modal_cancel}
          onClick={() => {
            closeModal(false);
            formikAddChild.handleReset();
            setError(false);
          }}
        >
          <CloseOutlinedIcon />
        </div>
        <form onSubmit={formikAddChild.handleSubmit}>
          <DialogTitle className={styles.modal_head}>Add Option</DialogTitle>
          <DialogContent className={styles.modal_inner}>
            <label className={styles.custom_label}>Select Type</label>
            <TextField
              select
              label=""
              variant="outlined"
              name="nodeType"
              disabled={selectedNode?.nodeType === "live-chat" || selectedNode?.nodeType === "arrange-callback" || selectedNode?.nodeType === "schedule-valuation" || selectedNode?.nodeType === "property-viewing"}
              autoComplete="off"
              value={formikAddChild.values.nodeType}
              onChange={formikAddChild.handleChange}
              error={
                formikAddChild.touched.nodeType &&
                Boolean(formikAddChild.errors.nodeType)
              }
              helperText={
                formikAddChild.touched.nodeType &&
                formikAddChild.errors.nodeType
              }
              defaultValue=""
              className={styles.custom_field}
              fullWidth
              SelectProps={{ MenuProps: { sx: { height: "300px" } } }}
            >
              <MenuItem value="">Select Type</MenuItem>
              {CHATBOT_NODE_TYPE.filter((row) => {
                const shouldExclude = categoriesProperty?.length === 1 && row.value === 'capture-property';
                return row.visible && !shouldExclude; // Exclude based on condition
              }).map((row, index) => (
                <MenuItem key={`ndtk${index}`} value={row.value}>
                  {row.name}
                </MenuItem>
              ))}

            </TextField>


            {(formikAddChild.values.nodeType === "message") && <label title="Click to save the response for future use. Please enter a descriptive title in the field below to identify the response easily." className={styles.custom_label + " pointer d-flex align-items-center mt-1 mb-2"}>
              <input type="checkbox" name='isCustomField' className="me-2"
                checked={formikAddChild?.values?.isCustomField}
                onChange={formikAddChild.handleChange} />
              Capture Field <ReportGmailerrorredIcon style={{ fontSize: "18px", marginLeft: "3px" }} />
            </label>}

            {formikAddChild?.values?.isCustomField && <div>

              <TextField
                select
                label=""
                variant="outlined"
                name={`customFieldTitle`}
                autoComplete="off"
                value={formikAddChild.values.customFieldTitle}
                onChange={formikAddChild.handleChange}
                placeholder="Add the field name"
                error={
                  formikAddChild.touched?.customFieldTitle &&
                  Boolean(formikAddChild.errors?.customFieldTitle)
                }
                helperText={
                  formikAddChild.touched?.customFieldTitle &&
                  formikAddChild.errors?.customFieldTitle
                }
                defaultValue=""
                className={styles.custom_field}
                fullWidth
                SelectProps={{ MenuProps: { sx: { height: "300px" } } }}
              >
                <MenuItem value="">Select Field</MenuItem>
                {updatedCustomFields?.map((row, index) =>
                  <MenuItem key={`ndtk${index}`} value={row?._id}>
                    {row?.displayField?.label}
                  </MenuItem>
                )}
              </TextField>
            </div>
            }

            {formikAddChild.values.nodeType !== "capture-user-details" && (
              <div
                className={
                  formikAddChild.values.nodeType === "live-chat"
                    ? styles.livechat_msg + " d-flex mt-2 position-relative"
                    : ""
                }
              >
                {formikAddChild.values.nodeType === "live-chat" && (
                  <ModeCommentIcon />
                )}
                <div className="w-100">
                  {formikAddChild.values.nodeType === "live-chat" && (
                    <label className={styles.custom_label}>
                      Online Message
                    </label>
                  )}
                  <TextField
                    name={`nodeDesc`}
                    className={"mt-2 " + styles.custom_error}
                    value={formikAddChild.values.nodeDesc}
                    onChange={formikAddChild.handleChange}
                    fullWidth
                    autoComplete="off"
                    rows={3}
                    multiline
                    placeholder={formikAddChild.values.nodeType === "live-chat" ? "Enter online message" : "Description"}
                    error={
                      formikAddChild.touched?.nodeDesc &&
                      Boolean(formikAddChild.errors?.nodeDesc)
                    }
                    helperText={
                      formikAddChild.touched?.nodeDesc &&
                      formikAddChild.errors?.nodeDesc
                    }
                  />
                </div>
                {formikAddChild.values.nodeType === "live-chat" && (
                  <div className={styles.line}></div>
                )}
              </div>
            )}

            {formikAddChild.values.nodeType === "live-chat" && (
              <>
                <div
                  className={
                    styles.livechat_msg + " d-flex mt-3 position-relative"
                  }
                >
                  <RestoreIcon />
                  <div className="w-100">
                    <label className={styles.custom_label}>Waiting Time</label>
                    <TextField
                      select
                      label=""
                      variant="outlined"
                      name="waitingTime"
                      autoComplete="off"
                      value={formikAddChild.values.waitingTime}
                      onChange={formikAddChild.handleChange}
                      error={
                        formikAddChild.touched.waitingTime &&
                        Boolean(formikAddChild.errors.waitingTime)
                      }
                      helperText={
                        formikAddChild.touched.waitingTime &&
                        formikAddChild.errors.waitingTime
                      }
                      defaultValue=""
                      className={styles.custom_field}
                      fullWidth
                      SelectProps={{ MenuProps: { sx: { height: "300px" } } }}
                    >
                      <MenuItem value={0}>Select time</MenuItem>
                      {WaitingTime.map((row, index) => (
                        <MenuItem key={`ndtkc${index}`} value={row.value}>
                          {row.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className={styles.dashed_line}></div>
                </div>
                <div className={styles.livechat_msg + " d-flex mt-2"}>
                  <ModeCommentIcon />
                  <div className="w-100">
                    <label className={styles.custom_label}>
                      Offline Message
                    </label>
                    <TextField
                      name={`offlineMessage`}
                      // className={styles.option_field}
                      className={"mt-2 " + styles.custom_error}
                      value={formikAddChild.values.offlineMessage}
                      onChange={formikAddChild.handleChange}
                      fullWidth
                      autoComplete="off"
                      rows={3}
                      multiline
                      placeholder="Enter offline message"
                      error={
                        formikAddChild.touched?.offlineMessage &&
                        Boolean(formikAddChild.errors?.offlineMessage)
                      }
                      helperText={
                        formikAddChild.touched?.offlineMessage &&
                        formikAddChild.errors?.offlineMessage
                      }
                    />
                  </div>
                </div>
              </>
            )}

            {(formikAddChild.values.nodeType === "question" || formikAddChild.values.nodeType === "capture-visitor" || formikAddChild.values.nodeType === "capture-property") && (
              <>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="questionOptions">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <FieldArray name="questionOptions">
                          {({ remove }) => (
                            <div>
                              {formikAddChild.values.questionOptions.map(
                                (option, index) => (
                                  <Draggable
                                    key={index}
                                    draggableId={`questionDesc-${index}`}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={styles.edit_wrap}
                                      >
                                        <div className={styles.modal_main + " d-flex align-items-start mt-3"}>
                                          <div className="ms-0 d-flex align-items-center me-1 mt-2">
                                            <CustomIconButton
                                              size={22}
                                              fontSize={15}
                                              color="#7e7979"
                                              icon={<MenuIcon />}
                                            />
                                          </div>
                                          <div className="w-100">
                                            <Row className="  align-items-start">
                                              <Col>
                                                <TextField
                                                  name={`questionOptions.${index}.questionDesc`}
                                                  className={styles.custom_field}
                                                  // className={styles.option_field}
                                                  value={option.questionDesc}
                                                  onChange={formikAddChild.handleChange}
                                                  autoComplete="off"
                                                  error={
                                                    formikAddChild.touched
                                                      .questionOptions &&
                                                    formikAddChild.errors
                                                      .questionOptions &&
                                                    formikAddChild.errors
                                                      .questionOptions[index]
                                                      ?.questionDesc
                                                  }
                                                  helperText={
                                                    (formikAddChild.touched
                                                      .questionOptions &&
                                                      formikAddChild.errors
                                                        .questionOptions &&
                                                      formikAddChild.errors
                                                        .questionOptions[index]
                                                        ?.questionDesc) ||
                                                    ""
                                                  }
                                                  fullWidth
                                                  size="small"
                                                />
                                              </Col>
                                              {formikAddChild.values.nodeType !== "question" && <Col xs="12" md="5" lg="5" className="ps-0 ">
                                                <TextField
                                                  select
                                                  label=""
                                                  className={styles.custom_field}
                                                  variant="outlined"
                                                  name={`questionOptions.${index}.questionType`}
                                                  autoComplete="off"
                                                  value={option.questionType}
                                                  onChange={formikAddChild.handleChange}
                                                  error={
                                                    formikAddChild.touched
                                                      .questionOptions &&
                                                    formikAddChild.errors
                                                      .questionOptions &&
                                                    formikAddChild.errors
                                                      .questionOptions[index]
                                                      ?.questionType
                                                  }
                                                  helperText={
                                                    (formikAddChild.touched
                                                      .questionOptions &&
                                                      formikAddChild.errors
                                                        .questionOptions &&
                                                      formikAddChild.errors
                                                        .questionOptions[index]
                                                        ?.questionType) ||
                                                    ""
                                                  }
                                                  defaultValue=""
                                                  fullWidth
                                                  SelectProps={{ MenuProps: { sx: { height: "300px" } } }}
                                                  size="small"
                                                >
                                                  <MenuItem value="">Select {formikAddChild.values.nodeType === "capture-visitor" ? "customer type" : "property type"}</MenuItem>
                                                  {formikAddChild.values.nodeType === "capture-visitor" ? agentType?.map((row, index) => (
                                                    categories.includes(row?.type) && <MenuItem key={`ndtkc${index}`} value={row.value}>
                                                      {row.label}
                                                    </MenuItem>
                                                  )) : propertyType?.map((row, index) => (
                                                    categoriesProperty.includes(row?.value) && <MenuItem key={`ndtkc${index}`} value={row.value}>
                                                      {row.label}
                                                    </MenuItem>
                                                  ))}
                                                </TextField>
                                              </Col>}



                                            </Row>
                                          </div>
                                          <div className="ms-1 d-flex align-items-center mt-2">
                                            <CustomIconButton
                                              size={22}
                                              fontSize={18}
                                              color="#454545"
                                              icon={<DeleteIcon />}
                                              onClick={() =>
                                                handleRemoveOption(index)
                                              }
                                              disabled={
                                                formikAddChild.values.questionOptions.length === 1
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )}
                            </div>
                          )}
                        </FieldArray>

                        {(!Array.isArray(formikAddChild?.errors?.questionOptions)) && formikAddChild?.errors?.questionOptions && (
                          <div style={{ color: "#e52d2d", fontSize: "0.95rem" }}>
                            {formikAddChild?.errors?.questionOptions}
                          </div>
                        )}


                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {(formikAddChild.values.nodeType !== "capture-property" || categoriesProperty?.length !== 1 || formikAddChild?.values?.questionOptions?.length === 0) && <Button
                  type="button"
                  onClick={handleAddOption}
                  className={styles.add_option}
                >
                  + Add option
                </Button>}
              </>
            )}
            {(error || isLocationNotExists || isvisitorNotExists || ispropertyNotExists) && (
              <div
                style={{
                  color: "#e52d2d",
                  fontSize: "0.75rem",
                }}
              >
                {error ? error : isvisitorNotExists ? "Please add visitor type node first." : ispropertyNotExists ? "Please add property type node first." : isLocationNotExists ? "Please add location node first." : ""}
              </div>
            )}

          </DialogContent>

          <DialogActions>
            <Button type="submit" className={styles.modal_btn} disabled={loading}>
              Save
            </Button>
          </DialogActions>
        </form>
      </FormikProvider>
    </Dialog>
  );
};

export default AddNodeModal;
