import { useState } from "react";
import Axios from "src/utils/axios";

const useChatbotAction = () => {

  const [loading, setLoading] = useState(false)

  const addNewNodeToTree = (tree, targetNode, newNode ,chatbotId) => {
    if (!tree) return tree;

    return tree.map((node) => {
      if (node._id === targetNode._id) {
        return {
          ...node,
          children: [newNode],
        };
      } else if (node.children) {
        return {
          ...node,
          children: addNewNodeToTree(node.children, targetNode, newNode ,chatbotId),
        };
      }
      return node;
    });
  };

  const updateNodeToTree = (tree, targetNode, updatedNode ,chatbotId) => {
    if (!tree) return tree;
    return tree.map((node) => {
      if (node._id === targetNode._id) {
        return { ...node, ...updatedNode };
      } else if (node.children) {
        return {
          ...node,
          children: updateNodeToTree(node.children, targetNode, updatedNode,chatbotId),
        };
      }
      return node;
    });
  };


  const addNewNodetoDB = async (orgData, frmValue, selectedNode,chatbotId) => {
    try {
      setLoading(true);

      const formData = {
        ...(selectedNode && selectedNode._id
          ? { parentId: selectedNode._id }
          : {}),
        nodeType: frmValue.nodeType,
        nodeDesc: frmValue.nodeDesc.trim(),
        ...(frmValue.questionOptions && frmValue.questionOptions.length && (frmValue.nodeType === 'question' || frmValue.nodeType === 'capture-visitor' || frmValue.nodeType === 'capture-property')
          ? { options: frmValue.questionOptions }
          : {}),
        attributes: frmValue.attributes,
        waitingTime: frmValue.waitingTime,
        offlineMessage: frmValue?.offlineMessage,
        customFieldTitle: frmValue?.isCustomField ? frmValue?.customFieldTitle : "",
        chatbotId:chatbotId,
        isMiddle: selectedNode?.children?.length > 0
      };

      const nodeAddObj = await Axios.post(`/chatbots`, formData);

      if (nodeAddObj.data?.data && nodeAddObj.data?.data._id) {
        let newNodeData = nodeAddObj.data?.data;


        if (!selectedNode) {
          setLoading(false)
          return [newNodeData];
        } else {
          if (
            !selectedNode.children ||
            !selectedNode.children.some(
              (node) => node.nodeDesc === newNodeData.nodeDesc
            )
          ) {
            const newAddedNodeData = addNewNodeToTree(orgData, selectedNode, newNodeData);
            setLoading(false)
            return newAddedNodeData;
          }
        }
      }
    } catch (err) {
      setLoading(false)
    }
    return orgData;
  };

  const updateNodetoDB = async (orgData, frmValue, selectedNode , chatbotId) => {
    try {
      setLoading(true);

      const formData = {
        ...(selectedNode && selectedNode._id
          ? { parentId: selectedNode._id }
          : {}),
        nodeType: frmValue.nodeType,
        nodeDesc: frmValue.nodeDesc.trim(),
        customFieldTitle: frmValue?.isCustomField ? frmValue?.customFieldTitle : "",
        ...(frmValue.questionOptions && frmValue.questionOptions.length && (frmValue.nodeType === 'question' || frmValue.nodeType === 'capture-visitor' || frmValue.nodeType === 'capture-property')
          ? { options: frmValue.questionOptions }
          : {}),
        attributes: frmValue.attributes,
        waitingTime: frmValue.waitingTime,
        offlineMessage: frmValue.offlineMessage,
        chatbotId:chatbotId,
      };

      const nodeEditObj = await Axios.put(`/chatbots/${selectedNode._id}`, formData);

      if (nodeEditObj.data?.data && nodeEditObj.data?.data._id) {
        let newNodeData = nodeEditObj.data?.data;
        const newNodeDatatree = updateNodeToTree(orgData, selectedNode, newNodeData);
        setLoading(false)
        return newNodeDatatree;
      }
    } catch (err) {
      setLoading(false)
      console.log(`Error occured while adding the chatbot node. ${err.message}`);
    }
    return orgData;
  };

  return {
    loading,
    addNewNodetoDB,
    updateNodetoDB,
  };
};

export default useChatbotAction;
