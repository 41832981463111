import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Style from "./AccountSetup2.module.css";
import WorkingHours from "./WorkingHours";
import { ButtonLoader, ButtonLoaderLeft } from "src/components/forms/ButtonLoader";
import BranchDetailForm from "./BranchDetailForm";
import Axios from "src/utils/axios";
import Toast from "src/components/custom/Toast";
import ErrorMsg from "src/components/custom/ErrorMsg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import TextInput from "src/components/forms/TextInput";
import useProperty from "src/components/apps/property/hooks/useAddproperty";
import { Grid } from "@mui/material";
import { FaPlus } from "react-icons/fa6";
import { DISTANCE_MILES_OPTIONS } from "../../../constants/propertyConstant";
import { debounce } from "src/helper/commonHelp";
import SelectInput from "src/components/forms/SelectInput";
import Tooltip from "@mui/material/Tooltip";
import Chip from '@mui/material/Chip';
import CloseIcon from "@mui/icons-material/Close";
import { styled } from '@mui/material/styles';
import { Popper, List, ListItem, ListItemText, Paper, ClickAwayListener } from '@mui/material';
import CommonCheckbox from "src/components/forms/CommonCheckbox";
const BranchDetailCard = ({
  index,
  totalCount,
  handleNext,
  isDisabled,
  handleBranchSubmit,
  currentBranch,
  handleAgencyData,
  agency,
  branch,
  handleOpenBranch,
  singleBranch,
  handleBranchList,
  getBranch,
  isEdit,
  // handleWebsite
  settings,

}) => {

  const { setPlacesData, placesData, fetchPlacesList, fetchPostCodeData, setPostCodeValue, postcodeValue, PlaceLocation, fetchGooglePlacesList, setPlaceLocation, getPostCodeValidate } = useProperty();


  // const { values, setFieldValue } = useFormikContext();
  const navigate = useNavigate();
  const isBranchInAgency = index < (agency?.branches?.length || 0);
  const [isLoading, setIsLoading] = useState(false);
  // const [collapsed, setCollapsed] = useState(isBranchInAgency ? true : false);
  const [collapsed, setCollapsed] = useState([]);
  const [showList, setShowList] = useState(false);
  const [showPostCode, setShowPostCode] = useState(false)
  const [error, setError] = useState("");
  const [radiuslocError, setRadiusLocError] = useState('');
  const [LocationData, setLocationData] = useState({});
  const [validPostCodeMsg, setValidPostCodeMsg] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeLocIndex, setActiveLocIndex] = useState(-1);
  const [activeBranchIndex, setActiveBranchIndex] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [activeLocBranch, setActiveLocBranch] = useState(null);
  const [anchorEl, setanchorEl] = useState(null);
  const checkOverlappingDays = (workingHours) => {
    const daysMap = {
      "everyday": ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"],
      "mon-fri": ["monday", "tuesday", "wednesday", "thursday", "friday"],
      "sat-sun": ["saturday", "sunday"]
    };

    const selectedDays = new Set();

    for (const hours of workingHours) {
      const { day } = hours;
      if (day in daysMap) {
        for (const mappedDay of daysMap[day]) {
          if (selectedDays.has(mappedDay)) {
            return false; // Overlapping day found
          }
          selectedDays.add(mappedDay);
        }
      } else {
        if (selectedDays.has(day)) {
          return false; // Overlapping day found
        }
        selectedDays.add(day);
      }
    }

    return true;
  };

  const initialValues = {
    companyWebsite: agency?.companyUrl,
    branches: agency?.branches?.length > 0 ? agency?.branches?.map(branch => ({
      _id: branch?._id,
      branchName: branch?.branchName || "",
      branchWebsite: branch?.branchWebsite || "",
      dealsWith: branch?.dealsWith ? (branch?.dealsWith.length > 1 ? "both" : branch?.dealsWith[0]) : "residential",
      typeOfAgency: branch?.typeOfAgency ? (branch?.typeOfAgency.length > 1 ? "both" : branch?.typeOfAgency[0]) : "both",
      displayLoc: branch?.servingLocation?.displayLoc || [],
      location: branch?.location,
      workingHours: branch?.workingHours || [{ day: "", opening: "", closing: "" }],
      isLeadAssignmentRestricted: branch?.isLeadAssignmentRestricted || false,
      postCodeObj: {
        radius: "0",
        locValue: '',
        locType: "zip",
        displayPostCode: [],
        PostError: "",
        AlreadyPostError: '',

      },
      locationObj: {
        radius: "0",
        locValue: '',
        locType: "loc",
        displayPostCode: [],
        locationError: "",
        locationAlreadyError: "",
      },

    })) : branch?.map(() => ({
      postCodeObj: {
        radius: "0",
        locValue: "",
        locType: "zip",
        displayPostCode: [],
        PostError: "",
        AlreadyPostError: ''

      },
      locationObj: {
        radius: "0",
        locValue: "",
        locType: "loc",
        displayPostCode: [],
        locationError: "",
        locationAlreadyError: "",

      },
      isLeadAssignmentRestricted: false,
      branchName: "",
      branchWebsite: "",
      dealsWith: "residential",
      typeOfAgency: "both",
      displayLoc: [],
      location: { displayAddress: "" },
      workingHours: [{ day: "", opening: "", closing: "" }]
    }))
  };


  const validationSchema = Yup.object().shape({
    companyWebsite: Yup.string()
      .test("is-valid-url", "Invalid URL format.", (value) => {
        if (!value) return false; // If value is empty, fail validation

        // Check if URL has www or not
        if (!/^(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[a-z]{2,})(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?$/i.test(value)) {
          return false;
        }
        return true; // Passes all validations
      })
      .required("Agency website is required."),
    branches: Yup.array().of(
      Yup.object().shape({
        branchName: Yup.string()
          .required("Branch name is required.")
          .test("not-start-with-space", "Branch name cannot start with a space.", (value) => !value.startsWith(" ")),
        branchWebsite: Yup.string().test("is-valid-url", "Invalid URL format.", (value) => {
          if (!value) return true;
          return /^(?:https?:\/\/)?(?:www\.)?[\w-]+(?:\.[a-z]{2,})(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?(?:\.[a-z]{2,})?$/i.test(value);
        }),
        dealsWith: Yup.string().required("Deals with is required."),
        typeOfAgency: Yup.string().required("Type of agency is required."),
        // location: Yup.object().shape({
        //   displayAddress: Yup.string().required("Branch address is required.")
        // }),
        workingHours: Yup.array().of(
          Yup.object().shape({
            day: Yup.string().required("Day is required."),
            opening: Yup.string()
              .nullable()
              .test("is-valid-opening", "Opening time is required if closing time is provided.", function (value) {
                const { closing } = this.parent;
                if (closing && !value) return false;
                return true;
              }),
            closing: Yup.string()
              .nullable()
              .test("is-after-opening", "Closing time must be after opening time.", function (value) {
                const { opening } = this.parent;
                if (!opening || !value) return true;
                return moment(value, "HH:mm").isAfter(moment(opening, "HH:mm"));
              })
              .test("is-valid-closing", "Closing time is required if opening time is provided.", function (value) {
                const { opening } = this.parent;
                if (opening && !value) return false;
                return true;
              }),
          })
        ).min(1, "At least one working hours entry is required.")
          .test("no-overlapping-days", "There are overlapping days in the working hours.", (workingHours) => checkOverlappingDays(workingHours)),
        displayLoc: Yup.array().min(1, "At least one postal code is required."),
      })
    ).required("At least one branch is required.")
  });


  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {

      if (isEdit) {
        navigate("/users?branch=created")
      }

      let response = await Axios.post(`/AccountSetup/${agency?.branches?.length > 0 ? "update-branch-new" : "create-branch-new"}`, { ...values, ...(settings && { settings }) });


      if (settings) {
        Toast(`Branch ${agency?.branches?.length > 0 ? 'updated successfully' : "created successfully"} `, "success");
      }
      handleAgencyData(response?.data?.data);
      setIsLoading(false);

      if (settings !== true) {
        handleNext();
      }


    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };


  const handleDelete = (item, branchIndex, type, setFieldValue, values) => {
    const updatedDisplayLoc = values.branches[branchIndex].displayLoc.filter(
      loc => !(loc.locValue === item && loc.locType === type)
    );

    setFieldValue(`branches[${branchIndex}].displayLoc`, updatedDisplayLoc);
  };
  // Helper function to check if any working hours entry has empty fields
  const hasIncompleteWorkingHours = (workingHours) => {
    return workingHours?.every(({ day, opening, closing }) => day && opening && closing);
  };
  const handleCurrentBranch = () => {
    handleOpenBranch(index);
  }

  const toggleSection = (section, index) => {
    setCollapsed(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const userTxtChangeDebounce = useCallback(
    debounce((txtval) => fetchGooglePlacesList(txtval), 800),
    []
  );

  const fetchPlaceDetail = (place_id) => {
    Axios.get(`/places/place-detail-by-address-id?place_id=${place_id}`)
      .then((res) => {
        setLocationData(res)
      })
      .catch((err) => {
        setError(err);
      });
  };

  const handleLocationClick = (location, setFieldValue, branchIndex) => {
    if (location) {
      fetchPlaceDetail(location.place_id, setFieldValue, branchIndex);
    }
    setPlaceLocation([]);
    setShowList(false);
  };
  const userPostCodeDebounce = useCallback(
    debounce((txtval) => fetchPostCodeData(txtval), 800),
    []
  );

  const handlerButtonCode = async (branchIndex, setFieldValue, formikValues) => {
    const radiusValue = formikValues.branches[branchIndex].locationObj.radius;
    const locValueData = formikValues.branches[branchIndex].locationObj.locValue;
    if (!locValueData) {
      setFieldValue(`branches[${branchIndex}].locationObj`, {
        radius: "0",
        locValue: '',
        locType: "loc",
        displayPostCode: [],
        locationError: "Please select location"
      })
      return;
    }
    const lat = LocationData?.data?.data?.address?.coordinates?.lat;
    const lng = LocationData?.data?.data?.address?.coordinates?.lng;
    const northeast_lat = LocationData?.data?.data?.raw?.geometry?.viewport?.northeast?.lat;
    const northeast_lng = LocationData?.data?.data?.raw?.geometry?.viewport?.northeast?.lng;
    const southwest_lng = LocationData?.data?.data?.raw?.geometry?.viewport?.southwest?.lng;
    const southwest_lat = LocationData?.data?.data?.raw?.geometry?.viewport?.southwest?.lat;

    const newPostcodeEntry = {
      locValue: LocationData?.data?.data?.address?.locName,
      locType: "loc",
      displayPostCode: [],
      radius: formikValues.branches[branchIndex].locationObj.radius,
      geoCoordinates: {
        type: 'Point',
        coordinates: [lng, lat]
      },
      geoViewport: {
        southwest: [northeast_lat, northeast_lng],
        northeast: [southwest_lat, southwest_lng]
      },
    };
    // Check if the location is already in displayLoc
    const isLocationAlreadySelected = formikValues.branches[branchIndex].displayLoc.some(
      (entry) => entry.locValue === newPostcodeEntry.locValue
    );

    if (isLocationAlreadySelected) {
      setFieldValue(`branches[${branchIndex}].locationObj`, {
        radius: radiusValue,
        locType: "loc",
        displayPostCode: [],
        locationAlreadyError: "This location is already selected",
        locValue: LocationData?.data?.data?.address?.locName,
      })
      return;
    }

    if (formikValues.branches[branchIndex].locationObj.locValue !== "") {
      setFieldValue(`branches[${branchIndex}].displayLoc`, [newPostcodeEntry, ...formikValues.branches[branchIndex].displayLoc]);
    }
    setFieldValue(`branches[${branchIndex}].locationObj`, {
      radius: "0",
      locValue: '',
      locType: "loc",
      displayPostCode: [],
    })

  };


  const handlerButtonCodeNew = async (branchIndex, setFieldValue, formikValues) => {
    const radiusValue = formikValues.branches[branchIndex].postCodeObj.radius;
    const locValueData = formikValues.branches[branchIndex].postCodeObj.locValue;
    if (!locValueData) {
      setFieldValue(`branches[${branchIndex}].postCodeObj`, {
        PostError: "Please select Postcode",
        locType: "zip",
        displayPostCode: [],
        radius: "0",
      })
      return;
    }
    setShowPostCode(false);
    const lowerCaseLocValueData = locValueData.toLowerCase();
    const lastDisplayLoc = formikValues.branches[branchIndex].displayLoc.find(
      (entry) => entry.locValue.toLowerCase() === lowerCaseLocValueData
    );
    if (lastDisplayLoc) {
      setFieldValue(`branches[${branchIndex}].postCodeObj`, {
        AlreadyPostError: "This postcode is already selected",
        locType: "zip",
        displayPostCode: [],
        radius: radiusValue,
        locValue: locValueData,
      })
      return;
    }

    let responseData = await Axios.post('/postCode/validate', {
      postCode: locValueData,
      isPostCode: formikValues.branches[branchIndex].postCodeObj.locType === "zip"
    });

    if (formikValues.branches[branchIndex].postCodeObj.locValue !== "") {
      if (responseData?.data?.postCodes?.length > 0) {
        setFieldValue(`branches[${branchIndex}].displayLoc`, [formikValues.branches[branchIndex].postCodeObj, ...formikValues.branches[branchIndex].displayLoc]);
      } else {
        setValidPostCodeMsg("This post code is not valid, please enter again.")
        setFieldValue(`branches[${branchIndex}].postCodeObj`, {
          locType: "zip",
          displayPostCode: [],
          radius: radiusValue,
          locValue: locValueData,
        })

      }
    }
    setFieldValue(`branches[${branchIndex}].postCodeObj`, {
      radius: "0",
      locType: "zip",
      displayPostCode: [],
      locValue: responseData?.data?.postCodes?.length > 0 ? "" : locValueData
    })

  };

  function TooltipSpilitName(name, maxLength) {
    const data = name
      ?.split('-')
      ?.map((word, index) => {
        return word?.toString()?.charAt(0)?.toUpperCase() + word?.slice(1);
      })?.join(' ');

    if (data?.length > maxLength) {
      return data?.substring(0, maxLength - 3) + "...";
    } else {
      return data;
    }
  }



  const CloseStyle = styled(CloseIcon)({
    fill: "#00336B", // Specify the color you want
    fontSize: "10px",
    width: "15px",

  });

  const handleDelete1 = useCallback((itemToRemove, setFieldValue, branchIndex, values) => {
    const updatedDisplayLoc = values.branches[branchIndex].displayLoc.filter(
      (item) => item?.locValue !== itemToRemove?.locValue

    );
    setFieldValue(`branches[${branchIndex}].displayLoc`, updatedDisplayLoc);
  }, []);

  const handleDeleteLocation = useCallback((itemToRemove, setFieldValue, branchIndex, values) => {
    const updatedDisplayLoc = values.branches[branchIndex].displayLoc.filter(
      (item) => item?.locValue !== itemToRemove?.locValue
    );
    setFieldValue(`branches[${branchIndex}].displayLoc`, updatedDisplayLoc);
  }, []);


  const handleKeyDown = (e, setFieldValue, values, index) => {
    if (showPostCode && postcodeValue?.length > 0) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setActiveIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % postcodeValue.length;
          return newIndex;
        });
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setActiveIndex((prevIndex) => {
          const newIndex = prevIndex === 0 ? postcodeValue.length - 1 : prevIndex - 1;
          return newIndex;
        });
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (activeIndex >= 0 && activeIndex < postcodeValue.length) {
          const selectedPostcode = postcodeValue[activeIndex]?.postCode;
          if (selectedPostcode) {
            setFieldValue(`branches[${index}].postCodeObj`, {
              ...values.branches[index]?.postCodeObj,
              locType: "zip",
              locValue: selectedPostcode,
              displayPostCode: [selectedPostcode],
            });
            setShowPostCode(false);
          }
        }
      }
    }
  };

  const handleKeyDownLoc = (e, setFieldValue, values, index) => {
    if (showList && PlaceLocation?.length > 0) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        setActiveLocIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % PlaceLocation.length;
          return newIndex;
        });
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        setActiveLocIndex((prevIndex) => {
          const newIndex = prevIndex === 0 ? PlaceLocation.length - 1 : prevIndex - 1;
          return newIndex;
        });
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (activeLocIndex >= 0 && activeLocIndex < PlaceLocation.length) {
          handleLocationClick(PlaceLocation[activeLocIndex], setFieldValue, index)
          const selectedPostcode = PlaceLocation[activeLocIndex]?.description;

          if (selectedPostcode) {
            setFieldValue(`branches[${index}].locationObj`, {
              ...values.branches[index]?.locationObj,
              locType: "loc",
              locValue: selectedPostcode,
              displayPostCode: [selectedPostcode]
            });
            setShowPostCode(false);
            setShowList(false)
          }
        } 
      }
    }
  };




  useEffect(() => {
    const activeItem = document.querySelector(`.${Style.placeList}.${Style.activePlace}`);
    if (activeItem) {
      activeItem.scrollIntoView({ block: "nearest" });
    }
  }, [activeIndex]);

  useEffect(() => {
    const activeItem = document.querySelector(`.${Style.placeList}.${Style.activePlace}`);
    if (activeItem) {
      activeItem.scrollIntoView({ block: "nearest" });
    }
  }, [activeLocIndex]);

  const handleClosePopover = () => {
    setAnchorEl1(null);
  };

  const handleLocClosePopover = () => {
    setanchorEl(null);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, setFieldValue, handleSubmit }) => (
        <Form>
          <div
            className="d-flex align-items-center justify-content-center "
            style={{ marginTop: "3rem", maxWidth: "60%", margin: settings ? "" : "auto" }}
          >
            <div>
              <div className="mb-5 " >
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className={settings ? Style.question_heading_settings : Style.question_heading + " mb-0"}>What is your agency’s website?</h3>
                  <ButtonLoader
                    type="button"
                    onClickhandle={handleSubmit}
                    buttonName="Next"
                    isLoading={isLoading}
                    loaderColor={"white"}
                    style={{
                      marginLeft: "12px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                    className={Style.save_btn + ` ${settings && ' d-none'}`}
                    icon={<KeyboardArrowRightIcon />}
                  />
                </div>
                <hr></hr>

                <div className={Style.branch_rd + ` gap-2 shadow-sm  ${settings ? "" : "p-5"}`} style={{ width: "100%" }}>
                  <label className={Style.input_label + " mb-3"}>
                    Agency Website<span className="red_required_span"> * </span>
                  </label>
                  <TextInput
                    customClass={Style.website_input_custom + " w-100"}
                    type="text"
                    name="companyWebsite"
                    value={values.companyWebsite}
                    onChange={handleChange}
                    placeholder="Agency Website"
                    size={"small"}
                  />
                  <ErrorMsg hasError={touched.companyWebsite && errors.companyWebsite}>
                    {errors.companyWebsite}
                  </ErrorMsg>
                </div>

              </div>
              <div>
                <h3 className={settings ? Style.question_heading_settings : Style.question_heading + " mb-2"}>Add Branch Details</h3>
                <p className={Style.subHeading}>
                  Set up your branches to manage locations and operations effectively.
                </p>

                <FieldArray name="branches">
                  {({ remove, push }) => (
                    values?.branches?.map((branch, index) => (
                      <div key={index} className={Style.branch_card} style={{ ...(index < currentBranch ? { border: "1px solid #00A7BB" } : {}), ...(singleBranch ? { boxShadow: "none" } : {}) }}>


                        {index < (agency?.branches?.length || 0) ?
                          <div className="d-flex align-items-center" onClick={() => toggleSection('detail', index)}  >
                            <CheckCircleIcon sx={{ fontSize: "27px", marginRight: "8px", color: "#00A7BB" }} />
                            <div className={Style.branch_index} style={{ textTransform: "capitalize" }}>{branch?.branchName}</div>
                          </div>
                          :
                          <div className="d-flex align-items-center" onClick={() => toggleSection('detail', index)} >
                            <CheckCircleIcon sx={{ fontSize: "27px", marginRight: "8px", color: "#E3E3E5" }} />
                            <div className={Style.branch_index}> Branch {singleBranch ? "" : index + 1 + " of " + totalCount}{" "} </div>
                          </div>
                        }
                        {!collapsed[index] && <div className={Style.branch_details}>
                          <div className={Style.first_section}>
                            <div className="d-flex align-items-start">

                              <div className="mt-1 w-100">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div>
                                    <h6 className={Style.detail_heading}>Branch Details</h6>
                                    <p>Add your basic branch details { }</p>
                                  </div>

                                </div>
                                <BranchDetailForm
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  handleChange={handleChange}

                                  index={index}
                                  branch={branch}
                                  setFieldValue={setFieldValue}
                                  // handleTagsChange={handleTagsChange}
                                  handleDelete={handleDelete}
                                  setPlacesData={setPlacesData}
                                  placesData={placesData}
                                  fetchPlacesList={fetchPlacesList}
                                />
                                {/* <BranchAddress/> */}

                              </div>
                            </div>
                          </div>
                          <div className={Style.second_section}>
                            <div className="d-flex align-items-start">

                              <div>
                                <div className="d-flex align-items-center justify-content-between mt-1 w-100">
                                  <div className="w-75">
                                    <h6 className={Style.detail_heading}> Branch Serving Location </h6>
                                    <p>
                                      Tell us where your branch serves to help us understand
                                      your service area by entering the postcodes (zip
                                      codes) or names of the areas your branch covers
                                    </p>
                                  </div>

                                </div>

                                <div className="">
                                  <ErrorMsg hasError={touched?.branches?.[index]?.displayLoc && errors?.branches?.[index]?.displayLoc}>{errors?.branches?.[index]?.displayLoc} </ErrorMsg>
                                  <div className="">
                                    <Grid container>
                                      <Grid item xs={6} sm={6} md={6} className='pt-0'>
                                        <label>
                                          Postcode
                                        </label>
                                      </Grid>
                                      <Grid item xs={4} sm={4} md={4} className='pt-0'>
                                        <label>
                                          Radius
                                        </label>
                                      </Grid>
                                    </Grid>

                                    <Grid container>
                                      <Grid item xs={6} sm={6} md={6} className='pt-0'>
                                        <TextInput
                                          customClass={Style.input_Location + " w-100 "}
                                          type="text"
                                          name={values?.branches[index]?.postCodeObj?.locType === "zip" ? values?.branches[index]?.postCodeObj?.locType : ""}
                                          value={values?.branches[index]?.postCodeObj?.locType === "zip" ? values?.branches[index]?.postCodeObj?.locValue : ""}
                                          onChange={(e) => {
                                            if (e.target.value.length > 0) {
                                              userPostCodeDebounce(e.target.value);
                                              setShowPostCode(true);
                                              setActiveBranchIndex(index);
                                              setAnchorEl1(e.currentTarget);

                                            }
                                            setFieldValue(`branches[${index}].postCodeObj`, {
                                              ...values.branches[index]?.postCodeObj,
                                              locType: "zip",
                                              locValue: e.target.value.trim(),
                                              displayPostCode: [e.target.value.trim()],
                                              PostError: "",
                                              AlreadyPostError: ""

                                            });
                                            setValidPostCodeMsg("");
                                          }}
                                          onKeyDown={(e) => handleKeyDown(e, setFieldValue, values, index)}
                                          variant="outlined"
                                          placeholder="Enter PostCode.."
                                          size={"small"}
                                          branch={"Branch"}
                                        />

                                        {values?.branches[index]?.postCodeObj?.PostError && <p style={{ color: "red" }}>{values?.branches[index]?.postCodeObj?.PostError}</p>}
                                        {values?.branches[index]?.postCodeObj?.AlreadyPostError && <p style={{ color: "red" }}>{values?.branches[index]?.postCodeObj?.AlreadyPostError}</p>}

                                        {validPostCodeMsg && <p style={{ color: "red" }}>{validPostCodeMsg}</p>}
                                        {showPostCode &&
                                          (postcodeValue?.length > 0 ? (
                                            <Popper
                                              open={Boolean(anchorEl1)}
                                              anchorEl={anchorEl1}
                                              placement="bottom-start"
                                              modifiers={[
                                                {
                                                  name: 'offset',
                                                  options: {
                                                    offset: [0, 8],
                                                  },
                                                },
                                              ]}
                                              sx={{ zIndex: "9999", width: '20.7%' }}
                                            >
                                              <ClickAwayListener onClickAway={handleClosePopover}>
                                                <Paper>
                                                  <List
                                                    sx={{
                                                      maxHeight: 300,
                                                      overflow: 'auto',
                                                    }}
                                                  >
                                                    {postcodeValue?.map((place, index1) => (
                                                      <div
                                                        onClick={() => {
                                                          setFieldValue(`branches[${activeBranchIndex}].postCodeObj`, {
                                                            ...values.branches[activeBranchIndex]?.postCodeObj,
                                                            locType: "zip",
                                                            locValue: place?.postCode,
                                                            displayPostCode: [place?.postCode],
                                                          });
                                                          setShowPostCode(false);
                                                        }}
                                                      >
                                                        <ListItem
                                                          sx={{
                                                            '&:hover': {
                                                              backgroundColor: '#4EA3FF42',
                                                            },
                                                            fontSize: '14px',
                                                            minHeight: '10px',
                                                            padding: '4px 8px',
                                                            backgroundColor: activeIndex === index1 ? '#4EA3FF42' : 'transparent',
                                                          }}

                                                        >
                                                          <ListItemText
                                                            sx={{
                                                              fontSize: '14px',
                                                            }}
                                                            primary={place?.postCode}
                                                          />
                                                        </ListItem>
                                                      </div>

                                                    ))}
                                                  </List>
                                                </Paper>
                                              </ClickAwayListener>
                                            </Popper>

                                          ) : (
                                            error && (
                                              <div className={Style.place_card}>
                                                {" "}
                                                <div className={Style.placeList}>
                                                  {error}
                                                </div>
                                              </div>
                                            )
                                          ))
                                        }

                                      </Grid>
                                      <Grid item xs={4} sm={4} md={4} className='pt-0'>
                                        <div className={Style.selectInputContainer} style={{ background: '#F5F8FA', color: '#F5F8FA' }}>
                                          <SelectInput
                                            name={values?.branches[index]?.postCodeObj?.locType === "zip" ? values?.branches[index]?.postCodeObj?.radius : ""}
                                            selectedValue={values?.branches[index]?.postCodeObj?.locType === "zip" ? values?.branches[index]?.postCodeObj?.radius : ""}
                                            placeholder={"Radius"}
                                            size={"small"}
                                            options={DISTANCE_MILES_OPTIONS?.length > 0 ? DISTANCE_MILES_OPTIONS : []}
                                            customClass={Style.customSelectWithIcon}
                                            onChange={(e) => {
                                              setFieldValue(`branches[${index}].postCodeObj`, {
                                                ...values.branches[index]?.postCodeObj,
                                                locType: "zip",
                                                radius: e.target.value
                                              });
                                            }}
                                            branch={"Branch"}

                                          />
                                        </div>

                                      </Grid>
                                      <Grid item xs={1} sm={1} md={1} style={{ marginLeft: '12px' }}>
                                        <ButtonLoaderLeft
                                          type={"button"}
                                          onClickhandle={() => handlerButtonCodeNew(index, setFieldValue, values)}
                                          buttonName="Add"
                                          loaderColor={"white"}
                                          className={Style.Add_btn}

                                          icon={<FaPlus />}
                                        ></ButtonLoaderLeft>
                                      </Grid>
                                    </Grid>
                                    {
                                      branch.displayLoc.map((item) => (item.locType === "zip" &&
                                        <>
                                          <Chip
                                            key={index}
                                            label={
                                              <Tooltip
                                                title={`${(item?.locValue || '').toUpperCase()}${item?.radius !== "0" ? `, within ${item.radius} miles` : ''}`}
                                              >
                                                <span className={Style.TootltipName}>
                                                  {TooltipSpilitName(
                                                    `${(item?.locValue || '').toUpperCase()}${item?.radius !== "0" ? `, within ${item?.radius || ''} miles` : ''}`,
                                                    35
                                                  )}
                                                </span>
                                              </Tooltip>
                                            }
                                            onDelete={() => handleDelete1(item, setFieldValue, index, values, branch.displayLoc)}
                                            variant="outlined"
                                            sx={{
                                              backgroundColor: "#4EA3FF42",
                                              color: "#00336B",
                                              border: "none",
                                              marginTop: "10px",
                                              height: "20px",
                                              fontSize: "12px",
                                            }}
                                            deleteIcon={<CloseStyle />}
                                            style={{ marginRight: "1px", marginLeft: "4px" }}
                                          />
                                        </>

                                      ))
                                    }



                                  </div>
                                  <div className={Style.or_label}>or</div>
                                  <div className="">
                                    <Grid container>
                                      <Grid item xs={6} sm={6} md={6} className='pt-0'>
                                        <label>Location Name</label>
                                      </Grid>
                                      <Grid item xs={4} sm={4} md={4} className='pt-0'>
                                        <label>
                                          Radius
                                        </label>
                                      </Grid>
                                    </Grid>

                                    <Grid container>
                                      <Grid item xs={6} sm={6} md={6} className='pt-0'>
                                        <TextInput
                                          customClass={Style.input_Location + " w-100 "}
                                          type="text"
                                          name={values?.branches[index]?.locationObj?.locType === "loc" ? values?.branches[index]?.locationObj?.locType : ""}
                                          value={values?.branches[index]?.locationObj?.locType === "loc" ? values?.branches[index]?.locationObj?.locValue : ""}

                                          onChange={(e) => {
                                            setFieldValue(`branches[${index}].locationObj`, {
                                              ...values.branches[index]?.locationObj,
                                              locType: "loc",
                                              locValue: e.target.value,
                                              displayPostCode: [],
                                              locationError: "",
                                              locationAlreadyError: ""
                                            });
                                            setShowPostCode(false)

                                            if (e.target.value.length > 0) {
                                              userTxtChangeDebounce(e.target.value);
                                              setShowList(true);
                                              setanchorEl(e.currentTarget);
                                              setActiveLocBranch(index);


                                            }
                                          }}
                                          onKeyDown={(e) => handleKeyDownLoc(e, setFieldValue, values, index)}

                                          variant="outlined"
                                          placeholder="Enter Location.."
                                          size="small"
                                        />
                                        {values?.branches[index]?.locationObj?.locationError && <p style={{ color: "red" }}>{values?.branches[index]?.locationObj?.locationError}</p>}
                                        {values?.branches[index]?.locationObj?.locationAlreadyError && <p style={{ color: "red" }}>{values?.branches[index]?.locationObj?.locationAlreadyError}</p>}

                                        {showList &&
                                          (PlaceLocation?.length > 0 ? (
                                            <Popper
                                              open={Boolean(anchorEl)}
                                              anchorEl={anchorEl}
                                              placement="bottom-start"
                                              modifiers={[
                                                {
                                                  name: 'offset',
                                                  options: {
                                                    offset: [0, 8],
                                                  },
                                                },
                                              ]}
                                              sx={{ zIndex: "9999", width: '20.7%' }}
                                            >
                                              <ClickAwayListener onClickAway={handleLocClosePopover}>
                                                <Paper>
                                                  <List
                                                    sx={{
                                                      maxHeight: 300,
                                                      overflow: 'auto',
                                                    }}
                                                  >
                                                    {PlaceLocation?.map((place, index1) => (
                                                      <div
                                                        onClick={() => {
                                                          handleLocationClick(place, setFieldValue, activeLocBranch);
                                                          setFieldValue(`branches[${activeLocBranch}].locationObj`, {
                                                            ...values.branches[activeLocBranch]?.locationObj,
                                                            locType: "loc",
                                                            locValue: place?.description,
                                                            displayPostCode: []
                                                          });
                                                        }}
                                                      >
                                                        <ListItem
                                                          sx={{
                                                            '&:hover': {
                                                              backgroundColor: '#4EA3FF42',
                                                            },
                                                            fontSize: '14px',
                                                            minHeight: '10px',
                                                            padding: '4px 8px',
                                                            backgroundColor: activeLocIndex === index1 ? '#4EA3FF42' : 'transparent',
                                                          }}

                                                        >
                                                          <ListItemText
                                                            sx={{
                                                              fontSize: '14px',
                                                            }}
                                                            primary={place?.description}
                                                          />
                                                        </ListItem>
                                                      </div>

                                                    ))}
                                                  </List>
                                                </Paper>
                                              </ClickAwayListener>
                                            </Popper>
                                          ) : (
                                            error && (
                                              <div className={Style.place_card}>
                                                {" "}
                                                <div className={Style.placeList}>
                                                  {error}
                                                </div>
                                              </div>
                                            )
                                          ))}
                                      </Grid>

                                      <Grid item xs={4} sm={4} md={4} className='pt-0'>
                                        <div style={{ background: '#F5F8FA', color: '#F5F8FA' }}>
                                          <SelectInput
                                            name={values?.branches[index]?.locationObj?.locType === "loc" ? values?.branches[index]?.locationObj?.radius : ""}
                                            selectedValue={values?.branches[index]?.locationObj?.locType === "loc" ? values?.branches[index]?.locationObj?.radius : ""}
                                            placeholder={"Radius"}
                                            size={"small"}
                                            options={DISTANCE_MILES_OPTIONS?.length > 0 ? DISTANCE_MILES_OPTIONS : []}
                                            customClass={Style.customSelectWithIcon}
                                            onChange={(e) => {
                                              setFieldValue(`branches[${index}].locationObj`, {
                                                ...values.branches[index]?.locationObj,
                                                locType: "loc",
                                                radius: e.target.value
                                              });
                                              setRadiusLocError("");
                                            }}

                                          />
                                        </div>

                                        {radiuslocError && <p style={{ color: "red" }}>{radiuslocError}</p>}


                                      </Grid>
                                      <Grid item xs={1} sm={1} md={1} style={{ marginLeft: '12px' }}>
                                        <ButtonLoaderLeft
                                          type={"button"}
                                          buttonName="Add"
                                          loaderColor={"white"}
                                          className={Style.Add_btn}
                                          onClickhandle={() => handlerButtonCode(index, setFieldValue, values)}
                                          icon={<FaPlus />}
                                        />
                                      </Grid>
                                    </Grid>
                                    {
                                      branch.displayLoc.map((item) => (item.locType === "loc" && <>
                                        <Chip
                                          key={index}
                                          label={
                                            <Tooltip
                                              title={`${item?.locValue || ''}${item?.radius && item?.radius !== "0" ? `, within ${item.radius} miles` : ''}`}
                                            >
                                              <span className={Style.TootltipName}>
                                                {TooltipSpilitName(
                                                  `${item?.locValue || ''}${item?.radius !== "0" ? `, within ${item?.radius || ''} miles` : ''}`,
                                                  35
                                                )}
                                              </span>
                                            </Tooltip>
                                          }
                                          onDelete={() => handleDeleteLocation(item, setFieldValue, index, values)}
                                          variant="outlined"
                                          sx={{
                                            backgroundColor: "#4EA3FF42",
                                            color: "#00336B",
                                            border: "none",
                                            marginTop: "10px",
                                            height: "20px",
                                            fontSize: "12px",
                                          }}
                                          deleteIcon={<CloseStyle />}
                                          style={{ marginRight: "1px", marginLeft: "4px" }}
                                        />
                                      </>))
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={Style.third_section}>
                            <div className="mb-3">
                              <CommonCheckbox
                                label="Restrict leads to service location"
                                checked={values.branches[index]?.isLeadAssignmentRestricted || false}
                                onChange={(e) => {
                                  setFieldValue(`branches[${index}].isLeadAssignmentRestricted`, e.target.checked);
                                }}
                              />
                            </div>
                            <div className="d-flex align-items-start">

                              <div className="w-100">
                                <div className="d-flex align-items-center justify-content-between mt-1 w-100">
                                  <div className="w-75">
                                    <h6 className={Style.detail_heading}>Working hours</h6>
                                    <p> Communications will only be sent to this branch during these hours. </p>
                                  </div>
                                </div>

                                <div>
                                  <FieldArray name={`branches[${index}].workingHours`} >
                                    {({ push: pushWorkingHours, remove: removeWorkingHours }) => (
                                      <WorkingHours
                                        index={index}
                                        pushWorkingHours={pushWorkingHours}
                                        removeWorkingHours={removeWorkingHours}
                                        workingHours={values.branches[index]?.workingHours}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                      />
                                    )}
                                  </FieldArray>

                                </div>

                              </div>
                            </div>
                          </div>
                        </div>}
                      </div>
                    ))
                  )}
                </FieldArray>
                <div className="text-end mb-3">
                  <ButtonLoader
                    type="button"
                    onClickhandle={() => {


                      handleSubmit()
                    }}
                    buttonName={settings ? "Save" : "Next"}
                    isLoading={isLoading}
                    loaderColor={"white"}
                    style={{
                      marginLeft: "12px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                    className={Style.save_btn}

                    icon={settings ? " " : <KeyboardArrowRightIcon />}
                  />
                </div>

              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>


  );
};

export default BranchDetailCard;