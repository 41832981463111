import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Style from "./broadcast.module.css";
import { Box, Checkbox, Grid } from "@mui/material";
import TextInput from "src/components/forms/TextInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import ErrorMsg from "src/components/custom/ErrorMsg";
import SelectInput from "src/components/forms/SelectInput";
import useBroadcast from "./hooks/useBroadcast";
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import { RiDeleteBin6Line } from "react-icons/ri";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import NameImage from "src/components/custom/NameImage";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BroadCastPreview from "./BroadCastPreview";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AstericSymbol from "src/components/custom/astericSymbol";
import NoDataFound2 from "src/components/custom/NoDataFound2";
import NoUsersFound from "src/assets/images/noDataFound/NoUsersFound.png";
import PropertySelectInput from "src/components/custom/PropertySelectInput";
import Loader from "src/layouts/loader/Loader";
import { Labels } from "src/constants/formPicker";
import BroadCastFilter from "./BroadCastFilter";
import { debounce } from "src/helper/commonHelp";
import useConnectChannels from "src/views/connectChat/hooks/useConnectChannels";

const AddBroadcast = () => {
  const navigate = useNavigate();

  const { fetchBroadcastData, broadcastData, handleAddBroadcast, isLoading, isDataLoading, getContactList, iscontactloading, contactUsers } = useBroadcast();
  const { userdata, } = useConnectChannels();

  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [checkedIds, setCheckedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [imageError, setImageError] = useState("");
  const [open, setOpen] = useState(false);
  const prevIsActiveRef = useRef();

  const [contactfilters, setContactfilters] = useState({
    filterType: "",
    location: "",
    postCode: "",
    radius: 0,
    maxBedroom: 0,
    minBedroom: 0,
    minBathroom: 0,
    maxBathroom: 0,
    minPrice: 0,
    maxPrice: 0,
    priceType: "",
    propertyType: [],
    furnishType: "",
    tags: [],
    displayCity: ""
  })

  const handleClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required("Name is required.")
      .test(
        "not-start-with-space",
        "Name cannot start with a space.",
        (value) => !value || !value.startsWith(" ")
      ),
    chatbot_id: Yup.string().required("Chatbot ID is required."),
    property: Yup.string().required("Property is required."),
    template_id: Yup.string().required("Template ID is required."),
    contact: Yup.array()
      .of(Yup.string().trim().required("Contact is required."))
      .min(1, "At least one contact is required."),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      chatbot_id: "",
      template_type: "",
      doc: null,
      property: "",
      template_id: "",
      contact: [],
      template_info: {
        name: "",
        language: "en",
        parameters: [
          {
            type: "header",
            variables: [
              {
                placeholder: "{{1}}",
                value: "replace_with_name",
              },
            ],
          },
          {
            type: "body",
            variables: [
              {
                placeholder: "{{2}}",
                value: "Property Jinni",
              },
              {
                placeholder: "{{3}}",
                value: "London",
              },
              {
                placeholder: "{{4}}",
                value: "30%",
              },
            ],
          },
        ],
      },
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {

      if (imageError) {
        return 0;
      }

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("chatbot_id", values.chatbot_id);
      formData.append("template_id", values.template_id);
      formData.append("property", values?.property);
      formData.append("template_type", selectedTemplate.header_type);
      formData.append("files", values.doc);
      formData.append("template_info", JSON.stringify(values.template_info));
      formData.append("template_message", 'Hi');

      values.contact.forEach((variable, index) => {
        formData.append(`contact[${index}]`, variable);
      });

      handleAddBroadcast(formData);
    },
  });

  const handleCloseFile = () => {
    setFileName("");
    setSelectedFile(null);
    formik.setFieldValue("template_id", "")

    formik.setFieldValue("name", "");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageError("");

    if (file) {
      if (file?.type.includes('image') || file?.type.includes('pdf') || file?.type.includes('video')) {
        if (file.size <= 16 * 1024 * 1024) {
          setSelectedFile(file);
          setFileName(file?.name);
          formik.setFieldValue("doc", file);
        } else {
          setImageError("The file must be an image, video or PDF, and should not exceed 16 MB in size.");
        }
      } else {
        setImageError(`Invalid file type, only ${selectedTemplate?.header_type === "IMAGE" ? "images" : selectedTemplate?.header_type === "VIDEO" ? "video files" : "pdf files"} are allowed.`);
      }
    }
  };

  const handleCheckboxChange = (id) => {
    if (checkedIds.includes(id)) {
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
      formik.setFieldValue("contact", checkedIds.filter((checkedId) => checkedId !== id));
    } else {
      setCheckedIds([...checkedIds, id]);
      formik.setFieldValue("contact", [...checkedIds, id]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setCheckedIds([]);
      formik.setFieldValue("contact", []);
    } else {
      setCheckedIds(contactUsers?.map((user) => user._id));
      formik.setFieldValue("contact", contactUsers?.map((user) => user._id));
    }
    setSelectAll(!selectAll);
  };

  function convertArrayToLabelValue(array) {
    return array?.map((item) => ({
      label: item?.displayName,
      value: item._id,
    }));
  }

  function convertChatbotArrayToLabelValue(array) {
    return array?.map((item) => ({
      label: item.name,
      value: item._id,
    }));
  }

  const replaceVariables = (text, variables) => {
    let updatedText = text;

    if (variables) {
      variables?.forEach((variable) => {
        updatedText = updatedText?.replace(
          variable.placeholder,
          variable.value
        );
      });
    }

    return updatedText;
  };

  function formatString(input) {
    return input?.split('-')?.map(word => word?.toString()?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(' ');
  }

  const getMappedValue = (label, propertyData) => {
    const labelLowerCase = label.toLowerCase();

    const labelObject = Labels.find(
      (labelObj) => labelObj.label === labelLowerCase
    );

    if (!labelObject) {
      return "NA";
    }

    switch (labelObject.label) {
      case "contact name":
        return "REPLACE_WITH_NAME";
      case "property name":
        return propertyData?.propertyAddress?.displayAddress || "NA";
      case "location":
        return propertyData?.propertyAddress?.displayAddress || "NA";
      case "address":
        return propertyData?.propertyAddress?.displayAddress || "NA";
      case "price":
        return propertyData?.listingType[0] === "let" ? propertyData?.rentInfo?.rent?.amount : propertyData?.price?.price || 0;
      case "property type":
        return propertyData?.propertyType || "NA";
      case "agency name":
        return broadcastData?.agency?.name || "NA";
      case "contact number":
        return broadcastData?.agency?.phone || "NA";
      case "key feature":
        return propertyData?.features?.map(feature => feature)?.join(", ") || "NA";
      case "details":
        return `${propertyData?.noOfBedroom || 0} Bedrooms, ${propertyData?.noOfBathroom || 0} Bathrooms, ${propertyData?.noOfReception || 0} Receptions` || "NA";
      case "bedrooms":
        return `${propertyData?.noOfBedroom || 0} Bedrooms` || "NA";
      case "bathrooms":
        return `${propertyData?.noOfBathroom || 0} Bathrooms` || "NA";
      case "receptions":
        return `${propertyData?.noOfReception || 0} Receptions` || "NA";
      default:
        return propertyData[labelObject.value] || "NA";
    }
  };

  const updateFilterType = (id) => {
    const property = broadcastData?.properties?.data?.find((item) => item._id === id);

    setContactfilters({
      ...contactfilters,
      location: property?.propertyAddress?.geoCoordinates?.coordinates[0] + "," + property?.propertyAddress?.geoCoordinates?.coordinates[1],
      postCode: property?.propertyAddress?.postalCode,
      displayCity: property?.propertyAddress?.city,
      radius: 0,
      maxBedroom: 0,
      minBedroom: 0,
      minBathroom: 0,
      maxBathroom: 0,
      minPrice: 0,
      maxPrice: 0,
      priceType: "",
      propertyType: [property?.propertyType],
      furnishType: "",
      tags: [],
      filterType: property.propertyCategory + "-" + (property?.listingType[0] === "let" ? "lettings" : "sales")
    })
  }

  useEffect(() => {
    fetchBroadcastData();
  }, []);

  const userTxtChangeDebounce = useCallback(
    debounce((txtval, contactfilters) => {
      getContactList(contactfilters, txtval)
    }, 500),
    []
  );

  useEffect(() => {
    if (formik.values.template_id && broadcastData?.template && formik.values.property) {
      const selectedTemplate = broadcastData.template.find((item) => item?._id === formik.values.template_id);
      const propertyData = broadcastData?.properties?.data?.find((property) => formik.values.property === property?._id);
      setSelectedTemplate(selectedTemplate);

      if (selectedTemplate) {
        const mappedHeaderVariables = selectedTemplate?.header_variables?.map(
          (variable) => ({
            placeholder: variable.placeholder,
            value: getMappedValue(variable.label, propertyData),
          })
        );

        const mappedBodyVariables = selectedTemplate.body_variables.map(
          (variable) => ({
            placeholder: variable.placeholder,
            value: getMappedValue(variable.label, propertyData),
          })
        );

        const updatedTemplateInfo = {
          name: selectedTemplate.name,
          language: selectedTemplate.language,
          parameters: [
            {
              type: "header",
              variables: mappedHeaderVariables,
            },
            {
              type: "body",
              variables: mappedBodyVariables,
            },
          ],
        };

        formik.setFieldValue("template_info", updatedTemplateInfo);
      }
    }
  }, [formik.values.template_id, formik.values.property]);

  useEffect(() => {
    getContactList(contactfilters, searchUser)
  }, [contactfilters])

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 260,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
    overflow: "hidden",
  };




  useEffect(() => {
    const currentIsActive = userdata?.integrationKeys?.whatsapp?.isActive;
    // Check if the previous value was undefined and the current value is false
    if (prevIsActiveRef.current === undefined && currentIsActive === false) {
      setOpen(true);
    }
    prevIsActiveRef.current = currentIsActive;
  }, [userdata?.integrationKeys?.whatsapp?.isActive]);


  const handleKeyDown = (event) => {
    const { key } = event;
    const options = convertArrayToLabelValue(broadcastData?.template || []);
    const selectedIndex = options.findIndex(option => option.value === formik.values.template_id);
    if (key === "ArrowDown") {
      event.preventDefault();
      const nextIndex = selectedIndex + 1 < options.length ? selectedIndex + 1 : 0; // Wrap to start
      formik.setFieldValue("template_id", options[nextIndex].value);
    }

    // Handle arrow up
    if (key === "ArrowUp") {
      event.preventDefault();
      const prevIndex = selectedIndex - 1 >= 0 ? selectedIndex - 1 : options.length - 1; // Wrap to end
      formik.setFieldValue("template_id", options[prevIndex].value);
    }

    if (key === "Enter") {
      event.preventDefault();
    }

    if (key === "Escape") {
      event.preventDefault();
    }
  };

  const handleChatKeyDown = (event) => {
    const { key } = event;
    const options = convertChatbotArrayToLabelValue(broadcastData?.chatbot?.data || []);
    const selectedIndex = options.findIndex(option => option.value === formik.values.chatbot_id);
    if (key === "ArrowDown") {
      event.preventDefault();
      const nextIndex = selectedIndex + 1 < options.length ? selectedIndex + 1 : 0; // Wrap to start
      formik.setFieldValue("chatbot_id", options[nextIndex].value);
    }

    if (key === "ArrowUp") {
      event.preventDefault();
      const prevIndex = selectedIndex - 1 >= 0 ? selectedIndex - 1 : options.length - 1; // Wrap to end
      formik.setFieldValue("chatbot_id", options[prevIndex].value);
    }

    // Handle Enter key
    if (key === "Enter") {
      event.preventDefault();
    }

    // Handle Escape key
    if (key === "Escape") {
      event.preventDefault();
    }
  };

  // Find the first object where header_type is "IMAGE" or "PDF"
  const firstHeaderTypeImageOrPdf = useMemo(() => {
    return broadcastData?.template?.find(
      item => item.header_type === "IMAGE" || item.header_type === "DOCUMENT"
    );
  }, [broadcastData?.template]);

  useEffect(() => {
    if (firstHeaderTypeImageOrPdf?._id) {
      formik.setFieldValue("template_id", firstHeaderTypeImageOrPdf._id);
    }
  }, [selectedFile, firstHeaderTypeImageOrPdf]);



  return (
    <>
      <div
        style={{
          background: "#F8FAFE",
          minHeight: "calc(100vh - 57px)",
          height: "calc(100vh - 57px)",
          overflow: "hidden",
        }}
      >

        <div className="">
          <Box className="">
            {isDataLoading ? <Loader borderWidth={"4px"} /> :
              <Grid container spacing={0} className="mt-1" style={{ maxWidth: "100vw" }}>
                <Grid item xs={12} sm={12} md={9} className={Style.custom_scroll} style={{ padding: "3.5rem 3rem" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} className="pt-0 mb-3">
                      <div className="d-flex align-items-center" >
                        <ArrowBackIosIcon sx={{ cursor: "pointer" }} onClick={() => { navigate('/broadcast') }} />
                        <div className={Style.title}>New Broadcast</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="pt-0 mb-3">
                      <div className="w-100">
                        <label className={Style.form_label}>Broadcast Name <AstericSymbol /></label>
                        <TextInput
                          customClass={Style.custom_search + " w-100"}
                          type="text"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          variant="outlined"
                          size={"small"}
                          sx={{ marginTop: "1rem", width: "80% !important" }}
                          placeholder={"Broadcast Name"}

                        />
                        <ErrorMsg hasError={formik.touched.name && formik.errors.name}>{formik.errors.name}</ErrorMsg>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="pt-0 mb-3 ">
                      <div className="w-100 ">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                          <label className={Style.form_label}>Search Property <AstericSymbol /></label>
                          <div className={Style.add_property}
                            onClick={() => window.open("/property/AddProperty", "_blank")}
                          >
                            <AddOutlinedIcon />
                            <span>Add Property</span>
                          </div>
                        </div>
                        <div className="position-relative">
                          <PropertySelectInput
                            tag={formik.values.property}
                            setValue={(val) => {
                              formik.setFieldValue('property', val)
                              updateFilterType(val)
                            }}
                            fullWidth
                            variant="outlined"
                            id="tags"
                            name="tags"
                            placeholder="Search Property"
                            rows={3}
                            customClass={Style.custom_search}
                            suggestions={broadcastData?.properties?.data || []}
                            handleDelete={(val) => {
                              formik.setFieldValue('property', null);
                              setContactfilters({
                                filterType: "",
                                location: "",
                                postCode: "",
                                radius: 0,
                                maxBedroom: 0,
                                minBedroom: 0,
                                minBathroom: 0,
                                maxBathroom: 0,
                                minPrice: 0,
                                maxPrice: 0,
                                priceType: "",
                                propertyType: [],
                                furnishType: "",
                                tags: [],
                                displayCity: ""
                              })
                            }}
                          />
                        </div>
                        <ErrorMsg hasError={formik.touched.property && formik.errors.property}>{formik.errors.property}</ErrorMsg>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="pt-0 mb-3">
                      <label className={Style.form_label}> Select template message <AstericSymbol /> </label>
                      <div className="w-100">
                        <SelectInput
                          name="template_id"
                          options={convertArrayToLabelValue(broadcastData?.template || [])}
                          selectedValue={formik.values.template_id}
                          onChange={(e) => formik.setFieldValue("template_id", e.target.value)}
                          size={"small"}
                          customClass={Style.custom_select}
                          sx={{ backgroundColor: "#F5F8FA" }}
                          noOptionText={"No template found."}
                          placeholder={"Select template message"}
                          onBlur={formik.handleBlur}
                          handleKeyDown={handleKeyDown} // Pass the keydown handler
                        />
                        <ErrorMsg hasError={formik.touched.template_id && formik.errors.template_id} > {formik.errors.template_id} </ErrorMsg>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="pt-0 mb-3">
                      <label className={Style.form_label}>Select chatbot <AstericSymbol /></label>
                      <div className="w-100">
                        <SelectInput
                          name="chatbot_id"
                          options={convertChatbotArrayToLabelValue(broadcastData?.chatbot?.data || [])}
                          selectedValue={formik.values.chatbot_id}
                          onChange={(e) => formik.setFieldValue("chatbot_id", e.target.value)}
                          size={"small"}
                          customClass={Style.custom_select}
                          sx={{ backgroundColor: "#F5F8FA" }}
                          noOptionText={"No chatbot found."}
                          placeholder={"Select chatbot"}
                          handleKeyDown={handleChatKeyDown} // Pass the keydown handler

                        />
                        <ErrorMsg hasError={formik.touched.chatbot_id && formik.errors.chatbot_id}> {formik.errors.chatbot_id} </ErrorMsg>
                      </div>
                    </Grid>
                    {/* {selectedTemplate &&
                      selectedTemplate?.header_type !== "TEXT" && (
                        <Grid item xs={12} sm={12} md={12}>
                          <div className={Style.document_heading + " mb-3"}>
                            Attach Document
                          </div>
                          {fileName ? (
                            <div className={" d-flex align-items-center  w-100 mb-3 mt-1"} >
                              <div className={Style.file_name}>{fileName}</div>
                              <RiDeleteBin6Line
                                style={{
                                  cursor: "pointer",
                                  color: "#5C5D5C",
                                  fontSize: "20px",
                                }}
                                onClick={() => {
                                  handleCloseFile();
                                }}
                              />
                            </div>
                          ) : (
                            <div className="w-100 mb-3 mt-1">
                              <input
                                type="file"
                                accept={selectedTemplate?.header_type === "IMAGE" ? "image/*" : selectedTemplate?.header_type === 'VIDEO' ? "video/*" : "application/pdf"}
                                ref={fileInputRef}
                                onChange={(e) => { handleFileChange(e); }}
                                hidden
                              />
                              <div onClick={handleClick} className={Style.upload_img}>
                                <div style={{ textAlign: "center" }}>
                                  <p className="mb-0">Upload {selectedTemplate?.header_type === 'DOCUMENT' ? "document" : selectedTemplate?.header_type === 'VIDEO' ? "video" : "image"}</p>
                                </div>
                              </div>
                            </div>
                          )}
                          <p className={Style.document_summary}>
                            File should be an image or pdf, and no bigger than 16
                            mb.
                          </p>
                          <p style={{ color: "red", fontSize: "14px" }}>{imageError}</p>
                          <ErrorMsg
                            hasError={
                              formik.touched.header_value &&
                              formik.errors.header_value
                            }
                          >
                            {" "}
                            {formik.errors.header_value}{" "}
                          </ErrorMsg>
                        </Grid>
                      )} */}

                    <Grid item xs={12} sm={12} md={12}>
                      <div className={Style.document_heading + " mb-3"}>
                        Attach Document
                      </div>
                      {fileName ? (
                        <div className={" d-flex align-items-center  w-100 mb-3 mt-1"} >
                          <div className={Style.file_name}>{fileName}</div>
                          <RiDeleteBin6Line
                            style={{
                              cursor: "pointer",
                              color: "#5C5D5C",
                              fontSize: "20px",
                            }}
                            onClick={() => {
                              handleCloseFile();
                            }}
                          />
                        </div>
                      ) : (
                        <div className="w-100 mb-3 mt-1">
                          <input
                            type="file"
                            accept={selectedTemplate?.header_type !== "IMAGE" ? "image/*" : selectedTemplate?.header_type === 'VIDEO' ? "video/*" : "application/pdf"}
                            ref={fileInputRef}
                            onChange={(e) => { handleFileChange(e); }}
                            hidden
                          />
                          <div onClick={handleClick} className={Style.upload_img}>
                            <div style={{ textAlign: "center" }}>
                              <p className="mb-0">Upload {selectedTemplate?.header_type === 'DOCUMENT' ? "document" : selectedTemplate?.header_type === 'VIDEO' ? "video" : "image"}</p>
                            </div>
                          </div>
                        </div>
                      )}
                      <p className={Style.document_summary}>
                        File should be an image or pdf, and no bigger than 16
                        mb.
                      </p>
                      <p style={{ color: "red", fontSize: "14px" }}>{imageError}</p>
                      <ErrorMsg
                        hasError={
                          formik.touched.header_value &&
                          formik.errors.header_value
                        }
                      >
                        {" "}
                        {formik.errors.header_value}{" "}
                      </ErrorMsg>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>

                      {contactfilters?.filterType !== "" && <div>
                        <div className={Style.document_heading + " mb-2"}>
                          Select Audience <AstericSymbol />
                        </div>

                        <BroadCastFilter contactfilters={contactfilters} setContactfilters={setContactfilters} />
                      </div>}

                      <div className="d-flex align-items-center justify-content-between">
                        <div
                          className="d-flex align-items-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (
                              formik.values.template_id &&
                              formik.values.property
                            ) {
                              handleSelectAllChange();
                            }
                          }}
                        >
                          <Checkbox
                            checked={selectAll}
                            onChange={() => {
                              if (
                                formik.values.template_id &&
                                formik.values.property
                              ) {
                                handleSelectAllChange();
                              }
                            }}
                            sx={{
                              padding: 0,
                              color: "#ACA8A8",
                              "&.Mui-checked": {
                                color:
                                  formik.values.template_id &&
                                    formik.values.property
                                    ? "#2379F0"
                                    : "#A0A0A0",
                              },
                              marginRight: "7px",
                            }}
                          />
                          <div
                            className={`${Style.select_text} ${formik.values.template_id && formik.values.property
                              ? ""
                              : Style.disable
                              }`}
                          >
                            Select All
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div
                            className={`${Style.total_selected} ${formik.values.template_id && formik.values.property ? "" : Style.disable}`}
                          >
                            {`${checkedIds?.length} selected `}{" "}
                          </div>
                          <span
                            className={Style.total}
                          >{`of ${contactUsers?.length} total`}</span>
                        </div>
                      </div>
                      <div className={Style.contact_list + " mt-3"}>
                        <ErrorMsg
                          hasError={formik.touched.contact && formik.errors.contact}
                        >
                          <div
                            style={{ padding: "0rem 1rem", marginBottom: "5px" }}
                          >
                            {formik.errors.contact}
                          </div>
                        </ErrorMsg>
                        <div
                          className={Style.custom_pad + " mb-2"}
                          style={{ position: "relative" }}
                        >
                          <TextInput
                            customClass={Style.custom_search + " w-100"}
                            type="text"
                            name="searchValue"
                            value={searchUser}
                            onChange={(e) => {
                              setSearchUser(e.target.value.trim());

                              userTxtChangeDebounce(e.target.value.trim(), contactfilters);

                            }}
                            variant="outlined"
                            placeholder="Search by name or contact"
                            size={"small"}
                          />
                          {searchUser ? (
                            <div
                              className={Style.reset_btn}
                              onClick={() => {
                                setSearchUser("");
                                userTxtChangeDebounce("", contactfilters)
                              }}
                            >
                              <HighlightOffIcon
                                size={14}
                                className=" text-muted"
                                style={{ cursor: "pointer", marginRight: "10px" }}
                              />
                            </div>
                          ) : (
                            <div className={Style.search_icon}>
                              <SearchIcon style={{ marginRight: "20px" }} />
                            </div>
                          )}
                        </div>
                        {iscontactloading ? <Loader /> : contactUsers?.length > 0 ?
                          <div className={Style.contact_list_scroll}>
                            {contactUsers.map((user, index) => {
                              return (
                                <div

                                  key={index}
                                  className={`${Style.contact} ${formik.values.template_id && formik.values.property ? Style.active : ""}`}
                                  onClick={() => {
                                    if (
                                      formik.values.template_id &&
                                      formik.values.property
                                    ) {
                                      handleCheckboxChange(user?._id);
                                    }
                                  }}
                                >
                                  <Checkbox
                                    checked={checkedIds.includes(user?._id)}
                                    onChange={() => {
                                      if (
                                        formik.values.template_id &&
                                        formik.values.property
                                      ) {
                                        handleCheckboxChange(user?._id);
                                      }
                                    }}
                                    sx={{
                                      padding: 0,
                                      color: "#ACA8A8",
                                      "&.Mui-checked": {
                                        color: formik.values.template_id && formik.values.property ? "#2379F0" : "#A0A0A0",
                                      },
                                      marginRight: "7px",
                                    }}
                                    disabled={
                                      formik.values.template_id &&
                                      formik.values.property
                                    }
                                  />
                                  <div>
                                    <NameImage name={user.name} height={"1.5rem"} width={"1.5rem"} fontSize={"10px"} />
                                  </div>
                                  <div className={Style.name}>{user.name}</div>
                                  <div className={Style.mobile}>{`(${user.phone}),`}</div>
                                  <div className={Style.category}>{user?.category && user?.category?.length > 0 ? `${user?.category?.map(formatString)?.join(', ')}` : ''}</div>
                                  <div className={Style.contact_tag}>
                                    {user?.tags && user?.tags.length > 0 && user?.tags?.map((tag, index) => {
                                      const formattedTagValue = tag?.replace(/-/g, ' ');
                                      return (
                                        <div key={index} className={Style.tag_box2} title={formattedTagValue?.replace(/-/g, ' ')} >
                                          {formattedTagValue}
                                        </div>
                                      );
                                    })}
                                  </div>

                                </div>
                              );
                            })}
                          </div> :
                          <div className={Style.contact_list_scroll} style={{ maxHeight: "260px" }}>
                            <NoDataFound2
                              width={"180px"}
                              text="No Contact Found!"
                              image={NoUsersFound}
                            />
                          </div>}
                      </div>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
                      <ButtonLoader
                        type="button"
                        color="primary "
                        onClickhandle={formik.handleSubmit}
                        buttonName={"Send"}
                        isLoading={isLoading}
                        loaderColor={"white"}
                        style={{ marginLeft: "12px", paddingLeft: "12px", paddingRight: "12px", }}
                        className={Style.send_btn}
                        disabled={isLoading}
                      ></ButtonLoader>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={3} style={{ textAlign: "right" }}>
                  <BroadCastPreview
                    values={formik.values}
                    selectedTemplate={selectedTemplate}
                    replaceVariables={replaceVariables}
                    selectedFile={selectedFile}
                  />
                </Grid>
              </Grid>
            }

            {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
         onClose={handleClose}
          severity="warning" sx={{ width: '100%' }}>
          WhatsApp integration is either not active or undefined!
        </Alert>
      </Snackbar> */}


          </Box>

        </div>
      </div>
      {/* {open && <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={Style.modal_wrap}>
          <div className="row mx-0 px-0">
            <div className="col-12 mx-0 px-0">
              <div className={Style.modal_header + " pt-4"}>
              <h5>Connect your WhatsApp</h5> 
                <p className="mt-0">Ready to reach out to many via WhatsApp? Connect your account now to start broadcasting.</p>
                <div className={Style.modal_cancel}
                 onClick={() => {
       setOpen(false); // Close the modal
       navigate('/broadcast'); // Navigate to the '/broadcast' page
     }}
                   >
                  <CloseOutlinedIcon />
                </div>
              </div>
              <div className={Style.modal_content}>
                <div className={Style.connect_wrapper + " d-flex"}>

                  <div className="col-md-12 px-2">
                    <div className={Style.integration_card + " "}>
                      <div className={Style.card_header + " card-header"}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className={Style.integration_logo}>
                            <div className="d-flex align-items-center">
                               <img src={whatsApp} alt="WhatsApp" className="img-fluid" />
                                <div className={Style.card_body + " ms-2 mt-0 mb-0"}> <h2>WhatsApp</h2></div>
                            </div>
                            
                          </div>
                          <button
                            className={(chatbotId ? userdata?.integrationKeys.whatsapp.isActive && (chatBotData?.channels?.includes("whatsapp")) : userdata?.integrationKeys.whatsapp.isActive) ? Style.integration_btn_green : Style.integration_btn}

                            onClick={() => {
                              if (userdata?._id && (!userdata?.subscription?.active || moment(userdata?.subscription?.endDate) < moment())) {
                                setIsSubscribed(false);
                              }
                              else if (chatbotId && userdata?.integrationKeys.whatsapp.isActive) {
                                let updatedChannels = [];
                                if (chatBotData?.channels?.includes("whatsapp")) {
                                  updatedChannels = chatBotData?.channels?.filter(channel => channel !== "whatsapp");;
                                } else {
                                  updatedChannels = [...chatBotData?.channels, "whatsapp"];
                                }
                                connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "whatsapp");
                              }
                              else {

                                if (userdata?.integrationKeys.whatsapp.isActive) {
                                  setshowWhatsappmodal(true);
                                } else {
                                  launchWhatsAppSignup(chatbotId, chatBotData, connectChatbotChannels, setchatBotData, "whatsapp");
                                }
                              }
                            }}
                            disabled={!validIntegration?.whatsapp}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"     >
                              <path d={svgPath} />
                            </svg>
                            {userdata?.integrationKeys.chatbot.isActive && chatBotData?.channels?.includes("chatBot")
                              ? "Connected"
                              : "Connect"}

                            {ischatbotLoading === "whatsapp" && (
                              <CircularProgress
                                style={{
                                  color: chatBotData?.channels?.includes("whatsapp") ? "white" : '#3874ff',
                                  width: '16px',
                                  height: '16px',
                                  position: 'relative',
                                  top: '3px',
                                  marginLeft: '3px',
                                }}
                              />
                            )}
                          </button>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>} */}

    </>
  );
};

export default AddBroadcast;