import React, { useCallback, useEffect, useState } from "react";

import Style from "./templates.module.css";
import { Table } from "reactstrap";
import TemplateCard from "./TemplateCard";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import TextInput from "src/components/forms/TextInput";
import { debounce } from "src/helper/commonHelp";
import SelectInput from "src/components/forms/SelectInput";
import { templatStatusOption } from "src/constants/formPicker";
import CachedIcon from "@mui/icons-material/Cached";
import BasicPagination from "src/components/custom/pagination";
import { useNavigate } from "react-router-dom";
import useTemplateData from "./hooks/useTemplateData";
import Loader from "src/layouts/loader/Loader";
import NoDataFound2 from "src/components/custom/NoDataFound2";
import NoUsersFound from "src/assets/images/noDataFound/NoUsersFound.png";

const Templates = () => {
  const navigation = useNavigate();
  const { fetchTemplates, isLoading, templateData } = useTemplateData();

  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [status, setStatus] = useState("all");
  const [agency, setAgency] = useState("master");
  const [searchUser, setSearchUser] = useState("");

  const userTxtChangeDebounce = useCallback(
    debounce((txtval) => {
      setPage(1);
      fetchTemplates(agency, txtval, status, false);
    }, 1000),
    []
  );

  // const handleClear = () => {
  //   setPage(1);
  //   setStatus("");
  //   setSearchUser("");
  //   setAgency("");
  // };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleTemplateData = () => {
    fetchTemplates(agency, searchUser, status, false);
  };

  useEffect(() => {
    handleTemplateData();
  }, [status, agency]);

  return (
    <div
      className="py-4 px-5"
      style={{ background: "#F8FAFE", minHeight: "calc(100vh - 56px)" }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className={Style.title}>Template Message</div>
        <button  className={Style.add_btn} onClick={() => { navigation("/templates/add");}}>
          <span> <AddIcon sx={{ fontSize: "16px" }} /> </span>
          <span>New Message Template</span>
        </button>
      </div>
      <div className="d-md-flex d-block  align-items-center mt-3">
        <div style={{ position: "relative" }}>
          <TextInput
            customClass={Style.custom_search}
            type="text"
            name="searchValue"
            value={searchUser}
            onChange={(e) => {
              setSearchUser(e.target.value.trim());
              userTxtChangeDebounce(e.target.value.trim());
            }}
            variant="outlined"
            placeholder="Search by name "
            size={"small"}
          />
          {searchUser ? (
            <div
              className={Style.reset_btn}
              onClick={() => {
                setSearchUser("");
                userTxtChangeDebounce("");
              }}
            >
              <HighlightOffIcon
                size={14}
                className=" text-muted"
                style={{ cursor: "pointer" }}
              />
            </div>
          ) : (
            <div className={Style.search_icon}>
              {" "}
              <SearchIcon currentPage />{" "}
            </div>
          )}
        </div>
        <div style={{ marginLeft: "20px", width: "15%" }}>
          <SelectInput
            name="agency"
            label="Agency"
            options={[{ label: "Master", value: "master" }]}
            selectedValue={agency}
            onChange={(e) => {
              setPage(1);
              setAgency(e.target.value);
            }}
            size={"small"}
            customClass={Style.custom_select}
            sx={{ backgroundColor: "#F5F8FA" }}
            noOptionText={"No agency found."}
            disabled={true}
          />
        </div>
        <div style={{ marginLeft: "20px", width: "15%" }}>
          <SelectInput
            name="status"
            label="Status"
            options={templatStatusOption}
            selectedValue={status}
            onChange={(e) => {
              setPage(1);
              setStatus(e.target.value);
            }}
            size={"small"}
            customClass={Style.custom_select}
            sx={{ backgroundColor: "#F5F8FA" }}
            noOptionText={"No status found."}
          />
        </div>
        <div style={{ marginLeft: "20px" }}>
          <CachedIcon
            sx={{ color: "#2379F0", cursor: "pointer" }}
            onClick={() => {fetchTemplates(agency, searchUser, status, true);}}
          />
        </div>
      </div>

      <Table
        
        bordered
        className={Style.template_table + " mt-3 border "}
      >
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Name</th>
            <th>Type</th>
            <th>Content</th>
            <th>Agency</th>
            <th>Category</th>
            <th>Status</th>
            <th>Template ID</th>
            <th>Action</th>
          </tr>
        </thead>
        {isLoading ? (
          <Loader borderWidth={"4px"} />
        ) : templateData && templateData?.length > 0 ? (
          <tbody>
            {templateData?.map((template, index) => {
              return (
                <TemplateCard
                  data={template}
                  page={page}
                  rowPerPage={rowPerPage}
                  index={index}
                  fetchTemplates={handleTemplateData}
                />
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="9">
                <NoDataFound2
                  width={"180px"}
                  text="No Data Found!"
                  image={NoUsersFound}
                />
              </td>
            </tr>
          </tbody>
        )}
      </Table>

      <div className="mt-2 pagination-position mb-3">
        <BasicPagination
          count={Math.ceil(templateData?.length / rowPerPage)}
          currentPage={page}
          onChange={handlePageChange}
          rowPerPage={rowPerPage}
          onRowChange={(e) => {
            setRowPerPage(e.target.value);
            setPage(1);
          }}
          totalCount={templateData?.length}
        />
      </div>
    </div>
  );
};

export default Templates;
