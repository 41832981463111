import React, { useRef, useState } from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import Downshift from "downshift";
import CloseIcon from "@mui/icons-material/Close";
import SuggestionPopover from "./SuggestionList";

import {
  Popper,
  List,
  ListItem,
  ListItemText,
  Paper,
  ClickAwayListener,
} from "@mui/material";

export default function PropertySelectInput({
  tag,
  setValue,
  suggestions,
  handleDelete,
  rows,
  multiline,
  handleText,
  customClass,
  fieldCustomClass,
  ...props
}) {
  const { placeholder, ...other } = props;

  const listRef = useRef(null);

  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const CustomChip = styled(Chip)({
    backgroundColor: "#F5F9FA",
    color: "#33475B",
    fontFamily: "poppins",
    fontWeight: "500",
    fontSize: "12px",
    marginRight: "5px",
    border: "1px solid #668195",
    borderRadius: "3px",
    height: "25px",
    width:"50%"
  });

  const CloseStyle = styled(CloseIcon)({
    fill: "#33475B",
    fontSize: "10px",
    width: "18px",
  });

  // Find property name by ID
  const getPropertyNameById = (id) => {
    const property = suggestions?.find((item) => item._id === id);
    return property ? property.propertyAddress?.addressLine1 : "";
  };

  const handleSelectSuggestion = (suggestion) => {
    if (suggestion && tag !== suggestion) {
      setValue(suggestion);
      setInputValue("");
      setAnchorEl(null);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  function handleChange(item) {
    if (tag !== item) {
      setValue(item);
    }
  }

  return (
    <React.Fragment>
      <Downshift
        id="downshift-single"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={tag}
      >
        {({ getInputProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            placeholder,
          });

          const filteredSuggestions = suggestions.filter((suggestion) =>
            suggestion?.propertyAddress?.addressLine1
              ?.toLowerCase()
              .includes(inputValue?.toLowerCase())
          );

          return (
            <div className={""} style={{position:"relative"}}>
              <TextField
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: tag ? (
                    <CustomChip
                      key={tag}
                      tabIndex={-1}
                      label={getPropertyNameById(tag)}
                      onDelete={() => handleDelete(tag)}
                      deleteIcon={<CloseStyle />}
                    />
                  ) : null,
                  onBlur,
                  onChange: (event) => {
                    setAnchorEl(event.currentTarget);
                    setInputValue(event.target.value);
                    onChange(event);
                  },
                  onFocus,
                }}
                {...other}
                {...inputProps}
                size="small"
                multiline={multiline ? true : false}
                minRows={rows}
                className={customClass}
                // label={null}
              />

              <Popper
                open={Boolean(anchorEl) && filteredSuggestions?.length > 0}
                anchorEl={anchorEl}
                placement="bottom-start"
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [0, 8],
                    },
                  },
                ]}
                sx={{ zIndex: "9999", width: "28%" }}
              >
                <ClickAwayListener onClickAway={handleClosePopover}>
                  <Paper>
                    <List
                      sx={{
                        maxHeight: 300,
                        overflow: "auto",
                      }}
                      // onKeyDown={handleKeyDown}
                      ref={listRef}
                    >
                      {filteredSuggestions?.map((suggestion, index) => (
                        <ListItem
                        sx={{
                          "&:hover": {
                            backgroundColor: "#4EA3FF42",
                          },
                          fontSize: "14px",
                          minHeight: "10px",
                        }}
                        button
                        key={index}
                        onClick={() => handleSelectSuggestion(suggestion?._id)}
                      >
                        <ListItemText
                          primaryTypographyProps={{ sx: { fontSize: "13px", textTransform:"capitalize" } }}
                          primary={suggestion?.propertyAddress?.addressLine1}
                        />
                      </ListItem>
                      
                      ))}
                    </List>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </div>
          );
        }}
      </Downshift>
    </React.Fragment>
  );
}
