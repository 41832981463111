import React, { useState, useEffect, useRef } from 'react';
import { Popper, List, ListItem, ListItemText, Paper, ClickAwayListener } from '@mui/material';

const SuggestionPopper = ({ inputText, suggestions, anchorEl, onSelect, open, onClose, highlightedIndex, handleKeyDown ,locationCode}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const listRef = useRef(null);
  useEffect(() => {
    if (inputText) {
      const filtered = suggestions?.filter(suggestion =>
        suggestion?.toLowerCase()?.includes(inputText?.toLowerCase())
      );
      setFilteredSuggestions(filtered);

      // Close Popper if no filtered suggestions are found
      if (filtered?.length === 0) {
        onClose();
      }
    } else {
      setFilteredSuggestions([]);
      onClose(); // Close Popper if inputText is empty
    }

  }, [inputText, suggestions, onClose, highlightedIndex]);

  const handleSelect = (suggestion) => {
    onSelect(suggestion);
    onClose();
  };



  useEffect(() => {
    if (open && listRef.current && highlightedIndex >= 0) {
      const listItem = listRef.current?.children[highlightedIndex];
      if (listItem) {
        listItem.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth'
        });
      }
    }
  }, [highlightedIndex, open]);


  useEffect(() => {
    if (filteredSuggestions.length > 0) {
      handleKeyDown(filteredSuggestions);

    }

  }, [highlightedIndex])


  return (
    <Popper
      open={open && filteredSuggestions?.length > 0} // Only open if there are suggestions
      anchorEl={anchorEl}
      placement="bottom-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 8], // Adjust popper offset from the anchor element
          },
        },
      ]}
      sx={{ zIndex: "9999", width: locationCode === "Code" ? '27%' : '16%' }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper>
          <List sx={{
            maxHeight: 300, // Set max height for the list
            overflow: 'auto', // Enable scrolling

          }}
            ref={listRef}
          >
            {filteredSuggestions?.map((suggestion, index) => (
              <ListItem sx={{
                '&:hover': {
                  backgroundColor: '#4EA3FF42',

                },
                fontSize: '14px',
                minHeight: '10px',
                backgroundColor: highlightedIndex === index ? '#4EA3FF42' : 'transparent',

              }}

                button key={index}
                onClick={() => handleSelect(suggestion)}
              >
                <ListItemText
                  sx={{
                    fontSize: '14px',

                  }}
                  primary={suggestion}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default SuggestionPopper;
