import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import moment from "moment";
import { Box, Grid } from "@mui/material";
import { Table } from "reactstrap";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import Style from "../broadcast.module.css";
import TextInput from "src/components/forms/TextInput";
import { debounce } from "src/helper/commonHelp";
import SelectInput from "src/components/forms/SelectInput";
import { BroadcastStatusOptions, Labels } from "src/constants/formPicker";
import BasicPagination from "src/components/custom/pagination";
import useBroadcast from "../hooks/useBroadcast";
import Loader from "src/layouts/loader/Loader";
import NoDataFound2 from "src/components/custom/NoDataFound2";
import NoUsersFound from "src/assets/images/noDataFound/NoUsersFound.png";
import CountCards from "./CountCards";
import BroadCastDetailCard from "./BroadcastDetailCard";
import BroadCastPreview from "../BroadCastPreview";

const BroadcastDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isLoading, fetchBroadcastDetail, broadcastDetailData, chatLoading, chatConvirations, fetchRepliedChat } = useBroadcast();
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [searchVal, setSearchVal] = useState("");
  const [status, setStatus] = useState("all");

  const userTxtChangeDebounce = useCallback(
    debounce((txtval) => {
      setPage(1);
      fetchBroadcastDetail(id, status, txtval, page, rowPerPage);
    }, 1000),
    []
  );

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const replaceVariables = (text, variables) => {
    let updatedText = text;

    if (variables) {
      variables?.forEach((variable) => {
        updatedText = updatedText?.replace(variable.placeholder, variable.value);
      });
    }

    return updatedText;
  };

  function formatString(input) {
    return input
      .split("-")?.map((word) => word?.toString()?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(" ");
  }

  useEffect(() => {
    fetchBroadcastDetail(id, status, searchVal, page, rowPerPage);
  }, [id, status, page, rowPerPage]);

  return (
    <div className="" style={{ background: "#F8FAFE", height: "calc(100vh - 57px)", overflow: "hidden" }} >
      <Box className="">
        {(isLoading && (status === "all" || !status) && !searchVal) ? (<Loader borderWidth={"4px"} />) :
          <Grid container spacing={0} className="mt-1">
            <Grid item xs={12} sm={12} md={9} lg={9} className={Style.custom_scroll} style={{ padding: "2rem 1rem 1rem 4rem" }}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <ArrowBackIosIcon sx={{ cursor: "pointer" }} onClick={() => { navigate("/broadcast-new"); }} />
                  <div className={Style.title}>{broadcastDetailData?.name}</div>
                </div>
                <div className="d-flex align-items-center">
                  <div className={Style.detail_date_title}>Sent on :</div>
                  <div className={Style.detail_date_value}> {broadcastDetailData?.scheduledAt ? moment(broadcastDetailData?.scheduledAt).format('DD MMM, YYYY hh:mm A') : "NA"}</div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-2">
                <div className={Style.detail_date_title}>Property:</div>
                <div className={Style.detail_property} title={broadcastDetailData?.property?.propertyAddress?.addressLine1}> {broadcastDetailData?.property?.propertyAddress?.addressLine1}</div>
              </div>
              <CountCards broadcastCount={broadcastDetailData?.totalStats} status={status} setStatus={setStatus} />
              {/* {broadcastDetailData?.statics?.campaign?.tags?.length > 0 && <div className={Style.subheading + " mb-3"}>Filter Criteria:</div>} */}
              {/* <div className="d-flex align-items-center flex-wrap" style={{ marginRight: "10px" }} >
              {broadcastDetailData?.statics?.campaign?.tags?.length > 0 &&
                broadcastDetailData?.statics?.campaign?.tags?.map((tag, index) => (
                  <div key={index} className={Style.tag_box}>
                    <div className="d-flex align-items-center" style={{ marginRight: "10px" }}>
                      <div className={Style.tag_val}>{tag.label}</div>
                      <span>is</span>
                      <div className={Style.tag_val}> {formatString(tag.value)}</div>
                    </div>
                  </div>
                ))}
            </div> */}
              <div style={{ borderBottom: "1px solid #00000017", marginRight: "-16px", marginTop: "10px" }}></div>
              <div>
                <div className={" my-4 "}>
                  <div className="d-md-flex d-block  align-items-center justify-content-between">
                    <div className=" d-flex  align-items-center ">
                      <div className={Style.detail_contact}> Contacts :</div>
                      <div className={Style.detail_total}> {broadcastDetailData?.recipients?.length}</div>
                    </div>
                    <div className="d-flex  align-items-center " style={{ width: "50%" }}>
                      <div className={Style.detail_subheading}> Status:</div>
                      <div style={{ width: "50%" }}>
                        <SelectInput
                          name="status"
                          // label="Agency"
                          options={BroadcastStatusOptions}
                          selectedValue={status}
                          onChange={(e) => { setStatus(e.target.value); }}
                          size={"small"}
                          customClass={Style.custom_select}
                          sx={{ backgroundColor: "#F5F8FA" }}
                          noOptionText={"No status found."}
                          placeholder={"Status"}
                        />
                      </div>
                      <div style={{ position: "relative", marginLeft: "20px", width: "90%", }}>
                        <TextInput
                          customClass={Style.custom_search + " w-100"}
                          type="text"
                          name="searchValue"
                          value={searchVal}
                          onChange={(e) => {
                            setSearchVal(e.target.value.trim());
                            userTxtChangeDebounce(e.target.value.trim());
                          }} 
                          variant="outlined"
                          placeholder="Search by name & phone ... "
                          size={"small"}
                        />
                        {searchVal ? (
                          <div className={Style.reset_btn}
                            onClick={() => {
                              setSearchVal("");
                              userTxtChangeDebounce("");
                            }}
                          >
                            <HighlightOffIcon size={14} className=" text-muted" style={{ cursor: "pointer" }} />
                          </div>
                        ) : (
                          <div className={Style.search_icon}> <SearchIcon currentPage /> </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Table bordered className={Style.template_table + " " + Style.broadcast_detail + " mt-3 border position-relative"}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Contact</th>
                      <th>User Tags</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  {(isLoading && (status || searchVal)) ? (
                    <Loader borderWidth={"4px"} />
                  ) :

                    broadcastDetailData?.recipients && broadcastDetailData?.recipients?.length > 0 ? (
                      <tbody>
                        {broadcastDetailData?.recipients?.map((contact, index) => {
                          return (
                            <BroadCastDetailCard
                              data={contact}
                              page={page}
                              rowPerPage={rowPerPage}
                              index={index}
                              cardStatus={status === "REPLIED" ? true : false}
                              chatLoading={chatLoading}
                              chatConvirations={chatConvirations}
                              fetchRepliedChat={fetchRepliedChat}
                            />
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="9">
                            <NoDataFound2
                              width={"180px"}
                              text="No Data Found!"
                              image={NoUsersFound}
                            />
                          </td>
                        </tr>
                      </tbody>
                    )
                  }
                </Table>
                <div className="mt-2 pagination-position mb-3">
                  <BasicPagination
                    count={Math.ceil(broadcastDetailData?.contacts / rowPerPage)}
                    currentPage={page}
                    onChange={handlePageChange}
                    rowPerPage={rowPerPage}
                    onRowChange={(e) => {
                      setRowPerPage(e.target.value);
                      setPage(1);
                    }}
                    totalCount={broadcastDetailData?.contacts}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} >
              {/* <BroadCastPreview /> */}
              <BroadCastPreview
                values={{ template_info: broadcastDetailData?.templateInfo }}
                selectedTemplate={broadcastDetailData?.templateId}
                replaceVariables={replaceVariables}
                selectedFile={broadcastDetailData?.templateId?.media}
                detail={true}
              />
            </Grid>
          </Grid>}
      </Box>
    </div>
  );
};

export default BroadcastDetails;
