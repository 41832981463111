import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatMessages: [],
  property: {},
  chatComments: [],
  userList: [],
  hasMoreChats: true,
  selectedUsr: {},
  usersCount: {},
  activeChatType: "unassinged",
  listSelectedClose: false,
  chatCount: 0,
  chatCountData: [],
  filter: {}
};

const messagingSlice = createSlice({
  name: "messaging",
  initialState,
  reducers: {
    saveMessages: (state, action) => {
      if (action.payload) {
        state.chatMessages = action.payload;
      } else {
        state.chatMessages = [];
      }
    },
    saveComments: (state, action) => {
      if (action.payload) {
        state.chatComments = action.payload;
      } else {
        state.chatComments = [];
      }
    },
    addMessages: (state, action) => {
      if (action.payload) {
        state.chatMessages = [...action.payload, ...state.chatMessages];
      } else {
        state.chatMessages = [];
      }
    },
    addComments: (state, action) => {
      if (action.payload) {
        state.chatComments = [...action.payload, ...state.chatComments];
      } else {
        state.chatComments = [];
      }
    },
    appendMessages: (state, action) => {
      state.chatMessages.push(action.payload.chatMessages);
    },
    addProperty: (state, action) => {
      state.property = action.payload;
    },
    updateMessages: (state, action) => {
      if (!Array.isArray(state.chatMessages)) {
        state.chatMessages = [];
      }
      const lastMessageIndex = state.chatMessages.length - 1;
      if (lastMessageIndex >= 0) {
        state.chatMessages[lastMessageIndex]._id = action.payload;
      }
    },
    updateCountChatUsers: (state, action) => {

      if (action.payload) {
        state.usersCount = action.payload;
      } else {
        state.usersCount = {};
      }
    },
    deleteMessageByMessageId: (state, action) => {
      if (action.payload) {
        state.chatMessages = state.chatMessages.filter((chat) => chat?.messageId !== action?.payload?.messageId)
      }
    },
    appendComments: (state, action) => {
      state.chatComments.push(action.payload.comments);
    },
    setUserList: (state, action) => {
      state.userList = action.payload;
    },
    appendChats: (state, action) => {
      if (action.payload && action.payload.length) {
        state.userList = [...state.userList, ...action.payload];
      }

      if (!action.payload.length || action.payload.length < 10) {
        state.hasMoreChats = false;
      }
    },
    setChatsLoadCompleted: (state, action) => {
      state.hasMoreChats = true;
    },
    appendUserList: (state, action) => {
      const payloadAgentIsEmpty = (action?.payload?.agent === null || action?.payload?.agent === undefined) ? true : false;
      const isActiveChatAll = state.activeChatType === "all";
      const isListSelectedCloseFalse = state.listSelectedClose === false;

      const shouldConditionPass =
        (payloadAgentIsEmpty && !action?.payload?.isBot && (state.activeChatType === "unassinged" || (isActiveChatAll && isListSelectedCloseFalse))) ||
        (payloadAgentIsEmpty && action?.payload?.isBot && (state.activeChatType === "bot" || (isActiveChatAll && !isListSelectedCloseFalse))) ||
        (!payloadAgentIsEmpty && ((isActiveChatAll && isListSelectedCloseFalse) || (state.activeChatType === "me" && isListSelectedCloseFalse && !action?.payload?.isBot) || (state.activeChatType === "bot" && action?.payload?.isBot)));

      if (shouldConditionPass) {
        state.userList.unshift(action.payload);
        state.chatCount += 1;
      }

      if (payloadAgentIsEmpty && !action?.payload?.isBot) {
        state.usersCount.unassigned.total += 1;
      } else {
        if (action?.payload?.isBot) {
          state.usersCount.bot.total += 1;
        } else {
          state.usersCount.me.open += 1;
          state.usersCount.me.total += 1;
        }
      }
      state.usersCount.all.total += 1;
      if (action?.payload?.isBot) {
        state.usersCount.all.close += 1;
      }
      else {
        state.usersCount.all.open += 1;
      }

    },

    setSelectedUser: (state, action) => {
      if (action.payload) {
        state.selectedUsr = action.payload;
      }
    },

    markMessagesAsRead: (state, action) => {
      const { userId, newUnreadCount } = action.payload;
      const user = state.userList.find((u) => u._id === userId);
      if (user) {
        user.unreadMessage = newUnreadCount;
      }
    },
    updateLastMessageInUserList: (state, action) => {
      const { userId, newLastMessage } = action.payload;
      const userToUpdate = state.userList.find((usr) => usr._id === userId);
      if (userToUpdate) {
        userToUpdate.lastMessage = newLastMessage;
      }
    },
    clearChat: (state, action) => {
      state.chatMessages = [];
      state.selectedUsr = {};
    },
    updateChatType: (state, action) => {
      if (action.payload) {
        state.activeChatType = action.payload;
      } else {
        state.activeChatType = "";
      }
    },
    updateTabValue: (state, action) => {
      if (action.payload) {
        state.listSelectedClose = action.payload;
      } else {
        state.listSelectedClose = false;
      }
    },
    updateChatCount: (state, action) => {
      if (action.payload) {
        state.chatCount = action.payload;
      } else {
        state.chatCount = 0;
      }
    },
    addChatCountData: (state, action) => {
      if (action.payload) {
        state.chatCountData = action.payload;
      } else {
        state.chatCountData = [];
      }
    },
    updateChatCountData: (state, action) => {
      if (action.payload) {
        for (let i = 0; i < state.chatCountData.length; i++) {
          if (
            state.chatCountData[i].chatCategory === action.payload.category &&
            state.chatCountData[i].agent === action.payload.agent
          ) {
            state.chatCountData[i].count = state.chatCountData[i].count + 1;
            state.chatCountData[i].new = true;

            const matchedRow = state.chatCountData.splice(i, 1)[0];
            state.chatCountData.unshift(matchedRow);

            break;
          }
        }
      }
    },
    setNewFalse: (state, action) => {
      if (action.payload) {
        for (let i = 0; i < state.chatCountData.length; i++) {
          if (
            state.chatCountData[i].chatCategory === action.payload.category &&
            state.chatCountData[i].agent === action.payload.agent
          ) {
            state.chatCountData[i].new = false;
            break;
          }
        }
      }
    },
    removeAssignedChat: (state, action) => {
      if (action.payload) {
        state.userList = state.userList.filter(
          (chat) => chat?._id != action.payload
        );
        state.usersCount.unassigned.total -= 1;
      }
    },
    moveUnassignedChatToMe: (state, action) => {
      if (action.payload) {
        if (state.activeChatType === "unassinged") {
          state.userList = state.userList.filter(
            (chat) => chat?._id != action.payload?.chatId
          );
          state.usersCount.unassigned.total -= 1;
        } else {
          state.usersCount.unassigned.total -= 1;
        }
      }
    },

    updateChatFilter: (state, action) => {
      if (action.payload) {
        state.filter = action.payload;
      }
    },

    removeMissedChat: (state, action) => {

      if (action.payload) {
        if (state.activeChatType === "all" || state.activeChatType === "unassinged") {
        
          state.userList = state.userList.filter((chat) => chat?._id != action.payload?._id);
          state.usersCount.all.close += 1;
          state.usersCount.all.open -= 1;
          state.usersCount.unassigned.total -= 1;
        }
        if (state.activeChatType === "bot") {
          state.userList.unshift(action.payload);
          state.usersCount.bot.total += 1;
          state.usersCount.unassigned.total -= 1;
        }
      }

    }
  },
});

export const {
  saveMessages,
  saveComments,
  addMessages,
  addComments,
  appendMessages,
  deleteMessageByMessageId,
  appendComments,
  updateMessages,
  setUserList,
  appendChats,
  setSelectedUser,
  setChatsLoadCompleted,
  markMessagesAsRead,
  updateLastMessageInUserList,
  appendUserList,
  clearChat,
  updateCountChatUsers,
  updateChatType,
  updateTabValue,
  updateChatCount,
  addChatCountData,
  updateChatCountData,
  setNewFalse,
  removeAssignedChat,
  moveUnassignedChatToMe,
  updateChatFilter,
  removeMissedChat,
  addProperty
} = messagingSlice.actions;
export default messagingSlice.reducer;
