import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
  token: null,
  isLoggedin: Cookies.get("isLoggedin") || null,
  branchList: [],
  selectedBranch: "",
  updateBranch: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const usrInfo = action.payload.data;

      const usrObj = {
        name: usrInfo.name,
        profilePic: usrInfo.avatar,
        _id: usrInfo._id,
        email: usrInfo.email,
        role: usrInfo.userType,
        agency: usrInfo.agency,
        branch: usrInfo.branch,
        branchName: usrInfo.branchName,
        address: usrInfo?.address,
        companyName: usrInfo?.companyName,
        departmentType: usrInfo?.departmentType,
        departmentRole: usrInfo?.departmentRole,
        userCategory: usrInfo?.userCategory,
        agencyCategory: usrInfo?.agencyCategory,
        openingHours: usrInfo?.openingHours,
        branchCategory: usrInfo?.branchCategory,
        roleId: usrInfo?.role,
        permissions: usrInfo?.permissions,
        accountSetupFormStep: usrInfo?.accountSetupFormStep,
        googleAccessToken: usrInfo?.googleAccessToken,
        googleAccessExpiryIn: usrInfo?.googleAccessExpiryIn,
        googleIsActive: usrInfo?.googleIsActive,
        googleSessionExpired: usrInfo?.googleSessionExpired,
        outlookAccessToken: usrInfo.outlookAccessToken,
        outlookAccessExpiryIn: usrInfo.outlookAccessExpiryIn,
        outlookIsActive: usrInfo.outlookIsActive,
        outlookSessionExpired: usrInfo.outlookSessionExpired

      };

      state.user = usrObj;
      state.isLoggedin = true;

      localStorage.setItem("user", JSON.stringify(usrObj));
      Cookies.set("isLoggedin", true, { expires: 7 });
    },

    updateUser: (state, action) => {
      const usrInfo = action.payload.data;
      const usrObj = {
        name: usrInfo.name,
        profilePic: usrInfo.avatar || usrInfo.profilePic,
        id: usrInfo.id,
        _id: usrInfo._id,
        email: usrInfo.email,
        agency: usrInfo.agency,
        branch: usrInfo.branch,
        branchName: usrInfo.branchName,
        role: usrInfo.role,
        address: usrInfo?.address,
        companyName: usrInfo?.companyName,
        departmentType: usrInfo?.departmentType,
        departmentRole: usrInfo?.departmentRole,
        userCategory: usrInfo?.userCategory,
        agencyCategory: usrInfo?.agencyCategory,
        openingHours: usrInfo?.openingHours,
        branchCategory: usrInfo?.branchCategory,
        roleId: usrInfo?.roleId,
        permissions: usrInfo?.permissions,
        accountSetupFormStep: usrInfo?.accountSetupFormStep,

        googleAccessToken: usrInfo?.googleAccessToken,
        googleAccessExpiryIn: usrInfo?.googleAccessExpiryIn,
        googleIsActive: usrInfo?.googleIsActive,
        googleSessionExpired: usrInfo?.googleSessionExpired,

        outlookAccessToken: usrInfo.outlookAccessToken,
        outlookAccessExpiryIn: usrInfo.outlookAccessExpiryIn,
        outlookIsActive: usrInfo.outlookIsActive,
        outlookSessionExpired: usrInfo.outlookSessionExpired

      };
      state.user = usrObj;
      if (action.payload.token) {
        state.token = action.payload.token;
      }
      localStorage.setItem("user", JSON.stringify(usrObj));
    },

    updateToken: (state, action) => {
      state.token = action.payload.token;
    },

    updateAuthorizedStatus: (state, action) => {
      const user = {
        ...state.user,
        isAuthorized: action.payload.isAuthorized,
      };
      state.user = user;
    },

    updateIsLoggedIn: (state, action) => {
      state.isLoggedin = action.payload.isLoggedin;
    },

    logout: (state, action) => {
      state.user = null;
      state.token = null;
      state.isLoggedin = false;
      localStorage.clear();

      // Remove all the cookies
      Object.keys(Cookies.get()).forEach((cookieName) =>
        Cookies.remove(cookieName)
      );
    },

    saveBranchList: (state, action) => {
      if (action.payload) {
        state.branchList = action.payload;
      } else {
        state.branchList = [];
      }
    },

    updateBranch: (state, action) => {
      if (action.payload) {
        state.selectedBranch = action.payload;
      } else {
        state.selectedBranch = "";
      }
    },

    updateFormStep: (state, action) => {
      if (action.payload) {
        state.user.accountSetupFormStep = action.payload;
      } else {
        state.user.accountSetupFormStep = 1;
      }
    }
  },
});

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;

export const {
  setCredentials,
  setSwitchUser,
  updateUser,
  updateToken,
  updateAuthorizedStatus,
  updateIsLoggedIn,
  logout,
  saveBranchList,
  updateBranch,
  updateFormStep
} = authSlice.actions;

export default authSlice.reducer;
