
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { BsTrash } from "react-icons/bs";

import TextInput from "../../components/forms/TextInput";
import ErrorMsg from "../../components/custom/ErrorMsg";
import * as Yup from "yup";
import { useFormik } from "formik";
import Axios from "../../utils/axios";
import Toast from "../../components/custom/Toast";
import { ButtonLoader } from "../../components/forms/ButtonLoader";
import { useRef } from "react";
import Loader from "src/layouts/loader/Loader";
import NoDataFound2 from "src/components/custom/NoDataFound2";
import NoReviewFound from "src/assets/images/noDataFound/NoReviewFound.png";
import DeleteModal from "src/components/custom/DeleteModal";

const DemoSetup = () => {
  const fileInputRef = useRef(null);
  const backgroundImageRef = useRef(null);
  const logoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setDataIsLoading] = useState(false);
  const [imageError, setImageError] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [demoList, setDemoList] = useState([]);
  const [showdelete, setShowdelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const emailRegex =
    /^[^\W_](?:[a-zA-Z0-9.!#$%&'*+/=?^`{|}~-]*[^\W_])?@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,2}$/;

  function capitalizeFirstLetter(string) {
    return string?.toString()?.charAt(0)?.toUpperCase() + string?.toString()?.slice(1);
  }

  const handleClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleBackgroundClick = () => {
    if (backgroundImageRef && backgroundImageRef.current) {
        backgroundImageRef.current.click();
    }
  };

  const handleLogoClick = () => {
    if (logoRef && logoRef.current) {
        logoRef.current.click();
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email.")
      .test("custom", "Please enter a valid email address.", (value) => {
        const trimmedValue = value?.trim(); // Remove leading and trailing spaces
        return emailRegex?.test(trimmedValue);
      }),
   
    // Add validation for other fields if needed
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      slug: "",
      description: "",
      image: null,
      backgroundImage:null,
      template:"",
      email:"",
      logoImage: null
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (imageError) {
          return 0;
        }
        setIsLoading(true);
        const formdata = new FormData();
        formdata.append("title", values.title);
        formdata.append("slug", values.slug);
        formdata.append("image", values.image);
        formdata.append("backgroundImage", values.backgroundImage);
        formdata.append("logoImage", values.logoImage);
        formdata.append("description", values.description);
        formdata.append("template", values.template);
        formdata.append("email", values.email);
        
        const updateData = await Axios.post(`/demo/create`, formdata);
        if (updateData?.data?.success) {
          Toast(updateData?.data?.message, "success");
          formik.resetForm();
          setSelectedImage("");
          setBackgroundImage("");
          setLogoImage("");
          fetchData();
        }
      } catch (error) {
        Toast(error.message, "error", );
      }
      finally {
        setIsLoading(false);
      }
    },
  });

  const fetchData = async () => {
    setDataIsLoading(true);
    try {
      const response = await Axios.get(`/demo/list`);
      setDemoList(response?.data?.data);
      setDataIsLoading(false);
    } catch (error) {
      Toast(error, "error");
      setDataIsLoading(false);
    }
  };

  const handleDelete = (demoId) => {
    Axios.delete(`/demo/delete`, {
      data: {demoId: demoId,},
    })
      .then(() => {
        fetchData();
        setShowdelete(false);
        setDeleteId("");
        Toast('Demo data deleted successfully.', "success");
      })
      .catch((err) => {
        Toast(err, "error");
      });
  };

  useEffect(() => {
      fetchData();
    }, // eslint-disable-next-line
    []);

  return (
    <div className="p-3">
      
      {isDataLoading ? (
        <Loader />
      ) : (
        <div style={{backgroundColor:"#fff", minHeight:"100vh"}}>
            <div className="p-4">
                <div className="">
                    <div className="d-flex">
                        <div className="" style={{marginRight:"20px"}}>
                            <div className="mb-3">
                                {selectedImage ? (
                                    <img
                                    className="profile_img"
                                    src={URL.createObjectURL(selectedImage)}
                                    alt="wrong-url"
                                    width="120"
                                    height="120"
                                    style={{ borderRadius: "10px", objectFit: "contain" }}
                                    />
                                ) : (
                                    <img
                                    className="profile_img "
                                    src={"/images/default_bg.png"}
                                    alt="wrong-url"
                                    width="120"
                                    height="120"
                                    style={{ borderRadius: "10px", objectFit: "contain" }}
                                    />
                                )}
                            </div>
                            <div className="w-100 mb-3">
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    multiple
                                    onChange={(e) => {
                                    setImageError("")
                                    if (e.currentTarget.files[0].type.includes('image')) {
                                        setSelectedImage(e.currentTarget.files[0]);
                                        formik.setFieldValue(
                                        "image",
                                        e.currentTarget.files[0]
                                        );
                                    } else if (e.currentTarget.files?.length > 0) {
                                        setImageError("Invalid file type, only images are allowed.")
                                    }
                                    else { setImageError("") }}}
                                    hidden
                                />
                                <button
                                    type="button"
                                    onClick={handleClick}
                                    style={{background: "white", border: "1px solid darkgray", borderRadius: "5px", padding: "5px 10px",  fontSize: "14px", fontWeight: "700",  }}
                                >
                                    Upload new image
                                </button>
                                <ErrorMsg hasError={imageError} >
                                    {imageError}
                                </ErrorMsg>
                            </div>
                        </div>
                        <div className="" style={{marginRight:"20px"}}>
                            <div className="mb-3">
                                {logoImage ? (
                                    <img
                                    className="profile_img"
                                    src={URL.createObjectURL(logoImage)}
                                    alt="wrong-url"
                                    width="120"
                                    height="120"
                                    style={{ borderRadius: "10px", objectFit: "contain" }}
                                    />
                                ) : (
                                    <img
                                    className="profile_img "
                                    src={"/images/default_bg.png"}
                                    alt="wrong-url"
                                    width="120"
                                    height="120"
                                    style={{ borderRadius: "10px", objectFit: "contain" }}
                                    />
                                )}
                            </div>
                            <div className="w-100 mb-3">
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={logoRef}
                                    multiple
                                    onChange={(e) => {
                                    setImageError("")
                                    if (e.currentTarget.files[0].type.includes('image')) {
                                        setLogoImage(e.currentTarget.files[0]);
                                        formik.setFieldValue(
                                        "logoImage",
                                        e.currentTarget.files[0]
                                        );
                                    } else if (e.currentTarget.files?.length > 0) {
                                        setImageError("Invalid file type, only images are allowed.")
                                    }
                                    else { setImageError("") }}}
                                    hidden
                                />
                                <button
                                    type="button"
                                    onClick={handleLogoClick}
                                    style={{background: "white", border: "1px solid darkgray", borderRadius: "5px", padding: "5px 10px",  fontSize: "14px", fontWeight: "700",  }}
                                >
                                    Upload chatbot logo
                                </button>
                                <ErrorMsg hasError={imageError} >
                                    {imageError}
                                </ErrorMsg>
                            </div>
                        </div>
                        <div className="">
                            <div className="mb-3">
                                {backgroundImage ? (
                                    <img
                                    className="profile_img"
                                    src={URL.createObjectURL(backgroundImage)}
                                    alt="wrong-url"
                                    width="120"
                                    height="120"
                                    style={{ borderRadius: "10px", objectFit: "contain" }}
                                    />
                                ) : (
                                    <img
                                    className="profile_img "
                                    src={"/images/default_bg.png"}
                                    alt="wrong-url"
                                    width="120"
                                    height="120"
                                    style={{ borderRadius: "10px", objectFit: "contain" }}
                                    />
                                )}
                            </div>
                            <div className="w-100 mb-3">
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={backgroundImageRef}
                                    multiple
                                    onChange={(e) => {
                                    setImageError("")
                                    if (e.currentTarget.files[0].type.includes('image')) {
                                        setBackgroundImage(e.currentTarget.files[0]);
                                        formik.setFieldValue(
                                        "backgroundImage",
                                        e.currentTarget.files[0]
                                        );
                                    } else if (e.currentTarget.files?.length > 0) {
                                        setImageError("Invalid file type, only images are allowed.")
                                    }
                                    else { setImageError("") }}}
                                    hidden
                                />
                                <button
                                    type="button"
                                    onClick={handleBackgroundClick}
                                    style={{background: "white", border: "1px solid darkgray", borderRadius: "5px", padding: "5px 10px",  fontSize: "14px", fontWeight: "700",  }}
                                >
                                    Upload background image
                                </button>
                                <ErrorMsg hasError={imageError} >
                                    {imageError}
                                </ErrorMsg>
                            </div>
                        </div>
                </div>
                    
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <div className="mb-3">
                                <TextInput
                                    customClass={"small-input w-100  "}
                                    type="text"
                                    name="title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    placeholder="Title"
                                    label="Title"
                                />
                                <ErrorMsg hasError={  formik.touched.title && formik.errors.title} > {formik.errors.title}</ErrorMsg>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="mb-3">
                                <TextInput
                                    customClass={"small-input w-100  "}
                                    type="text"
                                    name="slug"
                                    value={formik.values.slug}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    placeholder="Slug"
                                    label="Slug"
                                />
                                <ErrorMsg   hasError={ formik.touched.slug && formik.errors.slug  } >{formik.errors.slug} </ErrorMsg>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="mb-3">
                                <TextInput
                                    customClass={"small-input w-100  "}
                                    type="text"
                                    name="template"
                                    value={formik.values.template}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    placeholder="Template"
                                    label="Template"
                                />
                                <ErrorMsg   hasError={ formik.touched.template && formik.errors.template  } >{formik.errors.template} </ErrorMsg>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="mb-3">
                                <TextInput
                                    customClass={"small-input w-100  "}
                                    type="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    variant="outlined"
                                    placeholder="Account Email"
                                    label="Account Email"
                                />
                                <ErrorMsg   hasError={ formik.touched.email && formik.errors.email  } >{formik.errors.email} </ErrorMsg>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <TextInput
                            customClass={"small-input w-100  "}
                            type="text"
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            variant="outlined"
                            placeholder="Enter description .."
                            label="Description"
                            rows={3}
                            multiline
                        />
                        <ErrorMsg hasError={formik.touched.description && formik.errors.description } > {formik.errors.description}</ErrorMsg>
                    </div>
                    <ButtonLoader
                    onClickhandle={formik.handleSubmit}
                    buttonName="Add "
                    isLoading={isLoading}
                    loaderColor={"white"}
                    style={{
                        paddingLeft: "12px",
                        paddingRight: "12px",
                    }}
                    className="btn btn-primary"
                    ></ButtonLoader>
                </div>
                <Table bordered className="text-nowrap mt-1 align-middle mb-0 mt-5" borderless>
                    <>
                    <thead>
                        <tr>
                        <th>S.N.</th>
                        <th>Title</th>
                        <th>Email</th>
                        <th>Slug</th>
                        <th>Template</th>
                        <th>Description</th>
                        <th style={{textAlign:"end", paddingRight:"20px"}}> Action</th>
                        </tr>
                    </thead>
                    {demoList.length > 0 ? 
                    <tbody>
                        {demoList?.map((tdata, index) => (
                        <tr key={tdata.image} className="border-top">
                            <td>{index + 1}</td>
                            <td > <h6 className="mb-0">  {capitalizeFirstLetter(tdata?.title) || "NA"} </h6></td>
                            <td > <h6 className="mb-0">  {tdata?.email} </h6></td>
                            <td> {tdata?.slug || "NA"}</td>
                            <td> {tdata?.template || "NA"}</td>
                            <td > {capitalizeFirstLetter(tdata?.description)} </td>
                            <td style={{textAlign:"end", paddingRight:"30px" }}><BsTrash
                                title="Delete"
                                onClick={() => {
                                    setShowdelete(true);
                                    setDeleteId(tdata?._id)
                                }}
                                style={{ cursor: "pointer", fontSize: "16px", color: "#474a4d" }}
                                />
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    :
                    <tbody>
                        <tr>
                            <NoDataFound2 width={"180px"} text="No Demo Data Found!" image={NoReviewFound} />
                        </tr>
                    </tbody>
                    }
                    </>
                
            </Table>
            </div>
           
        </div>
      )}
      <DeleteModal
        open={showdelete}
        deletType="demo"
        text="demo"
        closeModal={() => {
          setShowdelete(false);
        }}
        handleDelete={() => {
          handleDelete(deleteId);
        }}
      />
    </div>
  );
};

export default DemoSetup;

