import styles from './QrCode.module.css';
import QRCode from 'qrcode';
import { useEffect, useRef, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import qrImg from "src/assets/images/channels/qr_image.png";
import { convertNameToSlugString } from 'src/utils/Validator';
const logoUrl = 'https://jinni-bot-storage.s3.amazonaws.com/leads/black-logo2_1723205484990.jpg';


const Index = ({ url, size = 100 ,downloadSize=200,qrDonloadName="image.png"}) => {
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const canvasRef = useRef(null);
    useEffect(() => {
        if (url) {
            const encodedUrl = encodeURI(url);
            QRCode.toDataURL(encodedUrl, { width: size })
                .then(dataUrl => setQrCodeUrl(dataUrl))
                .catch(err => console.error('Error generating QR code URL:', err));
        }

    }, [url]);


    // Draw QR code and logo on the canvas
    useEffect(() => {

        const drawQRCodeWithLogo = async (canvasRef) => {
            if (!qrCodeUrl) return;

            const canvas = canvasRef.current;
            if (!canvas) return;

            const ctx = canvas.getContext('2d');
            if (!ctx) return;

            const qrImage = new Image();
            qrImage.crossOrigin = 'anonymous';
            qrImage.src = qrCodeUrl;

            const logoImage = new Image();
            logoImage.crossOrigin = 'anonymous';
            logoImage.src = logoUrl;

            try {
                await Promise.all([
                    new Promise((resolve, reject) => {
                        qrImage.onload = resolve;
                        qrImage.onerror = reject;
                    }),
                    new Promise((resolve, reject) => {
                        logoImage.onload = resolve;
                        logoImage.onerror = reject;
                    })
                ]);

                canvas.width = size;
                canvas.height = size;

                // Draw the QR code image
                ctx.drawImage(qrImage, 0, 0, size, size);

                // Draw the logo image
                const logoSize = size / 6; // Adjust size as needed
                const logoX = (size - logoSize) / 2;
                const logoY = (size - logoSize) / 2;

                ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);

            } catch (error) {
                console.error('Error loading images:', error);
            }
        };

        drawQRCodeWithLogo(canvasRef);

    }, [qrCodeUrl, logoUrl]);

    const downloadQRCode = () => {
        const canvas = document.createElement('canvas'); // Create a new canvas for download
        canvas.width = downloadSize;
        canvas.height = downloadSize;

        const ctx = canvas.getContext('2d');
        if (!ctx || !qrCodeUrl) return;

        const qrImage = new Image();
        qrImage.crossOrigin = 'anonymous';
        qrImage.src = qrCodeUrl;

        const logoImage = new Image();
        logoImage.crossOrigin = 'anonymous';
        logoImage.src = logoUrl;

        qrImage.onload = () => {
            ctx.drawImage(qrImage, 0, 0, downloadSize, downloadSize);

            const logoSize = downloadSize / 6; // Adjust size for download
            const logoX = (downloadSize - logoSize) / 2;
            const logoY = (downloadSize - logoSize) / 2;

            logoImage.onload = () => {
                ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);

                // Trigger download
                const link = document.createElement('a');
                link.download = "qr-code-"+ convertNameToSlugString(qrDonloadName);
                link.href = canvas.toDataURL('image/png');
                link.click();
            };
        };
    };

    return <> {qrCodeUrl ? <div  >
        <span className={styles.download_icon}>
            <FileDownloadIcon onClick={downloadQRCode}   />
        </span>
            
       
        <canvas ref={canvasRef} /></div> : <img src={qrImg} className="img-fluid" />} </>
}

export default Index;