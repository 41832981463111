import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Axios from "src/utils/axios";
import { markMessagesAsRead, saveComments, saveMessages, addComments, addMessages, setSelectedUser, setUserList, appendChats, setChatsLoadCompleted, updateCountChatUsers, updateChatCount, updateTabValue, addProperty } from "src/store/apps/chat/MessageSlice";
import Toast from "src/components/custom/Toast";

const useChatUser = () => {
  const dispatch = useDispatch();
  const [isUserLoading, setUserLoading] = useState(false);
  const [isChatFetching, setChatFetching] = useState(false);
  const [allAssignUser, setallAssignUser] = useState([]);
  const [conversationList, setConversationList] = useState([]);
  const [userCount, setUserCount] = useState(null);
  const [noteList, setNoteList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const selectedUsr = useSelector((state) => state.messageSlice.selectedUsr);
  const [oldChatHistory, setOldChatHistory] = useState([]);
  const userList = useSelector((state) => state.messageSlice.userList);
  const userObj = useSelector((state) => state.auth.user);
  const [TagListData, setTagListData] = useState(null)
  const [tags, setTags] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);

  const getChatList = async (chatType, listSelectedClose, search, pageNumber = 1, limit = 10, appendData = false, platform,
    category, agent, chatId, branch, agencyId, firstTime = false) => {
    try {
      if (!appendData) {
        setUserLoading(true);
        dispatch(setSelectedUser({}));
      }  // If we are appending the data then keep this false to prevent loader again

      const params = {
        isActive: listSelectedClose ? "closed" : "open",
        chatType: chatType,
        search: search || "",
        page: pageNumber,
        perPage: limit,
        platform: platform,
        category: category || [],
        agent: agent || [],
        userCategory: userObj?.userCategory || [],
        chatId: chatId,
        branch: branch || [],
        agencyId: agencyId,
      };

      dispatch(setChatsLoadCompleted());
      const userResp = await Axios.get(`/chatuser/all-chatuser`, { params });

      if (!userResp?.data?.success) {
        throw new Error("Error occured while fetching the user.");
      }
      if (appendData) {
        if (userResp?.data?.data?.length) {
          dispatch(appendChats(userResp?.data?.data));
        }
      } else {
        if (chatId && userResp?.data?.perticularChat?.isActive && listSelectedClose && firstTime) {
          dispatch(updateTabValue(false));
        }
        else if (chatId && (!userResp?.data?.perticularChat?.isActive && !listSelectedClose && firstTime)) {
          dispatch(updateTabValue(true));
        }
        else {
          if (userResp?.data?.data?.length) {
            dispatch(setUserList(userResp?.data?.data));
            dispatch(updateChatCount(userResp?.data?.totol));

            dispatch(setSelectedUser({ ...userResp?.data?.data[0] }));
            getChatsBetweenUsers(userResp?.data?.data[0]?._id);
            readAllmessage(userResp?.data?.data[0]?._id);
            // handleNewValue(data?.chatCategory, data?._id)
          } else {
            dispatch(setUserList([]));
            dispatch(setSelectedUser({}));
            dispatch(saveMessages([]));
            dispatch(updateChatCount(0));
          }
        }

      }
    } catch (err) {
      Toast(err, "error");
    } finally {
      setUserLoading(false);
    }
  };

  const readAllmessage = (id) => {
    Axios.post("/botChat/readAllMessage", { conversationId: id })
      .then((res) => {
        dispatch(markMessagesAsRead({ userId: id, newUnreadCount: res.data.updatedChat?.unreadMessage }));
      })
      .catch((err) => {
        Toast(err, "error");
      });
  };

  const getChatsBetweenUsers = async (userId) => {
    try {
      const params = {
        conversationId: userId,
      };
      setChatFetching(true);
      const userResp = await Axios.get(`/botChat/getbotMessage`, { params });
      setChatFetching(false);
      if (!userResp?.data?.success) {
        throw new Error("Error occured while fetching the user.");
      }
      if (userResp?.data?.data) {
        dispatch(saveMessages(userResp?.data?.data?.chatMessages));
        dispatch(saveComments(userResp?.data?.data?.comments));
        dispatch(addProperty(userResp?.data?.data?.propertyId))
      } else {
        dispatch(saveMessages([]));
      }
    } catch (err) {
      setChatFetching(true);
    }
  };

  const changeChatStatus = async (status, chatId, handleUserChatList) => {
    try {
      var obj = {
        chatStatus: status,
        chatId: chatId,
      };
      const userResp = await Axios.put(`/chatuser/update-status`, obj);

      if (userResp?.data?.success) {
        countChatUsers();
        handleUserChatList();
        Toast(`Chat has been successfully moved to the ${status}.`, "success");
      } else {
        Toast("Error occured while changing chat status user.", "error");
      }
    } catch (err) {
      Toast(err, "error");
    }
  };

  const blockChatUser = async (chatId) => {
    try {
      var obj = { chatId };
      const userResp = await Axios.put(`/chatuser/block-chatuser`, obj);

      if (userResp?.data?.success) {
        Toast("User blocked successfully.", "success");
      } else {
        Toast("Error occured while block user.", "error");
      }
    } catch (err) {
      Toast(err, "error");
    }
  };

  function convertArrayToLabelValue(array) {
    return array?.map((item) => ({ label: item.name, value: item._id }));
  }

  const fetchOldConversations = async (chatUserMetaDataId) => {
    try {
      if (selectedUsr?.chatUserMetaData?._id) {
        setIsLoading(true);
        const params = {
          chatUserMetaDataId: selectedUsr?.chatUserMetaData?._id,
        };
        const conversation = await Axios.get(`/chatuser/old-chat`, { params });
        if (conversation?.data?.success) {
          const filteredConversations = conversation.data.data.filter(
            (conv) => conv._id !== selectedUsr._id
          );
          setConversationList(filteredConversations);
          setIsLoading(false);
        }
      } else {
        setConversationList([]);
        dispatch(setSelectedUser({}));
      }
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };

  const countChatUsers = async (platform, category, agent, chatId, branch, agencyId) => {
    try {
      let params = {
        platform: platform,
        category: category || [],
        agent: agent || [],
        userCategory: userObj?.userCategory,
        chatId: chatId,
        branch: branch || [],
        agencyId
      };
      const count = await Axios.get(`/chatuser/count-list`, { params });
      if (count?.data?.success) {
        setUserCount(count?.data?.data);
        dispatch(updateCountChatUsers(count?.data?.data));
      }
    } catch (error) {
      Toast(error, "error");
    }
  };

  const AddNotes = async (chatId, noteMessage) => {
    try {
      setIsLoading(true);
      var obj = {
        chatId: chatId,
        note: noteMessage,
      };
      const createNote = await Axios.post(`/chatuser/add-note`, obj);

      if (createNote?.data?.success) {
        setIsLoading(false);
        notesList(chatId);
        Toast("Note added successfully.", "success");
      } else {
        Toast("Error occured while adding notes.", "error");
        setIsLoading(false);
      }
    } catch (err) {
      Toast(err, "error");
      setIsLoading(false);
    }
  };

  const notesList = async (chatId) => {
    try {
      const params = {
        chatId: chatId,
      };
      if (chatId) {
        const noteList = await Axios.get(`/chatuser/get-note`, { params });
        if (noteList?.data?.success) {
          setNoteList(noteList?.data?.data || []);
        }
      } else {
        setNoteList([]);
        setConversationList([]);
        dispatch(setSelectedUser({}));
      }
    } catch (error) {
      Toast(error, "error");
    }
  };



  const deleteNote = async (chatId, noteId) => {
    try {
      var obj = {
        chatId: chatId,
        noteId: noteId,
      };
      const createNote = await Axios.put(`/chatuser/delete-note`, obj);

      if (createNote?.data?.success) {
        notesList(chatId);
        Toast("Note deleted successfully.", "success");
      } else {
        Toast("Error occured while deleting notes.", "error");
      }
    } catch (err) {
      Toast(err, "error");
    }
  };

  const handleSuggestionList = async (chatId) => {
    try {

      const suggestionList = await Axios.get(`/tag/suggestion-list`);
      if (suggestionList?.data?.success) {
        setSuggestionList(suggestionList?.data?.data || []);
      }
    } catch (error) {
      Toast(error, "error");
    }
  };

  const TagsList = async (chatId) => {
    try {
      const params = {
        chatId: chatId,
      };
      if (chatId) {
        const noteList = await Axios.get(`/chatuser/get-tag`, { params });
        if (noteList?.data?.success) {
          setTagListData(noteList?.data?.data || []);
        }
      } else {
        // setNoteList([]);
        // setConversationList([]);
        // dispatch(setSelectedUser({}));
      }
    } catch (error) {
      Toast(error, "error");
    }
  };

  const AddTagList = async (chatId, noteMessage) => {
    try {
      setIsLoading(true);

      const createTag = await Axios.post(`/chatuser/add-tag`, { chatId: chatId, tag: noteMessage });

      if (createTag?.data?.success) {
        setIsLoading(false);
        // notesList(chatId);
        Toast(createTag?.data?.message, "success");
      } else {
        Toast("Error occured while adding notes.", "error");
        setIsLoading(false);
      }
    } catch (err) {
      Toast(err, "error");
      setIsLoading(false);
    }
  };

  const deleteTagList = async (chatId, value) => {
    try {
      var obj = {
        chatId: chatId,
        value: value,
      };

      setIsLoading(true);
      const DeleteTag = await Axios.put(`/chatuser/delete-tag`, obj);
      const filteredData = tags.filter(item => value !== item);
      if (DeleteTag?.data?.success) {
        setTags(filteredData)
        setIsLoading(false);
        Toast(DeleteTag?.data?.message, "success");
      } else {
        Toast("Error occured while deleting notes.", "error");
      }
    } catch (err) {
      Toast(err, "error");
    }
  };

  const updateUserDetail = async (chatId, values) => {
    try {
      setIsLoading(true);
      var obj = {
        chatUserId: chatId,
        ...values,
      };
      const updateUser = await Axios.put(`/chatuser/update-chatuser-info`, obj);

      if (updateUser?.data?.success) {
        var obj = {
          ...selectedUsr,
        };

        obj.chatUserMetaData = {
          _id: obj?.chatUserMetaData?._id,
          block: obj?.chatUserMetaData?.block,
          name: updateUser?.data?.data?.name,
          email: updateUser?.data?.data?.email,
          alternativeEmail: updateUser?.data?.data?.alternativeEmail,
          phone: updateUser?.data?.data?.phone,
          companyName: updateUser?.data?.data?.companyName,
          alternativeEmailActive:
            updateUser?.data?.data?.alternativeEmailActive,
        };
        dispatch(setSelectedUser(obj));
        let updatedList = userList.map((item) =>
          item.chatUserMetaData._id === chatId
            ? {
              ...item,
              chatUserMetaData: {
                ...item?.chatUserMetaData,
                name: updateUser?.data?.data?.name,
              },
            }
            : item
        );
        dispatch(setUserList(updatedList ? updatedList : userList));
        setIsLoading(false);
      } else {
        Toast("Error occured while adding notes.", "error");
        setIsLoading(false);
      }
    } catch (err) {
      Toast(err, "error");
      setIsLoading(false);
    }
  };

  const updateInterestedInDetail = async (chatId, values) => {
    try {
      var obj = {
        chatId: chatId,
        ...values,
      };
      const updateUser = await Axios.put(`/chatuser/update-intrest`, obj);

      if (updateUser?.data?.success) {
        var obj = {
          ...selectedUsr,
        };
        obj.interestedIn = updateUser?.data?.data?.interestedIn;
        dispatch(setSelectedUser(obj));
      } else {
        Toast("Error occured while adding notes.", "error");
      }
    } catch (err) {
      Toast(err, "error");
    }
  };

  const getDaysDifference = (date) => {
    const inputDate = new Date(date);
    const currentDate = new Date();

    // Set the time of both dates to midnight (00:00:00)
    inputDate.setUTCHours(0, 0, 0, 0);
    currentDate.setUTCHours(0, 0, 0, 0);

    const timeDifference = currentDate - inputDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
      return "Today";
    } else if (daysDifference === 1) {
      return "Yesterday";
    } else {
      const formattedDate = inputDate.toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
      });
      return formattedDate;
    }
  };

  const sendTranscript = async (chatUser) => {
    try {
      var obj = {
        chatId: chatUser,
      };
      const sendTranscript = await Axios.post(
        `/transcriptEmail/sendmail-chat`,
        obj
      );

      if (sendTranscript?.data?.success) {
        Toast("Transcript sent successfully.", "success");
      } else {
        Toast("Error occured while adding notes.", "error");
      }
    } catch (err) {
      Toast(err, "error");
    }
  };

  const fetchChatMessages = async (chatId) => {
    const params = {
      chatId: chatId,
    };
    try {
      setIsLoading(true);
      const chatMessage = await Axios.get(`/chatuser/chat-history`, { params });
      if (chatMessage?.data?.success) {
        setOldChatHistory(chatMessage?.data?.chat);
        setIsLoading(false);
      }
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };
  const handleUpdateOldChat = async (chat, setScrollCounter) => {
    try {
      const params = {
        conversationId: chat._id,
      };
      setChatFetching(true);
      setScrollCounter((prev) => prev + 1);
      const userResp = await Axios.get(`/botChat/getbotMessage`, { params });
      setChatFetching(false);
      if (!userResp?.data?.success) {
        throw new Error("Error occured while fetching the user.");
      }
      if (userResp?.data?.data) {
        dispatch(
          addMessages([
            ...userResp?.data?.data?.chatMessages,
            {
              sender: "",
              message: `Previous ${chat.platform === "chat_bot" ? "website" : chat?.platform
                } chat was closed by ${chat?.closed?.closedBy?.name || "bot"} at ${moment(
                  chat?.closed?.createdAt
                ).format("hh:mm A DD/MM/YYYY")}`,
              type: "chatChange",
              read: true,
              _id: "",
            },
          ])
        );
        dispatch(addComments([...userResp?.data?.data?.comments]));
      } else {
        dispatch(saveMessages([]));
      }
    } catch (err) {
      setChatFetching(true);
    }
  };

  function pickFirstLetters(name) {
    const words = name?.split(" ");
    const initials = words?.map((word) => word[0]?.toUpperCase())?.join("");
    return initials;
  }

  const removeActiveChat = () => {
    Axios.post("/botChat/removeActiveChat", {})
      .then(() => { })
      .catch((error) => {
        Toast(error, "error");
      });
  };

  return {
    isChatFetching,
    isUserLoading,
    getChatList,
    getChatsBetweenUsers,
    changeChatStatus,
    allAssignUser,
    convertArrayToLabelValue,
    fetchOldConversations,
    conversationList,
    readAllmessage,
    countChatUsers,
    userCount,
    AddNotes,
    notesList,
    noteList,
    deleteNote,
    updateUserDetail,
    getDaysDifference,
    sendTranscript,
    updateInterestedInDetail,
    isLoading,
    fetchChatMessages,
    chatMessages,
    blockChatUser,
    oldChatHistory,
    pickFirstLetters,
    setNoteList,
    setConversationList,
    handleUpdateOldChat,
    removeActiveChat,
    TagsList,
    TagListData,
    AddTagList,
    deleteTagList,
    tags,
    setTags,
    handleSuggestionList,
    suggestionList,
    setIsLoading
  };
};
export default useChatUser;
