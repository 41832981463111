import React, { useCallback, useEffect, useState } from "react";

import Style from "./AccountSetup2.module.css";
import TextInput from "src/components/forms/TextInput";
import { departmentRole, departmentType } from "src/constants/formPicker";
import SelectInput from "src/components/forms/SelectInput";
import { Box, Grid } from "@mui/material";
import ErrorMsg from "src/components/custom/ErrorMsg";
import AstericSymbol from "src/components/custom/astericSymbol";
import { debounce } from "src/helper/commonHelp";
import Axios from "src/utils/axios";


const BranchDetailForm = ({ errors, touched, values, handleChange, handleDetailNextButton, branch, index, placesData, setPlacesData, fetchPlacesList }) => {

    const [showList, setShowList] = useState(false);
    const [error, setError] = useState("");


    const fetchPlaceDetail = (place_id, address) => {
        Axios.get(`/places/place-detail-by-address-id?place_id=${place_id}`)
            .then((res) => {
                handleChange({
                    target: {
                        name: `branches[${index}].location`,
                        value: { displayAddress: address, ...res.data.data },
                    },
                });

            })
            .catch((err) => {
                setError(err);
            });
    };

    const userTxtChangeDebounce = useCallback(
        debounce((txtval) => fetchPlacesList(txtval), 800),
        []
    );



    const onChangeAddress = (e) => {
        handleChange({
            target: {
                name: `branches[${index}].location`,
                value: { displayAddress: e.target.value },
            },
        });
        if (e.target.value.length > 0) {
            userTxtChangeDebounce(e.target.value);
            setShowList(true);
        }
    };

    const handleLocationClick = (location) => {
        if (location) {
            fetchPlaceDetail(location.place_id, location?.description);

        }
        setPlacesData([]);
        setShowList(false);
    };



    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className={Style.input_design + " "}>
                            <div className="d-grid gap-2">
                                <label>Branch Name<span className="red_required_span">{" "} *{" "}</span> </label>
                                <TextInput
                                    customClass={Style.input_custom + " w-100"}
                                    type="text"
                                    name={`branches[${index}].branchName`}
                                    value={values.branches[index]?.branchName}
                                    onChange={handleChange}
                                    variant="outlined"
                                    placeholder="Branch Name"
                                    size={"small"}
                                />
                                <ErrorMsg hasError={touched?.branches?.[index]?.branchName && errors?.branches?.[index]?.branchName}>{errors?.branches?.[index]?.branchName} </ErrorMsg>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className={Style.input_custom + " "}>
                            <div className="d-grid gap-2">
                                <label>Branch Website </label>
                                <TextInput
                                    customClass={" w-100"}
                                    type="text"
                                    name={`branches[${index}].branchWebsite`}
                                    value={values.branches[index]?.branchWebsite}
                                    onChange={handleChange}
                                    variant="outlined"
                                    placeholder="Branch Website"
                                    size={"small"}
                                />
                                <ErrorMsg hasError={touched?.branches?.[index]?.branchWebsite && errors?.branches?.[index]?.branchWebsite}>{errors?.branches?.[index]?.branchWebsite} </ErrorMsg>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className="d-grid gap-2">
                            <label>
                                {" "}
                                Deals With{" "}
                                <span className="red_required_span"> *</span>
                            </label>
                            <SelectInput
                                name={`branches[${index}].dealsWith`}
                                selectedValue={values?.branches[index]?.dealsWith}
                                placeholder="Deals With"
                                size={"small"}
                                options={departmentType}
                                customClass={Style.custom_select}
                                // onChange={handleChange}
                                onChange={(value) => {
                                    handleChange(`branches[${index}].dealsWith`)(value);
                                }}
                            />
                            <ErrorMsg hasError={touched?.branches?.[index]?.dealsWith && errors?.branches?.[index]?.dealsWith}>{errors?.branches?.[index]?.dealsWith} </ErrorMsg>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <div className="d-grid gap-2">
                            <label> Type of Agency <span className="red_required_span"> * </span>
                            </label>
                            <SelectInput
                                name={`branches[${index}].typeOfAgency`}
                                selectedValue={values.branches[index]?.typeOfAgency}
                                placeholder={"Type of Agency"}
                                size={"small"}
                                options={departmentRole}
                                customClass={Style.custom_select}
                                onChange={(value) => {
                                    handleChange(`branches[${index}].typeOfAgency`)(value);
                                }}
                            />
                            <ErrorMsg hasError={touched?.branches?.[index]?.typeOfAgency && errors?.branches?.[index]?.typeOfAgency}>{errors?.branches?.[index]?.typeOfAgency} </ErrorMsg>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}    >
                        <div className="d-grid gap-2">
                            <label className={Style.labelform}>
                                Branch Address 
                                {/* <AstericSymbol /> */}
                            </label>
                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="location"
                                value={values?.branches[index]?.location?.displayAddress}
                                onChange={(value) => {
                                    onChangeAddress(value)
                                }}
                                variant="outlined"
                                placeholder="Address"
                                size={"small"}
                            />
                            {showList &&
                                (placesData?.length > 0 ? (
                                    <div className={Style.place_card} onMouseLeave={() => setShowList(false)}>
                                        {placesData?.map((place, index) => (
                                            <div

                                                className={Style.placeList}
                                                key={index}
                                            >
                                                <p
                                                    className="mb-0"
                                                    key={index}
                                                    onClick={() =>
                                                        handleLocationClick(place)
                                                    }
                                                >
                                                    {place.description}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    error && (
                                        <div className={Style.place_card}>
                                            {" "}
                                            <div className={Style.placeList}>
                                                {error}
                                            </div>
                                        </div>
                                    )
                                ))}
                            {/* <ErrorMsg hasError={errors?.branches?.[index]?.location?.displayAddress} > {errors?.branches?.[index]?.location?.displayAddress} </ErrorMsg> */}
                        </div>
                    </Grid>
                </Grid>
            </Box>
            {/* <button  type="button" className={Style.move_btn}  onClick={handleDetailNextButton}>Move to next step</button> */}
        </div>
    );
};

export default BranchDetailForm;
