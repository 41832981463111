import React from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

const SelectInput = ({
  customClass,
  options,
  selectedValue,
  placeholder,
  onChange,
  label,
  onBlur,
  disabled,
  sx,
  size,
  onFocus,
  handleKeyDown,
  branch
}) => {
  return (
    <FormControl fullWidth size={size ? size : ""}>
      <InputLabel id="demo-simple-select-label">{label ? label : ""}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue}
        label={label ? label : ""}
        onChange={onChange}
        className={customClass}
        disabled={disabled}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 0, // Remove border-radius
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 0, // Remove border-radius from the outline
          },
          ...(sx || {}), // Merge additional styles if provided via `sx` prop
        }}
        displayEmpty={placeholder ? true : false}
        onFocus={onFocus}
        MenuProps={{
          style: { maxHeight: 350 },
          PaperProps: { onKeyDown: handleKeyDown },
        }}
      >
        {placeholder && (
          <MenuItem hidden value={""} sx={{ fontSize: "14px", color: "grey" }}>
            {placeholder}
          </MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem key={option.value} className="menu-item" value={option.value} sx={{ fontSize: "14px" }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
