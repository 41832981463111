import { useEffect, useState } from "react";
import Toast from "src/components/custom/Toast";
import Axios from "src/utils/axios";
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

const useConnectChannels = () => {

  const location = useLocation();
  let agencyId = new URLSearchParams(location.search)?.get("user");
  const userObj = useSelector((state) => state.auth.user);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showtelegrammodal, setshowtelegrammodal] = useState(false);
  const [showWhatsappmmodal, setshowWhatsappmodal] = useState(false);
  const [showFacebookmodal, setshowFacebookmodal] = useState(false);
  const [showInstagrammodal, setshowInstagrammodal] = useState(false);
  const [disconnectLoading, setDisconnectLoading] = useState(false);
  const [allQrConnection,setAllQrConnection]=useState(null);
  const [existConnection, setExistConnection] = useState('');
  const [existLoading, setExistsLoading] = useState(false);

  const [validIntegration, setValidIntegration] = useState({
    facebook: true,
    instagram: true,
    telegram: true,
    whatsapp: true,
    chatbot: true,
  });

  const [calenderData, setCalenderData] = useState();
  const [userdata, setUserdata] = useState();

  const [isLoading, setIsLoading] = useState();
  const [ischatbotLoading, setIschatbotLoading] = useState();

  const checkIntegration = (data) => {
    if (data.subscription[0].plan === "Basic Plan") {
      setValidIntegration({
        facebook: true,
        instagram: false,
        telegram: true,
        whatsapp: true,
        chatbot: true,
      });
    } else {
      setValidIntegration({
        facebook: true,
        instagram: true,
        telegram: true,
        whatsapp: true,
        chatbot: true,
      });
    }
  };

  const launchInstagramSignup = (chatbotId, chatBotData, connectChatbotChannels, setchatBotData) => {
    const fbLoginPromise = new Promise((resolve, reject) => {
      window.fbAsyncInit();

      window.FB.login(
        function (response) {
          if (response.authResponse) {
            resolve(response.authResponse.accessToken);
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          config_id: process.env.REACT_APP_INSTAGRAM_CONFIG_ID,
          // config_id:"1876237166138938", // Jinnibot_App
          //  config_id: '764463788992239', // propertyJinni
          // config_id: '834829778409130', // propertyJinniBot
          response_type: "token",
          override_default_response_type: true,
          extras: {
            setup: {},
          },
        }
      );
    });

    // Use the promise with async/await
    fbLoginPromise
      .then(async (accessToken) => {
        const data = {
          accessToken,
          // userId: userObj?._id,
          platform: "instagram",
        };

        try {
          const GenerateToken = await Axios.post(`/GenerateToken/getToken`, data);

          if (GenerateToken.status === 200) {

            if (chatbotId) {
              let updatedChannels = [...chatBotData?.channels, "instagram"];
              connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "instagram");
            }
            Toast("Instagram Connected", "success");
            setUserdata({
              integrationKeys: GenerateToken.data.data.integrationKeys,
              subscription: GenerateToken.data.data.subscription[0],
            });
            checkIntegration({
              integrationKeys: GenerateToken.data.data.integrationKeys,
              subscription: GenerateToken.data.data.subscription,
            });
          }
        } catch (error) {
          Toast(error, "error");
        }
      })
      .catch((error) => {
        Toast(error, "error");
      });
  };

  const LoginWithFacebook = (chatbotId, chatBotData, connectChatbotChannels, setchatBotData) => {
    window.fbAsyncInit(); // old

    window?.FB?.login(
      function (response) {
        if (response.status === "connected") {
          window?.FB?.api("/me", { fields: "name, email, picture, first_name" },
            async function (userResponse) {
              try {
                const { first_name, id, name, email, picture } = userResponse;
                const { accessToken, data_access_expiration_time, expiresIn } =
                  response.authResponse;

                const userdata = {
                  first_name,
                  userID: id,
                  name,
                  email,
                  picture: picture.data.url,
                  expiresIn,
                  accessToken,
                  data_access_expiration_time,
                };

                Axios.post(`/facebook/facebook-login`, userdata)
                  .then((res) => {
                    if (chatbotId) {
                      let updatedChannels = [...chatBotData?.channels, "facebook"];
                      connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "facebook");
                    }
                    setUserdata({
                      integrationKeys: res.data.data.integrationKeys,
                      subscription: res.data.data.subscription[0],
                    });
                    checkIntegration({
                      integrationKeys: res.data.data.integrationKeys,
                      subscription: res.data.data.subscription,
                    });
                  })
                  .catch((error) => {
                    console.log(error, "facebook_login_error");
                    Toast("Error generating token:", "error");
                  });
              } catch (error) {
                Toast(error, "error");
              }
            }
          );
        } else {
          // Toast("User cancelled login or did not fully authorize.", "error");
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: process.env.REACT_APP_FACEBOOK_CONFIG_ID,
        // config_id:"1877134746073420",
        //config_id:"3300556893410417", // propertyJinni user access
        //config_id:"924858689024178", // propertyJinniBot user access
        response_type: "token", // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: { setup: {} },
      }
    );
  };

  function launchWhatsAppSignup(chatbotId, chatBotData, connectChatbotChannels, setchatBotData) {
    // Launch Facebook login

    window.fbAsyncInit();
    window?.FB.login(
      function (response) {

        if (response.authResponse) {
          const accessCode = response.authResponse.code;
          if (response.status === "connected") {
            //Use this token to call the debug_token API and get the shared WABA's ID
            Axios.post(`/whatsapp/setup`, { accessCode: accessCode })
              .then((res) => {
                if (chatbotId) {
                  let updatedChannels = [...chatBotData?.channels, "instagram"];
                  connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "instagram");
                }
                setUserdata({
                  integrationKeys: res.data.data.integrationKeys,
                  subscription: res.data.data.subscription[0],
                });
                checkIntegration({
                  integrationKeys: res.data.data.integrationKeys,
                  subscription: res.data.data.subscription,
                });
              })
              .catch((error) => {
                Toast(error, "error");
              });
          }
        } else {
          return null;
        }
      },
      {
        config_id:"8545376615573676",
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {
          setup: {},
        },
      }
    );
  }

  const disConnectFacebook = () => {
    setDisconnectLoading(true);
    Axios.post(`/facebook/facebookDisconnect`, {})
      .then((res) => {
        setUserdata({
          integrationKeys: res.data.data.integrationKeys,
          subscription: res.data.data.subscription[0],
        });
        checkIntegration({
          integrationKeys: res.data.data.integrationKeys,
          subscription: res.data.data.subscription,
        });
        setDisconnectLoading(false);
        setshowFacebookmodal(false);
      })
      .catch((error) => {
        Toast(error, "error");
        setDisconnectLoading(false);
        setshowFacebookmodal(false);
      });
  };
  const disConnectInstagram = () => {
    setDisconnectLoading(true);
    Axios.post(`/generateToken/instagramDisconnect`, {})
      .then((res) => {
        setUserdata({
          integrationKeys: res.data.data.integrationKeys,
          subscription: res.data.data.subscription[0],
        });
        checkIntegration({
          integrationKeys: res.data.data.integrationKeys,
          subscription: res.data.data.subscription,
        });
        setshowInstagrammodal(false);
        setDisconnectLoading(false);
      })
      .catch((error) => {
        Toast(error, "error");
        setshowInstagrammodal(false);
        setDisconnectLoading(false);
      });
  };

  const disConnectWhatsapp = () => {
    setDisconnectLoading(true);
    Axios.post(`/whatsapp/whatsappDisconnect`, {})
      .then((res) => {
        setUserdata({
          integrationKeys: res?.data?.data?.integrationKeys,
          subscription: res?.data?.data?.subscription[0],
        });
        checkIntegration({
          integrationKeys: res?.data?.data?.integrationKeys,
          subscription: res?.data?.data?.subscription,
        });
        setDisconnectLoading(false);
        setshowWhatsappmodal(false);
      })
      .catch((error) => {
        Toast(error, "error");
        setDisconnectLoading(false);
        setshowWhatsappmodal(false);
      });
  };

  const getUserListData = () => {

    agencyId = agencyId ? agencyId : (typeof userObj.agency === "string" ? userObj.agency : (typeof userObj.agency === "object" ? userObj.agency._id : agencyId));

    if ((userObj.role !== 'agent')) {
      Axios.get(`/user/get-agency-details?agencyId=${agencyId}`)
        .then((res) => {
          setUserdata({
            ...res?.data?.data,
            subscription: res?.data?.data?.subscription[0] || res?.data?.data?.createdBy?.subscription[0],
          });
          checkIntegration(res?.data?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          Toast(err, "error");
          setIsLoading(false);
        });
    }

  };

  const getUserAccountData = () => {
    Axios.get("/user/getUserDetail")
      .then((res) => {
        setCalenderData({
          integrationKeys: res?.data?.data?.integrationKeys,
        });
        // checkIntegration(res?.data?.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        Toast(err, "error");
        // setIsLoading(false);
      });
  };

  const connectChatbotChannels = (channels, chatbotId, setchatBotData, platformName) => {
    setIschatbotLoading(platformName);
    Axios.post(`/chatBot/updateChatbot/${chatbotId}`, { channels: channels })
      .then((res) => {
        setchatBotData(res.data?.data);
        setIschatbotLoading(false);
        getAllConnectedQrConnection();
      }).catch((err) => {
        Toast(err, "error");
        setIschatbotLoading(false);
      })
  }

  const confirmExistingConnection = async (channels, setOpenBots, setExistsLoading) => {
    try {
      const res = await Axios.get(`/chatBot/existing-connection?channels=${channels}`);
      return res.data?.data?.length > 0
    } catch (err) {
      return false; // An error occurred
    }
  };
  
  const getAllConnectedQrConnection = async () => {
    try {
      const res = await Axios.get(`/chatBot/allQr-connection`);
       setAllQrConnection(res.data?.data)
    } catch (err) {
      return false; // An error occurred
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getUserListData();
    getUserAccountData()
  }, []);

  return {
    showConnectModal,
    setShowConnectModal,
    showtelegrammodal,
    setshowtelegrammodal,
    showWhatsappmmodal,
    setshowWhatsappmodal,
    showFacebookmodal,
    setshowFacebookmodal,
    showInstagrammodal,
    setshowInstagrammodal,
    disconnectLoading,
    setDisconnectLoading,
    validIntegration,
    setValidIntegration,
    userdata,
    setUserdata,
    getUserListData,
    calenderData,
    setCalenderData,
    getUserAccountData,
    checkIntegration,
    launchInstagramSignup,
    LoginWithFacebook,
    launchWhatsAppSignup,
    disConnectFacebook,
    disConnectInstagram,
    disConnectWhatsapp,
    setIsLoading,
    isLoading,
    connectChatbotChannels,
    ischatbotLoading,
    confirmExistingConnection,
    existConnection,
    existLoading,
    getAllConnectedQrConnection,
    allQrConnection
  };
};

export default useConnectChannels;
