import React, { useCallback } from "react";
import styles from "./chatbot.module.css"
import deleteIcon from "../../assets/images/chatBuilder/delete.svg";
import editIcon from "../../assets/images/chatBuilder/edit.svg";
import addIcon from "../../assets/images/chatBuilder/add.svg";
import arrowIcon from "../../assets/images/chatBuilder/arrow.svg";
import propertyImageIcon from "../../assets/images/chatBuilder/pictures_node.png";
import propertyDetails from "../../assets/images/chatBuilder/property_icon.png";
import propertypdfIcon from "../../assets/images/chatBuilder/brochure_node.png";
import wave from "../../assets/images/chatBuilder/wave.png"
import question from "../../assets/images/chatBuilder/question.png"
import message from "../../assets/images/chatBuilder/message.png"
import liveChat from "../../assets/images/chatBuilder/live_chat.png"
import location from "../../assets/images/chatBuilder/location.png"
import valuation from "../../assets/images/chatBuilder/valuation.png"
import viewing from "../../assets/images/chatBuilder/viewing.png"
import callback from "../../assets/images/chatBuilder/callback.png"
import user from "../../assets/images/chatBuilder/user.png"
import option from "../../assets/images/chatBuilder/option.png"
import close from "../../assets/images/chatBuilder/closed_icon.png"
import Tooltip from '@mui/material/Tooltip';


const CustomNode = ({ chatbotPageType, nodeData, toggleNode, toggleVisibility, onAddButtonClick, onEditButtonClick, onDeleteButtonClick, getNodeLabel }) => {

  const handleAddButtonClick = useCallback(() => {
    onAddButtonClick(nodeData);
  }, [onAddButtonClick, nodeData]);

  const handleEditButtonClick = useCallback(() => {
    onEditButtonClick(nodeData);

  }, [onEditButtonClick, nodeData]);

  const handleDeleteButtonClick = useCallback(() => {
    if (nodeData) {
      onDeleteButtonClick(nodeData);
    }
  }, [onDeleteButtonClick, nodeData]);

  const imageIcon = {
    greeting: wave,
    message: message,
    'property-brochure': propertypdfIcon,
    'property-image': propertyImageIcon,
    'property-details': propertyDetails,
    'capture-user-details': user,
    'live-chat': liveChat,
    "capture-location": location,
    "schedule-valuation": valuation,
    "property-viewing": viewing,
    "arrange-callback": callback,
    option: option,
    question: question,
    "capture-visitor": question,
    "capture-property": question,
    closing: close

  }

  return (
    <>
      {nodeData.nodeType === 'option' ?
        <foreignObject width={150} height={140} x={-75} y={-30} className={styles.showIcon}>
          <div className={styles.node_disp + " " + styles.node_dispo + " " +
            (nodeData?.visitorCategory === "seller" ? styles.seller_node : "") +
            (nodeData?.visitorCategory === "buyer" ? styles.buyer_node : "") +
            (nodeData?.visitorCategory === "landlord" ? styles.landlord_node : "") +
            (nodeData?.visitorCategory === "tenant" ? styles.tenant_node : "") +
            (nodeData?.propertyCategory === "residential" ? styles.residential_node : "") +
            (nodeData?.propertyCategory === "commercial" ? styles.commercial_node : "")
          }
          >
            <div className={styles.node_wrapo + " nodeTxtCont"}>
              <div className={styles.node_txt + " text-center"}>
                <p className={styles.body_text + " botPageType mb-0"}
                  onClick={() => toggleVisibility(nodeData)}
                >{nodeData.nodeDesc} </p>

              </div>
            </div>
            {chatbotPageType === "chatbot" && <div className={styles.icon_wrapper + " nodeButtonCont"} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img className={styles.add_btn + " " + (!(nodeData.children?.length > 0) && styles.addbtn)} src={addIcon} onClick={handleAddButtonClick} alt="Add Node"></img>

            </div>}
          </div>
        </foreignObject>
        :
        <foreignObject width={280} height={180} x={-140} y={-60} className={styles.showIcon}>

          <div className={styles.node_disp + " nodeDisp"} >
            <div className={styles.node_wrap + " nodeTxtCont"}>
              <img src={imageIcon[nodeData.nodeType]} className={styles.status_image} alt="NodeType" />
              <div className={styles.node_txt}>
                <p className={styles.body_head + " botPageType"}> {getNodeLabel(nodeData.nodeType)}</p>
                <Tooltip title={nodeData.nodeDesc} el="" arrow placement="top">
                  <p className={styles.body_text + " botPageType"}
                    dangerouslySetInnerHTML={{ __html: nodeData.nodeDesc }} >
                  </p>
                </Tooltip>
              </div>
            </div>
            {chatbotPageType === "chatbot" &&
              <div className={styles.icon_wrapper + " nodeButtonCont"} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {nodeData.nodeType !== 'greeting' ? <img className={styles.delete_icon} src={deleteIcon} onClick={handleDeleteButtonClick} alt="Delete"></img> : null}

                {((nodeData.nodeType !== 'question' && nodeData.nodeType !== 'capture-visitor' && nodeData.nodeType !== 'capture-property') &&
                  nodeData.nodeType !== 'closing') && (
                    (nodeData?.children?.length > 0)
                      ? ((nodeData?.children[0]?.isVisible === undefined ? true : nodeData?.children[0]?.isVisible) &&
                        <img
                          className={`${styles.add_btn} ${!(nodeData?.children?.length > 0) && styles.addbtn}`}
                          src={addIcon}
                          onClick={handleAddButtonClick}
                          alt="Add"
                        />
                      )
                      : (
                        <img
                          className={`${styles.add_btn} ${styles.addbtn}`}
                          src={addIcon}
                          onClick={handleAddButtonClick}
                          alt="Add"
                        />
                      ))}

                <img className={styles.edit_btn} src={editIcon} onClick={handleEditButtonClick} alt="Edit"></img>

                {nodeData.children?.length > 0 &&
                  <img className={styles.explore_btn} src={arrowIcon}
                    // onClick={toggleNode}
                    onClick={() => toggleVisibility(nodeData)}
                    alt="Expand"></img>
                }
              </div>}
            {chatbotPageType !== "chatbot" &&
              <div className={styles.icon_wrapper + " nodeButtonCont"} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {nodeData.children?.length > 0 &&
                  <img className={styles.explore_btn} src={arrowIcon}
                    // onClick={toggleNode}
                    onClick={() => toggleVisibility(nodeData)}
                    alt="Expand"></img>
                }
              </div>}
          </div>
        </foreignObject>
      }
    </>
  );
};

export default CustomNode;