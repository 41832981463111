import React, { useEffect, useState, useRef } from "react";
import Popper from "@mui/material/Popper";
import Style from "./AccountSetup2.module.css";
import TextInput from "src/components/forms/TextInput";
import { agentTypes, countryCodes, departmentRole, departmentType } from "src/constants/formPicker";
import SelectInput from "src/components/forms/SelectInput";
import CountryCode from "src/components/forms/CountriesCode";
import PhoneNumberWithCountryCode from "src/components/forms/PhoneNumberWithCountryCode";

const TeamForm = ({ index, team, errors, touched, handleChange, agency, setFieldValue, closeError }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");
  const [currentField, setCurrentField] = useState(null);
  const popperRef = useRef(null); // Ref for Popper container

  // Function to handle focus events
  const handleFocus = (event, errorMessage, fieldName) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(errorMessage);
    setCurrentField(fieldName);
  };

  // Function to handle closing the popover
  const handleClose = () => {
    setAnchorEl(null);
    setPopoverContent("");
    setCurrentField(null);
  };

  const open = Boolean(anchorEl);

  // Effect to handle clicks outside to close the Popper
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperRef.current && !popperRef.current.contains(event.target) &&
          !document.querySelector(`input[name^="team"]`).contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatBranches = (agency) => {
    if (agency?.branches) {
      return agency?.branches?.map((branch) => ({
        value: branch?._id,
        label: branch?.branchName,
      }));
    }
    return [];
  };

  const handleDepartment = (teamIndex, branchId) => {
    const branch = agency?.branches?.find(b => b?._id === branchId);
    if (branch) {
      const { dealsWith, typeOfAgency } = branch;

      if (dealsWith?.length === 1) {
        handleChange(`team[${teamIndex}].departmentType`)(dealsWith[0]);
      } else {
        handleChange(`team[${teamIndex}].departmentType`)("");
      }

      if (typeOfAgency?.length === 1) {
        handleChange(`team[${teamIndex}].departmentRole`)(typeOfAgency[0]);
      } else {
        handleChange(`team[${teamIndex}].departmentRole`)("");
      }
    }
  };

  // Effect to update Popper content based on errors
  useEffect(() => {
    if (currentField !== null && errors.team) {
      const teamErrors = errors.team[index];
      if (teamErrors) {
        const errorMessage = teamErrors[currentField];
        if (errorMessage) {
          handleFocus({
            currentTarget: document.querySelector(`[name="team[${index}].${currentField}"]`),
          }, errorMessage, currentField);
        } else {
          handleClose();
        }
      }
      else {
        handleClose();
      }
    } else if (!anchorEl && errors.team) {
      // Show the Popper for the first error if no field is focused
      const firstErrorIndex = errors.team.findIndex((error) => error);
      if (firstErrorIndex !== -1) {
        const firstErrorField = Object.keys(errors.team[firstErrorIndex])[0];
        const errorMessage = errors.team[firstErrorIndex][firstErrorField];
        const element = document.querySelector(`[name="team[${firstErrorIndex}].${firstErrorField}"]`);
        if (element) {
          handleFocus({ currentTarget: element }, errorMessage, firstErrorField);
        }
      }
    }
    else {
      handleClose();
      closeError();
    }
  }, [ touched, errors]);


  return (
    <>
      {team?.map((teamMember, teamIndex) => (
        <tr key={teamIndex} className={Style.table_row}>
          <td>
            <div className={(errors.team?.[teamIndex]?.name && touched.team?.[teamIndex]?.name) ? Style.error_border : ""}>
              <TextInput
                customClass={Style.custom_textfield + " w-100"}
                type="text"
                name={`team[${teamIndex}].name`}
                value={teamMember.name}
                onChange={(e) => {
                  handleChange(e);
                  // Close the Popper if there's no error for this field
                  if (!errors.team?.[teamIndex]?.name) {
                    handleClose();
                  }
                }}
                onFocus={(e) => errors.team?.[teamIndex]?.name && touched.team?.[teamIndex]?.name && handleFocus(e, errors.team[teamIndex].name, 'name')}
                variant="outlined"
                size={"small"}
              />
            </div>
          </td>
          <td>
            <div className={(errors.team?.[teamIndex]?.email && touched.team?.[teamIndex]?.email) ? Style.error_border : ""}>
              <TextInput
                customClass={Style.custom_textfield + " w-100"}
                type="text"
                name={`team[${teamIndex}].email`}
                value={teamMember.email}
                onChange={(e) => {
                  handleChange(e);
                  // Close the Popper if there's no error for this field
                  setPopoverContent(errors.team?.[teamIndex]?.email);
                  if (!errors.team?.[teamIndex]?.email) {
                    handleClose();
                  }
                }}
                onFocus={(e) => errors.team?.[teamIndex]?.email && touched.team?.[teamIndex]?.email && handleFocus(e, errors.team[teamIndex].email, 'email')}
                variant="outlined"
                size={"small"}
              />
            </div>
          </td>
          <td>
            <div className={(errors.team?.[teamIndex]?.phone && touched.team?.[teamIndex]?.phone) ? Style.error_border : ""}>
                <div className="d-flex align-items-center">
                  <div style={{ width:"45%"}}>
                    <CountryCode
                      customClass={Style.custom_textfield + " w-100"}
                      name={`team[${teamIndex}].countryCode`}
                      placeholder={"Country Code"}
                      options={countryCodes}
                      selectedValue={teamMember.countryCode}
                      
                      noOptionText={"No Country Code found."} 
                      onChange={(e) => {
                        handleChange(e);
                        if (!errors.team?.[teamIndex]?.countryCode) {
                          handleClose();
                        }
                      }}
                      onFocus={(e) => errors.team?.[teamIndex]?.phone && touched.team?.[teamIndex]?.phone && handleFocus(e, errors.team?.[teamIndex]?.phone, 'phone')}

                    />
                  </div>
                  <div style={{width:"100%"}}>
                    <PhoneNumberWithCountryCode
                      customClass={Style.custom_textfield + " w-100"}
                      countryCode={teamMember.countryCode}
                      name={`team[${teamIndex}].phone`}
                      value={teamMember.phone}
                      variant="outlined"
                      placeholder="Phone"
                      size={"small"}
                      onChange={(e) => {
                        handleChange(e);
                        if (!errors.team?.[teamIndex]?.phone) {
                          handleClose();
                        }
                      }}
                      onFocus={(e) => errors.team?.[teamIndex]?.phone && touched.team?.[teamIndex]?.phone && handleFocus(e, errors.team?.[teamIndex]?.phone, 'phone')}
                    />
                  </div>
                </div>
                {/* <TextInput
                  customClass={Style.custom_textfield + " w-100"}
                  type="text"
                  name={`team[${teamIndex}].phone`}
                  value={teamMember.phone}
                  onChange={(e) => {
                    handleChange(e);
                    // Close the Popper if there's no error for this field
                    if (!errors.team?.[teamIndex]?.phone) {
                      handleClose();
                    }
                  }}
                  onFocus={(e) => errors.team?.[teamIndex]?.phone && touched.team?.[teamIndex]?.phone && handleFocus(e, errors.team?.[teamIndex]?.phone, 'phone')}
                  variant="outlined"
                  size={"small"}
                /> */}
            </div>
          </td>
          <td>
            <div className={(errors.team?.[teamIndex]?.branch && touched.team?.[teamIndex]?.branch) ? Style.error_border : ""}>
                <SelectInput
                    name={`team[${teamIndex}].branch`}
                    size={"small"}
                    options={formatBranches(agency)}
                    selectedValue={teamMember.branch}
                    onChange={(e) => { 
                      handleChange(`team[${teamIndex}].branch`)(e.target.value); 
                      if (e.target.value) {
                        handleDepartment(teamIndex, e?.target?.value);
                          handleClose();
                      }
                    }}
                    customClass={Style.Custom_table_select}
                    onFocus={(e) => errors.team?.[teamIndex]?.branch && touched.team?.[teamIndex]?.branch && handleFocus(e, errors.team[teamIndex].branch, 'branch')}
                />
            </div>
          </td>
          <td>
            <div className={(errors.team?.[teamIndex]?.designation && touched.team?.[teamIndex]?.designation) ? Style.error_border : ""}>
                <SelectInput
                    name={`team[${teamIndex}].designation`}
                    size={"small"}
                    options={agentTypes}
                    selectedValue={teamMember.designation}
                    onChange={(value) => { 
                        handleChange(`team[${teamIndex}].designation`)(value);
                        if (value) {  handleClose(); }
                    }}
                    customClass={Style.Custom_table_select}
                    onFocus={(e) => errors.team?.[teamIndex]?.designation && touched.team?.[teamIndex]?.designation && handleFocus(e, errors.team[teamIndex].designation, 'designation')}
                />
            </div>
          </td>
          <td>
            <div className={(errors.team?.[teamIndex]?.departmentType && touched.team?.[teamIndex]?.departmentType) ? Style.error_border : ""}>
                <SelectInput
                    name={`team[${teamIndex}].departmentType`}
                    size={"small"}
                    options={departmentType}
                    selectedValue={teamMember.departmentType}
                    onChange={(value) => {
                        handleChange(`team[${teamIndex}].departmentType`)(value);
                        if (value) {  handleClose();}
                    }}
                    disabled={agency?.branches?.find(b => b?._id === teamMember?.branch)?.dealsWith?.length === 1}
                    customClass={Style.Custom_table_select}
                    onFocus={(e) => errors.team?.[teamIndex]?.departmentType && touched.team?.[teamIndex]?.departmentType && handleFocus(e, errors.team[teamIndex].departmentType, 'departmentType')}
                />
            </div>
          </td>
          <td>
            <div className={(errors.team?.[teamIndex]?.departmentRole && touched.team?.[teamIndex]?.departmentRole) ? Style.error_border : ""}>
                <SelectInput
                    name={`team[${teamIndex}].departmentRole`}
                    size={"small"}
                    options={departmentRole}
                    selectedValue={teamMember.departmentRole} 
                    onChange={(value) => {
                        handleChange(`team[${teamIndex}].departmentRole`)(value);
                        if (value) {  handleClose(); }
                    }}
                    disabled={agency?.branches?.find(b => b?._id === teamMember?.branch)?.typeOfAgency?.length === 1}
                    customClass={Style.Custom_table_select}
                    onFocus={(e) => errors.team?.[teamIndex]?.departmentRole && touched.team?.[teamIndex]?.departmentRole && handleFocus(e, errors.team[teamIndex].departmentRole, 'departmentRole')}
                />
            </div>
          </td>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            onClose={handleClose}
            style={{ zIndex: 1300 }} 
            ref={popperRef} 
            sx={{
              background:"#fff",
              boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
              border: "1px solid #EAEAEA",
              padding:"1rem",
              color:"red",
              marginTop:"15px !important",
              borderRadius:"2px",
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right:"50%",
                borderTop: "1px solid #EAEAEA",
                borderLeft: "1px solid #EAEAEA",
                background: "#fff",
                width: 16,
                height: 16,
                transform: "translateY(-50%) rotate(45deg)",
                boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
              }
            }}
          >
            <div className={Style.custom_popper}>
              {popoverContent}
            </div>
          </Popper>
        </tr>
      ))}
    </>
  );
};

export default TeamForm;
