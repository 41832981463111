import React, { useState, useEffect } from "react";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { ButtonLoader, ButtonLoaderLeft, } from "src/components/forms/ButtonLoader";
import Style from "./AccountSetup2.module.css";
import DirectorForm from "./DirectorForm";
import Axios from "src/utils/axios";
import Toast from "src/components/custom/Toast";
import AgentListNew from "./AgentListNew";

const DirectorManagement = ({
  handleNext,
  handleAgencyData,
  agency,
  closeError,
  settings
}) => {
  let agentId = 0;
  const generateUniqueId = () => {
    agentId = agentId + 1;
    return agentId.toString();
  };

  const userObj = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    directors: [
      {
        name: userObj.name || "",
        email: userObj.email || "",
        phone: userObj.phone || "",
        countryCode: userObj?.countryCode || "+44",
      },
      {
        name: "",
        email: "",
        phone: "",
        countryCode: "+44",
      },
    ],
    branches: [
      {
        branchName: "",
        agents: [
          {
            id: generateUniqueId(),
            name: "",
            email: "",
            phone: "",
            countryCode: "+44",
            designation: "",
            departmentType: "residential", // residential
            departmentRole: "both", // both
          },
        ],
      },
    ],
  });



  const validationSchema = Yup.object().shape({
    directors: Yup.array().of(
      Yup.lazy((value) => {
        // if (!value.name && !value.email && !value.phone) {
        //   return Yup.object().shape({});
        // }
        return Yup.object().shape({
          name: Yup.string()
            .matches(
              /^[a-zA-Z ]+$/,
              "Name can only contain alphabetical characters."
            )
            .required("Name is required.")
            .test(
              "not-start-with-space",
              "Name cannot start with a space.",
              (value) => !value.startsWith(" ")
            ),
          email: Yup.string()
            .email("Invalid email format.")
            .required("Email is required.")
            .test("custom", "Please enter a valid email address.", (value) => {
              const parts = value.split("@");
              if (
                parts.length !== 2 ||
                /[^a-zA-Z0-9.-]/.test(parts[0]) ||
                /[^a-zA-Z0-9.-]/.test(parts[1])
              ) {
                return false;
              }

              if (parts.length === 2 && parts[1].split(".").length - 1 > 2) {
                return false;
              }
              return true;
            }),
          phone: Yup.string()
            .matches(/^\+?[0-9]\d{1,12}$/, "Invalid mobile number.")
            .min(10, "Phone number must be at least 10 characters.")
            .max(11, "Phone number cannot exceed 11 characters.")
            .required("Phone Number is required.")
            .test(
              "not-start-with-space",
              "Phone cannot start with a space.",
              (value) => {
                return !value || !value.startsWith(" ");
              }
            ),
          countryCode: Yup.string().required("Country Code is required."),
        });
      })
    ),
    branches: Yup.array().of(
      Yup.object().shape({
        agents: Yup.array()
          .when('$branches', (branches, schema) =>
            branches.length > 1
              ? schema.min(1, "At least one agent is required for each branch.")
              : schema // No minimum agents required if there's only one branch
          )
          .of(
            Yup.object().shape({
              name: Yup.string()
                .matches(/^[a-zA-Z ]+$/, "Name can only contain alphabetical characters.")
                .required("Agent name is required.")
                .test(
                  "not-start-with-space",
                  "Agent name cannot start with a space.",
                  (value) => !value.startsWith(" ")
                ),
              email: Yup.string()
                .email("Invalid email format.")
                .required("Agent email is required.")
                .test("custom", "Please enter a valid email address.", (value) => {
                  const parts = value.split("@");
                  if (
                    parts.length !== 2 ||
                    /[^a-zA-Z0-9.-]/.test(parts[0]) ||
                    /[^a-zA-Z0-9.-]/.test(parts[1])
                  ) {
                    return false;
                  }
                  if (parts.length === 2 && parts[1].split(".").length - 1 > 2) {
                    return false;
                  }
                  return true;
                }),
              designation: Yup.string().required("Designation is required."),
              phone: Yup.string()
                .matches(/^\+?[0-9]\d{1,12}$/, "Invalid mobile number.")
                .min(10, "Phone number must be at least 10 characters.")
                .max(11, "Phone number cannot exceed 11 characters.")
                .required("Phone Number is required.")
                .test(
                  "not-start-with-space",
                  "Phone cannot start with a space.",
                  (value) => !value || !value.startsWith(" ")
                ),
              countryCode: Yup.string().required("Country Code is required."),
            })
          ),
        displayLoc: Yup.array().min(1, "At least one postal code is required."),
      })
    )
  });


  function convertToSingleAgentsArray(branches) {
    const agents = [];

    branches.forEach((branch) => {
      branch.agents.forEach((agent) => {
        // Ensure each agent keeps its associated branchId
        agents.push({
          branchId: branch.branchId,
          ...agent,
        });
      });
    });

    return agents;
  }

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      let response = await Axios.post(
        `${agency?.users?.length > 1 ? `/AccountSetup/update-manager-agent-new` : `/AccountSetup/create-manager-agent-new`
        }`,
        {
          directors: values.directors,
          team: convertToSingleAgentsArray(values.branches),
          ...(settings && { settings })
        }
      );
      if (settings) {
        Toast(`Agency Team ${agency.users.length > 1 ? 'updated successfully' : "created successfully"} `, "success");
      }
      handleAgencyData(response?.data?.data);
      if (settings !== true) {
        handleNext();
      }
      setIsLoading(false);
    } catch (error) {
      Toast(error, "error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (agency?.users) {
      const adminUsers = agency.users.filter(
        (user) => user?.userType === "companyadmin"
      );
      const directors = adminUsers.map((user) => ({
        _id: user._id || "",
        name: user.name || "",
        email: user.email || "",
        phone: user.phone || "",
        countryCode: user.countryCode || "+44",
      }));

      setInitialValues((prevValues) => {
        // Create an array of branch objects with corresponding agents
        const branchesArray = agency?.branches?.map((branch) => ({
          branchName: branch.branchName,
          branchId: branch?._id,
          typeOfAgency: branch?.typeOfAgency,
          dealsWith: branch?.dealsWith,
          agents: branch.users.length > 0 ? branch?.users?.map((user) => ({
            branchId: branch?._id,
            id: generateUniqueId(),
            _id: user._id,
            name: user?.name,
            email: user?.email,
            phone: user?.phone,
            countryCode: user?.countryCode || "+44",
            designation: user?.userType,
            departmentType: user?.departmentType?.length === 2 ? "both" : user?.departmentType && user?.departmentType[0],
            departmentRole: user?.departmentRole?.length === 2 ? "both" : user?.departmentRole && user?.departmentRole[0],
          })) : [
            {
              id: generateUniqueId(),
              name: "",
              email: "",
              phone: "",
              countryCode: "+44",
              designation: "",
              departmentType: branch?.dealsWith?.length === 2 ? "both" : branch?.dealsWith[0], // residential
              departmentRole: branch?.typeOfAgency?.length === 2 ? "both" : branch?.typeOfAgency[0], // both
            },
          ],
        }));

        return {
          ...prevValues,
          directors:
            directors.length > 0
              ? directors
              : [{
                name: userObj.name || "",
                email: userObj.email || "",
                phone: userObj.phone || "",
                countryCode: userObj.countryCode || "+44",
              }],
          branches:
            branchesArray.length > 0 ? branchesArray : prevValues.branches, // Update branches with agents
        };
      });
    }
  }, [agency?.users, agency?.branches, userObj]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, }) => (
        <Form>
          <div className="d-flex align-items-center  "
            style={{ marginTop: "3rem", maxWidth: settings ? "90%" : "80%", margin: settings ? '' : `auto` }}
          >
            <div className={`w-75 ${settings ? "" : "m-auto"}`}>
              {userObj?.role === "companyadmin" && (
                <><><div className="w-100 mb-3 d-flex align-items-center justify-content-between">
                  <h3 className={settings ? Style.question_heading_settings : Style.question_heading + " "}>
                    Add agency admin details.{" "}
                  </h3>
                  {settings ? "" : <div className={`d-flex align-items-center justify-content-end  `}>
                    <ButtonLoaderLeft
                      type={"button"}
                      onClickhandle={() => handleNext("back")}
                      // isLoading={isLoading}
                      buttonName="Back"
                      loaderColor={"white"}
                      className={Style.back_btn}
                      icon={<KeyboardArrowLeftIcon />} s
                    ></ButtonLoaderLeft>
                    <ButtonLoader
                      onClickhandle={handleSubmit}
                      isLoading={isLoading}
                      buttonName="Next"
                      loaderColor={"white"}
                      className={Style.save_btn}
                      icon={<KeyboardArrowRightIcon />}
                    ></ButtonLoader>
                  </div>}
                </div><hr color="#0000002B"></hr></><div className="mb-5">
                    <FieldArray name="directors">
                      {({ push: pushDirector, remove: removeDirector }) => (
                        <div className=" ">
                          <DirectorForm
                            directors={values.directors}
                            errors={errors}
                            touched={touched}
                            handleChange={handleChange}
                            closeError={closeError}
                            setFieldValue={setFieldValue}
                            removeDirector={removeDirector} />
                          <button
                            type="button"
                            className={Style.add_btn}
                            onClick={() => pushDirector({
                              name: "",
                              email: "",
                              phone: "",
                              countryCode: "+44",
                            })}
                          >
                            <AddIcon sx={{ color: "#2E66F5", fontSize: "20px", cursor: "pointer", }} />
                            <p>Add directors</p>
                          </button>
                        </div>
                      )}
                    </FieldArray>
                  </div></>
              )}
              <div className="w-75 mb-2 d-flex align-items-center justify-content-between">
                <h3 className={settings ? Style.question_heading_settings : Style.question_heading + " mb-2 "}>
                  Add agents or managers details.
                </h3>
              </div>
              <hr className="#0000002B"></hr>
              <div className="col-lg-12">
                <FieldArray name="branches">
                  {({ }) => values?.branches?.map((branch, branchIndex) => (
                    <div className="  mb-4 p-4 bg-white" style={{ boxShadow: " 0px 5.19px 10.38px 0px #0000000F" }}>
                      <FieldArray name={`branches.${branchIndex}.agents`}>
                        {({ push: pushAgent, remove: removeAgent }) => (
                          <AgentListNew
                            values={values}
                            pushAgent={pushAgent}
                            removeAgent={removeAgent}
                            branch={branch}
                            branchIndex={branchIndex}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            branchCount={values?.branches?.length}
                          />
                        )}
                      </FieldArray>
                    </div>
                  ))
                  }
                </FieldArray>

                <div className="d-flex align-items-center mt-5 justify-content-end mb-5">
                  {settings ? <ButtonLoader
                    onClickhandle={handleSubmit}
                    isLoading={isLoading}
                    buttonName="Save"
                    loaderColor={"white"}
                    className={Style.save_btn}
                    icon={''} ></ButtonLoader> :
                    <>
                      <ButtonLoaderLeft
                        type={"button"}
                        onClickhandle={() => handleNext("back")}
                        buttonName="Back"
                        loaderColor={"white"}
                        className={Style.back_btn}
                        icon={<KeyboardArrowLeftIcon />}
                      ></ButtonLoaderLeft>
                      <ButtonLoader
                        onClickhandle={handleSubmit}
                        isLoading={isLoading}
                        buttonName="Next"
                        loaderColor={"white"}
                        className={Style.save_btn}
                        icon={<KeyboardArrowRightIcon />}
                      ></ButtonLoader>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DirectorManagement;
