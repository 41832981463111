import React, { useEffect, useRef, useState } from "react";
import styles from "./channels.module.css";
import whatsApp from "src/assets/images/channels/Whatsapp.svg";
import website from "src/assets/images/channels/Website.svg";
import telegram from "src/assets/images/channels/Telegram.svg";
import instagram from "src/assets/images/channels/Instagram.svg";
import messanger from "src/assets/images/channels/Messanger.svg";
import ConnectJinniBotModal from "src/components/custom/modals/ConnectJinniBotModal";
import ConnecttelegramModal from "src/components/custom/modals/ConnectTelegramModal";
import DisconnectModel from "src/components/custom/modals/DisconnectModel";
import useConnectChannels from "../hooks/useConnectChannels";
import Loader from "src/layouts/loader/Loader";
import Axios from "src/utils/axios";
import LinkIcon from '@mui/icons-material/Link';
import QRCode from 'qrcode';
import waveEmoji from '../../../assets/images/channels/wave_icon.png'
import demo from '../../../assets/images/channels/demo.png'
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import NoSubscriptionModal from "src/components/custom/modals/NoSubscriptionModal";
import Toast from "src/components/custom/Toast";
import { svgPath } from "src/constants/chatbotConstants";
import socialIcon from "src/assets/images/channels/social.svg";
import qrIcon from "src/assets/images/channels/qr_icon.svg";
import qrImg from "src/assets/images/channels/qr_image.png";
import trash from "src/assets/images/modal/trash.svg";
import { CircularProgress, Modal } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box } from "@mui/system";
import QrCodeGenerator from "src/components/QrCodeGenerator"
const logoUrl = 'https://jinni-bot-storage.s3.amazonaws.com/leads/black-logo2_1723205484990.jpg';

const Channels = ({ isAccountSetup, chatbotId, chatBotData, setchatBotData }) => {

  const { showConnectModal, setShowConnectModal, showtelegrammodal, setshowtelegrammodal, showWhatsappmmodal, setshowWhatsappmodal, showFacebookmodal, setshowFacebookmodal,
    showInstagrammodal, setshowInstagrammodal, disconnectLoading, validIntegration, userdata, setUserdata, checkIntegration, launchInstagramSignup,
    LoginWithFacebook, launchWhatsAppSignup, disConnectFacebook, disConnectInstagram, disConnectWhatsapp, isLoading, connectChatbotChannels, ischatbotLoading, confirmExistingConnection, existConnection, existLoading, getAllConnectedQrConnection, allQrConnection } = useConnectChannels();

  const [open, setOpen] = useState(false);
  const [instOpen, setInstOpen] = useState(false);
  const [pageId, setPageId] = useState(null);
  const [FbOpen, setFbOpen] = useState(false);
  const [instLoading, setInstaLoading] = useState(false);
  const [isSubscried, setIsSubscribed] = useState(true);
  const [openBots, setOpenBots] = useState(false);
  const [openWebsite, setopenWebsite] = useState(false);
  const [openInsta, setopenInsta] = useState(false);
  const [openFacebook, setopenFacebook] = useState(false);
  const [openTelegram, setopenTelegram] = useState(false);
  const [openOfficeFront, setopenOfficeFront] = useState(false)
  const [openSalesBoard, setOpenSalesBoard] = useState(false)
  const [openLettingBoard, setOpenLettingBoard] = useState(false)
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [disconnect, setDisconnect] = useState({ open: false, botName: null });
  const canvasRef = useRef(null);
  const canvasRef1 = useRef(null);
  const canvasRef2 = useRef(null);

  const size = 100;

  // sub component
  const Pagelist = ({ type }) => {

    const ConnectPage = (payload) => {
      setPageId(payload.page_id);
      Axios.post(`/${type === "instagram" ? "generateToken" : type}/${type}-page-subscribed`, payload)
        .then((res) => {
          setPageId(null);

          Axios.get("/user/getUserDetail")
            .then((res) => {
              setUserdata({
                integrationKeys: res.data.data.integrationKeys,
                subscription: res.data.data.subscription[0],
              });
              checkIntegration(res.data.data);
            })
            .catch((err) => { Toast(err, 'error'); });
        })
        .catch((error) => {
          Toast(error, 'error');
        });
    };

    const DisconnectPage = (payload) => {
      setPageId(payload.page_id);
      Axios.post(`/${type === "instagram" ? "generateToken" : type}/${type}-page-unsubscribed`, payload)
        .then((res) => {
          setPageId(null);

          Axios.get("/user/getUserDetail")
            .then((res) => {
              setUserdata({
                integrationKeys: res.data.data.integrationKeys,
                subscription: res.data.data.subscription[0],
              });
              checkIntegration(res.data.data);
            })
            .catch((err) => { Toast(err, 'error') });
        })
        .catch((error) => {
          Toast(error, 'error');
        });
    };


    return (
      <div className={styles.connect_modal_wrapper + " mx-0 px-0 bg-white"}   >
        <div className={styles.modal_header}>
          <div className={styles.modal_left}>
            <h2>Connected Pages</h2>
          </div>
          <div className={styles.modal_right} onClick={() => {
            if (open) {
              setOpen(!open)
            } else {
              setInstOpen(!instagram)
            }
          }}>
            <CloseIcon />
          </div>
        </div>

        <div className={styles.drawer_body}>
          <div className={styles.records_wrapper}>
            <h5>Hello <img src={waveEmoji} /></h5>
            <p className="mb-4 ">
              {type === "facebook" && `We found ${userdata?.integrationKeys?.facebook?.pages?.length} Facebook  Page managed by you`}

              {type === "instagram" && `We found ${userdata?.integrationKeys?.instagram?.pages?.filter((pageItem) => pageItem.instagramId !== null).length} Facebook  Page managed by you`}
            </p>
          </div>
          {userdata?.integrationKeys?.[type]?.isActive &&
            userdata?.integrationKeys?.[type]?.pages?.map((pageItem) => {

              if (type === "instagram" && pageItem.instagramId !== null) {
                return <div className={styles.connected_wrapper + " d-flex align-items-center justify-content-between mb-3"}>
                  <div className="d-flex align-items-center  bg-white rounded  ">
                    <img

                      src={pageItem.picture || demo}
                      className="rounded-circle shadow-4-strong"
                      width={"40px"}
                      alt="facebook"
                    />
                    <p className="p-0 m-0  text-xs">{pageItem.name}</p>
                  </div>
                  <div>
                    <div

                      onClick={() => {
                        if (pageItem.connected) {
                          DisconnectPage({ page_id: pageItem.page_id })
                        }
                        else {
                          ConnectPage({ page_id: pageItem.page_id })
                        }
                      }}
                    >
                      <button className={pageItem.connected ? styles.connected_btn : styles.connect_btn}>
                        {pageItem.connected ? "Connected" : "Connect"}
                        {pageId === pageItem?.page_id && (
                          <img
                            src="/images/Reload.gif"
                            alt="loading"
                            style={{ width: "20px", height: "20px" }}
                            className="rounded-pill"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              }

              if (type === "facebook") {
                return <div className={styles.connected_wrapper + " d-flex align-items-center justify-content-between mb-3"}>
                  <div className="d-flex align-items-center  bg-white rounded  ">
                    <img

                      src={pageItem.picture || demo}
                      className="rounded-circle shadow-4-strong"
                      width={"40px"}
                      alt="facebook"
                    />
                    <p className="p-0 m-0  text-xs">{pageItem.name}</p>
                  </div>
                  <div>
                    <div
                      onClick={() => {
                        if (pageItem.connected) {
                          DisconnectPage({ page_id: pageItem.page_id })
                        }
                        else {
                          ConnectPage({ page_id: pageItem.page_id })
                        }
                      }}
                    >
                      <button className={pageItem.connected ? styles.connected_btn : styles.connect_btn}>
                        {pageItem.connected ? "Connected" : "Connect"}
                        {pageId === pageItem?.page_id && (
                          <img
                            src="/images/Reload.gif"
                            alt="loading"
                            style={{ width: "20px", height: "20px" }}
                            className="rounded-pill"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              }
            })}
        </div>
      </div>
    );
  };
  // sub component

  useEffect(() => {
    getAllConnectedQrConnection();
  }, []);

  function getNamesByChannels(channel) {
    return allQrConnection?.filter(item => item.channels.includes(channel)).map(item => item.name)[0]

  }

  useEffect(() => {
    if (userdata?.botName) {
      const URL = `https://propertyjinni.co.uk/${userdata?.botName}/${userdata?.name}`;
      QRCode.toDataURL(URL, { width: size })
        .then(dataUrl => setQrCodeUrl(dataUrl))
        .catch(err => console.error('Error generating QR code URL:', err));
    }
  }, [userdata?.botName]);

  console.log(qrCodeUrl)

  // Draw QR code and logo on the canvas
  useEffect(() => {

    const drawQRCodeWithLogo = async (canvasRef) => {
      if (!qrCodeUrl) return;

      const canvas = canvasRef.current;
      if (!canvas) return;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const qrImage = new Image();
      qrImage.crossOrigin = 'anonymous';
      qrImage.src = qrCodeUrl;

      const logoImage = new Image();
      logoImage.crossOrigin = 'anonymous';
      logoImage.src = logoUrl;

      try {
        await Promise.all([
          new Promise((resolve, reject) => {
            qrImage.onload = resolve;
            qrImage.onerror = reject;
          }),
          new Promise((resolve, reject) => {
            logoImage.onload = resolve;
            logoImage.onerror = reject;
          })
        ]);

        canvas.width = size;
        canvas.height = size;

        // Draw the QR code image
        ctx.drawImage(qrImage, 0, 0, size, size);

        // Draw the logo image
        const logoSize = size / 6; // Adjust size as needed
        const logoX = (size - logoSize) / 2;
        const logoY = (size - logoSize) / 2;

        ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);

      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    drawQRCodeWithLogo(canvasRef);
    drawQRCodeWithLogo(canvasRef1);
    drawQRCodeWithLogo(canvasRef2);
  }, [qrCodeUrl, logoUrl]);

  const styleText = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 260,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 1,
    overflow: "hidden",
  };



  return (
    <section>
      <div className={isAccountSetup ? "" : "container"}>
        <div className="row">
          <div className="col-12 pt-4 pb-2">
            <div className={styles.integration_h1}>
              Seamless connection with all your favorite channels
            </div>
            <p className={styles.integration_p}>
              Close more deals with PropertyJinni. Connect all your social media channels to manage leads and conversations in one place.         </p>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="row">
              {open && <Pagelist type="facebook" />}

              <div className="col-12 mb-3">
                <div className={styles.sub_head}><img src={socialIcon} className="img-fluid me-1" />Connect social channels </div>
              </div>

              <div className="col-md-4 px-2">
                <div className={styles.integration_card + " card"} style={{ height: '170px' }}>
                  <div className={styles.card_header + " card-header"}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className={styles.integration_logo}>
                        <img src={website} alt="Website" className="img-fluid" />
                      </div>
                      <button onClick={async () => {
                        if (userdata?._id && (!userdata?.subscription?.active || moment(userdata?.subscription?.endDate) < moment())) {
                          setIsSubscribed(false);
                        }
                        else if (chatbotId && userdata?.integrationKeys.chatbot.isActive) {

                          let updatedChannels = [];

                          if (chatBotData?.channels?.includes("chatBot")) {
                            updatedChannels = chatBotData?.channels?.filter(channel => channel !== "chatBot");
                            connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "chatBot");
                          } else {
                            let existing = await confirmExistingConnection("chatBot", setopenWebsite);
                            if (existing) {
                              setopenWebsite(true);
                            }
                            else {
                              updatedChannels = [...chatBotData?.channels, "chatBot"];
                              connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "chatBot");
                            }
                          }
                        }

                        else {
                          setShowConnectModal(true);
                        }
                      }}
                        className={(chatbotId ? userdata?.integrationKeys.chatbot.isActive && (chatbotId && chatBotData?.channels?.includes("chatBot")) : userdata?.integrationKeys.chatbot.isActive) ? styles.integration_btn_green : styles.integration_btn}
                        disabled={!validIntegration?.chatbot}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"   >
                          <path d={svgPath} />
                        </svg>
                        {(chatbotId ? userdata?.integrationKeys.chatbot.isActive && (chatBotData?.channels?.includes("chatBot")) : userdata?.integrationKeys.chatbot.isActive) ? "Connected" : "Connect"}
                        {ischatbotLoading === "chatBot" && (
                          <CircularProgress
                            style={{
                              color: chatBotData?.channels?.includes("chatBot") ? "white" : '#3874ff',
                              width: '16px',
                              height: '16px',
                              position: 'relative',
                              top: '3px',
                              marginLeft: '3px',
                            }}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className={styles.card_body}>
                    <h2>Website</h2>
                    <p>
                      Manage all website conversations in the PropertyJinni CRM.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 px-2">
                <div className={styles.integration_card + " card"}>
                  <div className={styles.card_header + " card-header"}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className={styles.integration_logo}>
                        <img src={whatsApp} alt="WhatsApp" className="img-fluid" />
                      </div>
                      <button
                        className={(chatbotId ? userdata?.integrationKeys.whatsapp.isActive && (chatBotData?.channels?.includes("whatsapp")) : userdata?.integrationKeys.whatsapp.isActive) ? styles.integration_btn_green : styles.integration_btn}

                        onClick={async () => {
                          if (userdata?._id && (!userdata?.subscription?.active || moment(userdata?.subscription?.endDate) < moment())) {
                            setIsSubscribed(false);
                          }
                          else if (chatbotId && userdata?.integrationKeys.whatsapp.isActive) {
                            let updatedChannels = [];

                            if (chatBotData?.channels?.includes("whatsapp")) {
                              updatedChannels = chatBotData?.channels?.filter(channel => channel !== "whatsapp");
                              connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "whatsapp");
                            } else {
                              let existing = await confirmExistingConnection("whatsapp", setOpenBots);
                              if (existing) {
                                setOpenBots(true);
                              } else {
                                updatedChannels = [...chatBotData?.channels, "whatsapp"];
                                connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "whatsapp");
                              }
                            }
                          }

                          else {

                            if (userdata?.integrationKeys.whatsapp.isActive) {
                              setshowWhatsappmodal(true);
                            } else {
                              launchWhatsAppSignup(chatbotId, chatBotData, connectChatbotChannels, setchatBotData, "whatsapp");
                            }
                          }
                        }}
                        disabled={!validIntegration?.whatsapp}
                      >


                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"     >
                          <path d={svgPath} />
                        </svg>
                        {(chatbotId ? userdata?.integrationKeys.whatsapp.isActive && (chatBotData?.channels?.includes("whatsapp")) : userdata?.integrationKeys.whatsapp.isActive) ? "Connected" : "Connect"}

                        {ischatbotLoading === "whatsapp" && (
                          <CircularProgress
                            style={{
                              color: chatBotData?.channels?.includes("whatsapp") ? "white" : '#3874ff',
                              width: '16px',
                              height: '16px',
                              position: 'relative',
                              top: '3px',
                              marginLeft: '3px',
                            }}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className={styles.card_body}>
                    <h2>WhatsApp</h2>
                    <p>Manage your chats on WhatsApp within the PropertyJinni CRM. </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 px-2">
                <div className={styles.integration_card + " card"}>
                  <div className={styles.card_header + " card-header"}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className={styles.integration_logo}>
                        <img src={messanger} alt="Messenger" className="img-fluid" />
                      </div>
                      <button
                        className={(chatbotId ? userdata?.integrationKeys.facebook.isActive && (chatBotData?.channels?.includes("facebook")) : userdata?.integrationKeys.facebook.isActive) ? styles.integration_btn_green : styles.integration_btn}
                        onClick={async () => {
                          if (userdata?._id && (!userdata?.subscription?.active || moment(userdata?.subscription?.endDate) < moment())) {
                            setIsSubscribed(false);
                          }
                          else if (chatbotId && userdata?.integrationKeys.facebook.isActive) {
                            let updatedChannels = [];


                            if (chatBotData?.channels?.includes("facebook")) {
                              updatedChannels = chatBotData?.channels?.filter(channel => channel !== "facebook");;
                            } else {
                              let existing = await confirmExistingConnection("facebook", setopenFacebook);
                              if (existing) {
                                setopenFacebook(true)
                              }
                              updatedChannels = [...chatBotData?.channels, "facebook"];
                            }
                            connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "facebook");
                          }
                          else {

                            if (userdata?.integrationKeys.facebook.isActive) {
                              setFbOpen(false);
                              setshowFacebookmodal(true);
                            } else {
                              setFbOpen(true);
                              LoginWithFacebook(chatbotId, chatBotData, connectChatbotChannels, setchatBotData, "facebook");
                            }
                          }

                        }}
                        disabled={!validIntegration?.facebook}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" >
                          <path d={svgPath} />
                        </svg>

                        {(chatbotId ? userdata?.integrationKeys.facebook.isActive && (chatBotData?.channels?.includes("facebook")) : userdata?.integrationKeys.facebook.isActive) ? "Connected" : "Connect"}

                        {FbOpen && userdata?.integrationKeys.facebook.isActive === false && (<img src="/images/Reload.gif" alt="loading" style={{ width: "20px", height: "20px" }} className="rounded-pill" />)}
                        {ischatbotLoading === "facebook" && (
                          <CircularProgress
                            style={{
                              color: chatBotData?.channels?.includes("facebook") ? "white" : '#3874ff',
                              width: '16px',
                              height: '16px',
                              position: 'relative',
                              top: '3px',
                              marginLeft: '3px',
                            }}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className={styles.card_body}>
                    <h2>Facebook Messenger</h2>
                    <p>
                      Utilise PropertyJinni CRM to manage your Facebook Messenger
                      conversations.

                      {userdata?.integrationKeys?.facebook?.isActive && (
                        <a onClick={() => { setInstOpen(false); setOpen(!open) }} className={styles.linked_pages + " d-block"}  >
                          <LinkIcon />View Linked Page
                        </a>
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-4 px-2">
                {instOpen && <Pagelist type="instagram" />}
                <div className={styles.integration_card + " card"}>
                  <div className={styles.card_header + " card-header"}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className={styles.integration_logo}>
                        <img src={instagram} alt="Instagram" className="img-fluid" />
                      </div>
                      <button
                        className={(chatbotId ? userdata?.integrationKeys.instagram.isActive && (chatBotData?.channels?.includes("instagram")) : userdata?.integrationKeys.instagram.isActive) ? styles.integration_btn_green : styles.integration_btn}
                        onClick={async () => {

                          if (userdata?._id && (!userdata?.subscription?.active || moment(userdata?.subscription?.endDate) < moment())
                          ) { setIsSubscribed(false); }
                          else if (chatbotId && userdata?.integrationKeys.instagram.isActive) {
                            let updatedChannels = [];


                            // if (chatBotData?.channels?.includes("whatsapp")) {
                            //   updatedChannels = chatBotData?.channels?.filter(channel => channel !== "whatsapp");
                            //   connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "whatsapp");
                            // } else {
                            //   let existing = await confirmExistingConnection("whatsapp", setOpenBots);
                            //   if (existing) {
                            //     setOpenBots(true);
                            //   } else {
                            //     updatedChannels = [...chatBotData?.channels, "whatsapp"];
                            //     connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "whatsapp");
                            //   }
                            // }
                            if (chatBotData?.channels?.includes("instagram")) {
                              updatedChannels = chatBotData?.channels?.filter(channel => channel !== "instagram");;
                            } else {
                              let existing = await confirmExistingConnection("instagram", setInstOpen);
                              if (existing) {
                                setInstOpen(true)
                              }
                              updatedChannels = [...chatBotData?.channels, "instagram"];
                            }
                            connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "instagram");
                          } else {
                            if (userdata?.integrationKeys.instagram.isActive) {
                              setInstaLoading(false)
                              setshowInstagrammodal(true);
                            } else {
                              setInstaLoading(true)
                              launchInstagramSignup(chatbotId, chatBotData, connectChatbotChannels, setchatBotData, "instagram");
                            }
                          }

                        }}
                        disabled={!validIntegration?.instagram}
                      >

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"
                        >
                          <path d={svgPath} />
                        </svg>
                        {(chatbotId ? userdata?.integrationKeys.instagram.isActive && (chatBotData?.channels?.includes("instagram")) : userdata?.integrationKeys.instagram.isActive) ? "Connected" : "Connect"}
                        {instLoading &&
                          userdata?.integrationKeys.instagram.isActive ===
                          false && (<img src="/images/Reload.gif" alt="loading" style={{ width: "20px", height: "20px" }} className="rounded-pill" />)}
                        {ischatbotLoading === "instagram" && (
                          <CircularProgress
                            style={{
                              color: chatBotData?.channels?.includes("instagram") ? "white" : '#3874ff',
                              width: '16px',
                              height: '16px',
                              position: 'relative',
                              top: '3px',
                              marginLeft: '3px',
                            }}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className={styles.card_body}>
                    <h2>Instagram</h2>
                    <p>
                      Manage your Instagram conversations using the PropertyJinni    CRM.
                    </p>
                    {userdata?.integrationKeys?.instagram?.isActive && (
                      <a onClick={() => { setOpen(false); setInstOpen(!instOpen) }} className={styles.linked_pages + " d-block"} >
                        <LinkIcon />View Linked Page
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-4 px-2">
                <div className={styles.integration_card + " card"}>
                  <div className={styles.card_header + " card-header"}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className={styles.integration_logo}>
                        <img src={telegram} alt="Telegram" className="img-fluid" />
                      </div>
                      <button
                        className={(chatbotId ? userdata?.integrationKeys.telegram.isActive && (chatBotData?.channels?.includes("telegram")) : userdata?.integrationKeys.telegram.isActive) ? styles.integration_btn_green : styles.integration_btn}
                        onClick={async () => {


                          if (userdata?._id && (!userdata?.subscription?.active || moment(userdata?.subscription?.endDate) < moment())
                          ) {
                            setIsSubscribed(false);
                          }
                          else if (chatbotId && userdata?.integrationKeys.telegram.isActive) {
                            let updatedChannels = [];
                            if (chatBotData?.channels?.includes("telegram")) {
                              updatedChannels = chatBotData?.channels?.filter(channel => channel !== "telegram");;
                            } else {
                              let existing = await confirmExistingConnection("telegram", setopenTelegram);
                              if (existing) {
                                setopenTelegram(true)
                              }
                              updatedChannels = [...chatBotData?.channels, "telegram"];
                            }
                            connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "telegram");
                          } else {
                            setshowtelegrammodal(true);
                          }

                          //   if (chatBotData?.channels?.includes("telegram")) {
                          //     updatedChannels = chatBotData?.channels?.filter(channel => channel !== "telegram");;
                          //   } else {
                          //     updatedChannels = [...chatBotData?.channels, "telegram"];
                          //   }
                          //   connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "telegram");
                          // } else {
                          //   setshowtelegrammodal(true);
                          // }
                        }}
                        disabled={!validIntegration?.telegram}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"   >
                          <path d={svgPath} />
                        </svg>
                        {(chatbotId ? userdata?.integrationKeys.telegram.isActive && (chatBotData?.channels?.includes("telegram")) : userdata?.integrationKeys.telegram.isActive) ? "Connected" : "Connect"}
                        {ischatbotLoading === "telegram" && (
                          <CircularProgress
                            style={{
                              color: chatBotData?.channels?.includes("telegram") ? "white" : '#3874ff',
                              width: '16px',
                              height: '16px',
                              position: 'relative',
                              top: '3px',
                              marginLeft: '3px',
                            }}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className={styles.card_body}>
                    <h2>Telegram</h2>
                    <p>
                      Manage all of your Telegram conversations within the   PropertyJinni CRM.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {chatbotId && <div className="row mt-3 pb-4">
              <div className="col-12 mb-3">
                <div className={styles.sub_head}><img src={qrIcon} className="img-fluid me-1" />Connect QR code </div>
              </div>


              <div className="col-md-4 px-2">
                <div className={styles.qr_card}>
                  <div className={styles.qr_left}>
                    <QrCodeGenerator url={`https://propertyjinni.co.uk/agentsdev/${userdata?.botName}`} size={100} downloadSize={400} qrDonloadName={userdata?.name} />
                  </div>
                  <div className={styles.qr_content}>
                    <div class={styles.card_body}>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div><h2 className="mb-0">QR for Office Front</h2></div>

                        <button onClick={async () => {
                          let updatedChannels = [];
                          if (chatBotData?.channels?.includes("shopfront")) {
                            setDisconnect({ open: true, botName: "shopfront" });
                          } else {
                            let existing = await confirmExistingConnection("shopfront", setopenOfficeFront);
                            if (existing) {
                              setopenOfficeFront(true);
                            }
                            else {
                              updatedChannels = [...chatBotData?.channels, "shopfront"];
                              connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "shopfront");
                            }
                          }
                        }}
                          className={chatBotData?.channels?.includes("shopfront") ? styles.integration_btn_green : styles.integration_btn}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"   >
                            <path d={svgPath} /></svg>
                          {chatBotData?.channels?.includes("shopfront") ? "Connected" : "Connect"}
                          {ischatbotLoading === "shopfront" && (
                            <CircularProgress
                              style={{
                                color: chatBotData?.channels?.includes("shopfront") ? "white" : '#3874ff',
                                width: '16px',
                                height: '16px',
                                position: 'relative',
                                top: '3px',
                                marginLeft: '3px',
                              }}
                            />
                          )}
                        </button>
                      </div>
                      <p>Add a QR code to your Office front to connect customers to your chatbot for instant support.</p>
                      {getNamesByChannels("shopfront") && <span className={styles.connected_chatbot}><strong>Connected with : </strong>{getNamesByChannels("shopfront")}</span>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 px-2">
                <div className={styles.qr_card}>
                  <div className={styles.qr_left}>
                    <QrCodeGenerator url={`https://propertyjinni.co.uk/agentsdev/${userdata?.botName}`} size={100} downloadSize={400} qrDonloadName={userdata?.name} />
                  </div>
                  <div className={styles.qr_content}>
                    <div class={styles.card_body}>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div><h2 className="mb-0">QR for Sales Board</h2></div>

                        <button
                          onClick={async () => {

                            let updatedChannels = [];
                            if (chatBotData?.channels?.includes("sales-board")) {
                              setDisconnect({ open: true, botName: "sales-board" });
                            }
                            else {
                              let existing = await confirmExistingConnection("sales-board", setOpenSalesBoard);
                              if (existing) {
                                setOpenSalesBoard(true)
                              }
                              else {
                                updatedChannels = [...chatBotData?.channels, "sales-board"];
                                connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "sales-board");

                              }
                            }
                          }}
                          className={chatBotData?.channels?.includes("sales-board") ? styles.integration_btn_green : styles.integration_btn}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"   >
                            <path d={svgPath} /></svg>
                          {chatBotData?.channels?.includes("sales-board") ? "Connected" : "Connect"}
                          {ischatbotLoading === "sales-board" && (
                            <CircularProgress
                              style={{
                                color: chatBotData?.channels?.includes("sales-board") ? "white" : '#3874ff',
                                width: '16px',
                                height: '16px',
                                position: 'relative',
                                top: '3px',
                                marginLeft: '3px',
                              }}
                            />
                          )}
                        </button>

                      </div>

                      <p>Add a QR code to The property front to connect customers to your chatbot for instant support.</p>
                      {getNamesByChannels("sales-board") && <span className={styles.connected_chatbot}><strong>Connected with : </strong>{getNamesByChannels("sales-board")}</span>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 px-2">
                <div className={styles.qr_card}>
                  <div className={styles.qr_left}>
                    <QrCodeGenerator url={`https://propertyjinni.co.uk/agentsdev/${userdata?.botName}`} size={100} downloadSize={400} qrDonloadName={userdata?.name} />
                  </div>
                  <div className={styles.qr_content}>
                    <div class={styles.card_body}>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div><h2 className="mb-0">QR for Lettings Board</h2></div>


                        <button onClick={async () => {
                          let updatedChannels = [];
                          if (chatBotData?.channels?.includes("lettings-board")) {
                            setDisconnect({ open: true, botName: "lettings-board" });
                          }
                          else {
                            let existing = await confirmExistingConnection("lettings-board", setOpenLettingBoard);
                            if (existing) {
                              setOpenLettingBoard(true)
                            }
                            else {
                              updatedChannels = [...chatBotData?.channels, "lettings-board"];
                              connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "lettings-board");
                            }
                          }
                        }}
                          className={chatBotData?.channels?.includes("lettings-board") ? styles.integration_btn_green : styles.integration_btn}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"   >
                            <path d={svgPath} /></svg>
                          {chatBotData?.channels?.includes("lettings-board") ? "Connected" : "Connect"}
                          {ischatbotLoading === "lettings-board" && (
                            <CircularProgress
                              style={{
                                color: chatBotData?.channels?.includes("lettings-board") ? "white" : '#3874ff',
                                width: '16px',
                                height: '16px',
                                position: 'relative',
                                top: '3px',
                                marginLeft: '3px',
                              }}
                            />
                          )}
                        </button>

                      </div>

                      <p>Add a QR code to The property front to connect customers to your chatbot for instant support.</p>
                      {getNamesByChannels("lettings-board") && <span className={styles.connected_chatbot}><strong>Connected with : </strong>{getNamesByChannels("lettings-board")}</span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          </>
        )}
      </div>

      {
        openBots && <Modal
          open={openBots}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleText} className={styles.modal_wrap}>
            <div className="row mx-0 px-0">
              <div className="col-12 mx-0 px-0">
                <div className={styles.modal_header1 + " pt-4"}>
                  <h5>WhatsApp Already Connected</h5>
                  <p className="mt-0">A chatbot is currently connected to your WhatsApp account. Would you like to disconnect the existing one and link the new chatbot instead?</p>
                  <div className={styles.modal_cancel}
                    onClick={() => {
                      setOpenBots(false); // Close the modal
                    }}
                  >
                    <CloseOutlinedIcon />
                  </div>
                </div>
                <div className={styles.modal_content}>
                  <div className={styles.connect_wrapper + " d-flex"}>

                    <div className="col-md-12 px-2">
                      <div className={styles.integration_card + " "}>
                        <div className={styles.card_header + " card-header"}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className={styles.integration_logo}>
                              <div className="d-flex align-items-center">
                                <img src={whatsApp} alt="WhatsApp" className="img-fluid" />
                                <div className={styles.card_body + " ms-2 mt-0 mb-0"}> <h2 style={{ marginTop: '5px' }}>WhatsApp</h2></div>
                              </div>

                            </div>
                            <div className="d-flex gap-2">
                              <button
                                style={{ border: "1px solid red" }}
                                className={(chatbotId ? userdata?.integrationKeys.whatsapp.isActive && (chatBotData?.channels?.includes("whatsapp")) : userdata?.integrationKeys.whatsapp.isActive) ? styles.integration_btn_green : styles.integration_btn}

                                onClick={() => {

                                  let updatedChannels = [];
                                  if (chatBotData?.channels?.includes("whatsapp")) {
                                    updatedChannels = chatBotData?.channels?.filter(channel => channel !== "whatsapp");;
                                  } else {
                                    updatedChannels = [...chatBotData?.channels, "whatsapp"];
                                  }

                                  if (userdata?.integrationKeys.whatsapp.isActive) {
                                    connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "whatsapp");
                                  } else {
                                    launchWhatsAppSignup(chatbotId, chatBotData, connectChatbotChannels, setchatBotData, "whatsapp");
                                  }
                                  setOpenBots(false);
                                }

                                }
                                disabled={!validIntegration?.whatsapp}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"     >
                                  <path d={svgPath} />
                                </svg>
                                {(chatbotId ? userdata?.integrationKeys.whatsapp.isActive && (chatBotData?.channels?.includes("whatsapp")) : userdata?.integrationKeys.whatsapp.isActive) ? "Connected" : "Connect"}

                                {ischatbotLoading === "whatsapp" && (
                                  <CircularProgress
                                    styles={{
                                      color: chatBotData?.channels?.includes("whatsapp") ? "white" : '#3874ff',
                                      width: '16px',
                                      height: '16px',
                                      position: 'relative',
                                      top: '3px',
                                      marginLeft: '3px',
                                    }}
                                  />
                                )}
                              </button>
                              <button className={styles.integration_Cancel} onClick={() => {
                                setOpenBots(false); // Close the modal
                              }} >cancel</button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      }


      {
        openWebsite && <Modal
          open={openWebsite}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleText} className={styles.modal_wrap}>
            <div className="row mx-0 px-0">
              <div className="col-12 mx-0 px-0">
                <div className={styles.modal_header1 + " pt-4"}>
                  <h5>Web Already Connected</h5>
                  <p className="mt-0">A chatbot is currently connected to your website. Would you like to disconnect the existing one and link the new chatbot instead?</p>
                  <div className={styles.modal_cancel}
                    onClick={() => {
                      setopenWebsite(false); // Close the modal
                    }}
                  >
                    <CloseOutlinedIcon />
                  </div>
                </div>
                <div className={styles.modal_content}>
                  <div className={styles.connect_wrapper + " d-flex"}>

                    <div className="col-md-12 px-2">
                      <div className={styles.integration_card + " "}>
                        <div className={styles.card_header + " card-header"}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className={styles.integration_logo}>
                              <div className="d-flex align-items-center">
                                <img src={website} alt="WhatsApp" className="img-fluid" />
                                <div className={styles.card_body + " ms-2 mt-0 mb-0"}> <h2 style={{ marginTop: '5px' }}>Chatbot</h2></div>
                              </div>

                            </div>
                            <div className="d-flex gap-2">

                              <button

                                className={(chatbotId ? userdata?.integrationKeys.chatbot.isActive && (chatbotId && chatBotData?.channels?.includes("chatBot")) : userdata?.integrationKeys.chatbot.isActive) ? styles.integration_btn_green : styles.integration_btn}

                                onClick={() => {
                                  let updatedChannels = [];
                                  if (chatBotData?.channels?.includes("chatBot")) {
                                    updatedChannels = chatBotData?.channels?.filter(channel => channel !== "chatBot");;
                                  } else {
                                    updatedChannels = [...chatBotData?.channels, "chatBot"];
                                  }

                                  if (userdata?.integrationKeys.chatbot.isActive) {
                                    connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "chatBot");
                                  } else {
                                    setShowConnectModal(true);
                                  }
                                  setopenWebsite(false)
                                }

                                }
                                disabled={!validIntegration?.chatbot}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"   >
                                  <path d={svgPath} />
                                </svg>
                                {(chatbotId ? userdata?.integrationKeys.chatbot.isActive && (chatBotData?.channels?.includes("chatBot")) : userdata?.integrationKeys.chatbot.isActive) ? "Connected" : "Connect"}


                                {ischatbotLoading === "chatBot" && (
                                  <CircularProgress
                                    styles={{
                                      color: chatBotData?.channels?.includes("chatBot") ? "white" : '#3874ff',
                                      width: '16px',
                                      height: '16px',
                                      position: 'relative',
                                      top: '3px',
                                      marginLeft: '3px',
                                    }}
                                  />
                                )}
                              </button>
                              <button className={styles.integration_Cancel} onClick={() => setopenWebsite(false)}>Cancel</button>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      }

      {
        openInsta && <Modal
          open={openInsta}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleText} className={styles.modal_wrap}>
            <div className="row mx-0 px-0">
              <div className="col-12 mx-0 px-0">
                <div className={styles.modal_header1 + " pt-4"}>
                  <h5>Instagram Already Connected</h5>
                  <p className="mt-0">A chatbot is currently connected to your Instagram account. Would you like to disconnect the existing one and link the new chatbot instead?</p>
                  <div className={styles.modal_cancel}
                    onClick={() => {
                      setopenInsta(false); // Close the modal
                    }}
                  >
                    <CloseOutlinedIcon />
                  </div>
                </div>
                <div className={styles.modal_content}>
                  <div className={styles.connect_wrapper + " d-flex"}>

                    <div className="col-md-12 px-2">
                      <div className={styles.integration_card + " "}>
                        <div className={styles.card_header + " card-header"}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className={styles.integration_logo}>
                              <div className="d-flex align-items-center">
                                <img src={instagram} alt="WhatsApp" className="img-fluid" />
                                <div className={styles.card_body + " ms-2 mt-0 mb-0"}> <h2 style={{ marginTop: '5px' }}>Instagram</h2></div>
                              </div>

                            </div>
                            <div className="d-flex gap-2">

                              <button
                                className={(chatbotId ? userdata?.integrationKeys.instagram.isActive && (chatBotData?.channels?.includes("whatsapp")) : userdata?.integrationKeys.instagram.isActive) ? styles.integration_btn_green : styles.integration_btn}

                                onClick={() => {

                                  let updatedChannels = [];
                                  if (chatBotData?.channels?.includes("instagram")) {
                                    updatedChannels = chatBotData?.channels?.filter(channel => channel !== "instagram");;
                                  } else {
                                    updatedChannels = [...chatBotData?.channels, "instagram"];
                                  }

                                  if (userdata?.integrationKeys.instagram.isActive) {
                                    connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "instagram");
                                  } else {
                                    setInstaLoading(true)
                                    launchInstagramSignup(chatbotId, chatBotData, connectChatbotChannels, setchatBotData, "instagram");
                                  }
                                  setopenInsta(false);
                                }

                                }
                                disabled={!validIntegration?.instagram}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"     >
                                  <path d={svgPath} />
                                </svg>
                                {(chatbotId ? userdata?.integrationKeys.instagram.isActive && (chatBotData?.channels?.includes("instagram")) : userdata?.integrationKeys.instagram.isActive) ? "Connected" : "Connect"}

                                {ischatbotLoading === "instagram" && (
                                  <CircularProgress
                                    styles={{
                                      color: chatBotData?.channels?.includes("whatsapp") ? "white" : '#3874ff',
                                      width: '16px',
                                      height: '16px',
                                      position: 'relative',
                                      top: '3px',
                                      marginLeft: '3px',
                                    }}
                                  />
                                )}
                              </button>
                              <button className={styles.integration_Cancel} onClick={() => {
                                setopenInsta(false); // Close the modal
                              }}>Cancel</button>

                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      }


      {
        openFacebook && <Modal
          open={openFacebook}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleText} className={styles.modal_wrap}>
            <div className="row mx-0 px-0">
              <div className="col-12 mx-0 px-0">
                <div className={styles.modal_header1 + " pt-4"}>
                  <h5>Facebook Connected</h5>
                  <p className="mt-0">A chatbot is currently connected to your Facebook Messenger account. Would you like to disconnect the existing one and link the new chatbot instead?</p>
                  <div className={styles.modal_cancel}
                    onClick={() => {
                      setopenFacebook(false); // Close the modal
                    }}
                  >
                    <CloseOutlinedIcon />
                  </div>
                </div>
                <div className={styles.modal_content}>
                  <div className={styles.connect_wrapper + " d-flex"}>

                    <div className="col-md-12 px-2">
                      <div className={styles.integration_card + " "}>
                        <div className={styles.card_header + " card-header"}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className={styles.integration_logo}>
                              <div className="d-flex align-items-center">
                                <img src={messanger} alt="messanger" className="img-fluid" />
                                <div className={styles.card_body + " ms-2 mt-0 mb-0"}> <h2 style={{ marginTop: '5px' }}>Facebook</h2></div>
                              </div>

                            </div>
                            <button
                              className={(chatbotId ? userdata?.integrationKeys.facebook.isActive && (chatBotData?.channels?.includes("facebook")) : userdata?.integrationKeys.facebook.isActive) ? styles.integration_btn_green : styles.integration_btn}

                              onClick={() => {

                                let updatedChannels = [];
                                if (chatBotData?.channels?.includes("facebook")) {
                                  updatedChannels = chatBotData?.channels?.filter(channel => channel !== "facebook");;
                                } else {
                                  updatedChannels = [...chatBotData?.channels, "facebook"];
                                }

                                if (userdata?.integrationKeys.facebook.isActive) {
                                  connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "facebook");
                                } else {
                                  setFbOpen(true);
                                  LoginWithFacebook(chatbotId, chatBotData, connectChatbotChannels, setchatBotData, "facebook");
                                }
                                setopenFacebook(false);
                              }

                              }
                              disabled={!validIntegration?.facebook}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"     >
                                <path d={svgPath} />
                              </svg>
                              {(chatbotId ? userdata?.integrationKeys.facebook.isActive && (chatBotData?.channels?.includes("facebook")) : userdata?.integrationKeys.facebook.isActive) ? "Connected" : "Connect"}

                              {ischatbotLoading === "facebook" && (
                                <CircularProgress
                                  styles={{
                                    color: chatBotData?.channels?.includes("facebook") ? "white" : '#3874ff',
                                    width: '16px',
                                    height: '16px',
                                    position: 'relative',
                                    top: '3px',
                                    marginLeft: '3px',
                                  }}
                                />
                              )}
                            </button>
                            <button className={styles.integration_Cancel} onClick={() => {
                              setopenFacebook(false); // Close the modal
                            }}>Cancel</button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      }

      {
        openTelegram && <Modal
          open={openTelegram}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleText} className={styles.modal_wrap}>
            <div className="row mx-0 px-0">
              <div className="col-12 mx-0 px-0">
                <div className={styles.modal_header1 + " pt-4"}>
                  <h5>Telegram Connected</h5>
                  <p className="mt-0">A chatbot is currently connected to your Telegram account. Would you like to disconnect the existing one and link the new chatbot instead?</p>
                  <div className={styles.modal_cancel}
                    onClick={() => {
                      setopenTelegram(false); // Close the modal
                    }}
                  >
                    <CloseOutlinedIcon />
                  </div>
                </div>
                <div className={styles.modal_content}>
                  <div className={styles.connect_wrapper + " d-flex"}>

                    <div className="col-md-12 px-2">
                      <div className={styles.integration_card + " "}>
                        <div className={styles.card_header + " card-header"}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className={styles.integration_logo}>
                              <div className="d-flex align-items-center">
                                <img src={telegram} alt="telegram" className="img-fluid" />
                                <div className={styles.card_body + " ms-2 mt-0 mb-0"}> <h2 style={{ marginTop: '5px' }}>Telegram</h2></div>
                              </div>

                            </div>
                            <div className={styles.connect_wrapper + " d-flex"}>

                              <button
                                className={(chatbotId ? userdata?.integrationKeys.telegram.isActive && (chatBotData?.channels?.includes("telegram")) : userdata?.integrationKeys.telegram.isActive) ? styles.integration_btn_green : styles.integration_btn}

                                onClick={() => {

                                  let updatedChannels = [];
                                  if (chatBotData?.channels?.includes("telegram")) {
                                    updatedChannels = chatBotData?.channels?.filter(channel => channel !== "telegram");;
                                  } else {
                                    updatedChannels = [...chatBotData?.channels, "telegram"];
                                  }

                                  if (userdata?.integrationKeys.telegram.isActive) {
                                    connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "telegram");
                                  } else {
                                    setshowtelegrammodal(true);
                                  }
                                  setopenTelegram(false);
                                }

                                }
                                disabled={!validIntegration?.telegram}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"     >
                                  <path d={svgPath} />
                                </svg>
                                {(chatbotId ? userdata?.integrationKeys.telegram.isActive && (chatBotData?.channels?.includes("telegram")) : userdata?.integrationKeys.telegram.isActive) ? "Connected" : "Connect"}

                                {ischatbotLoading === "telegram" && (
                                  <CircularProgress
                                    styles={{
                                      color: chatBotData?.channels?.includes("telegram") ? "white" : '#3874ff',
                                      width: '16px',
                                      height: '16px',
                                      position: 'relative',
                                      top: '3px',
                                      marginLeft: '3px',
                                    }}
                                  />
                                )}
                              </button>
                              <button className={styles.integration_Cancel} onClick={() => {
                                setopenTelegram(false); // Close the modal
                              }}>Cancel</button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      }

      {
        openOfficeFront && <Modal
          open={openOfficeFront}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleText} className={styles.modal_wrap}>
            <div className="row mx-0 px-0">
              <div className="col-12 mx-0 px-0">
                <div className={styles.modal_header1 + " pt-4"}>
                  <h5>QR Already Connected</h5>
                  <p className="mt-0">This QR Code is already linked to {getNamesByChannels("shopfront")}. Would you like to disconnect it and link a new chatbot instead?</p>
                  <div className={styles.modal_cancel}
                    onClick={() => {
                      setopenOfficeFront(false); // Close the modal
                    }}
                  >
                    <CloseOutlinedIcon />
                  </div>
                </div>
                <div className={styles.modal_content}>
                  <div className={styles.connect_wrapper + " d-flex"}>

                    <div className="col-md-12 px-2">
                      <div className={styles.integration_card + " "}>
                        <div className={styles.card_header + " card-header"}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className={styles.integration_logo}>
                              <div className={styles.card_body + " ms-2 mt-0 mb-0"}> <h2 style={{ marginTop: '5px' }}>QR for Office Front</h2></div>


                            </div>
                            <div className={styles.connect_wrapper + " d-flex gap-3"}>

                              <button
                                className={chatBotData?.channels?.includes("shopfront") ? styles.integration_btn_green : styles.integration_btn}

                                onClick={() => {

                                  let updatedChannels = [];
                                  if (chatBotData?.channels?.includes("shopfront")) {
                                    updatedChannels = chatBotData?.channels?.filter(channel => channel !== "shopfront");;
                                  } else {

                                    updatedChannels = [...chatBotData?.channels, "shopfront"];
                                  }
                                  connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "shopfront");
                                  setopenOfficeFront(false)
                                }
                                }
                                disabled={!validIntegration?.telegram}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"     >
                                  <path d={svgPath} />
                                </svg>
                                {chatBotData?.channels?.includes("shopfront") ? "Connected" : "Connect"}


                                {ischatbotLoading === "shopfront" && (
                                  <CircularProgress
                                    styles={{
                                      color: chatBotData?.channels?.includes("shopfront") ? "white" : '#3874ff',
                                      width: '16px',
                                      height: '16px',
                                      position: 'relative',
                                      top: '3px',
                                      marginLeft: '3px',
                                    }}
                                  />
                                )}
                              </button>
                              <button className={styles.integration_Cancel} onClick={() => {
                                setopenOfficeFront(false); // Close the modal
                              }}>Cancel</button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      }

      {
        openSalesBoard && <Modal
          open={openSalesBoard}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleText} className={styles.modal_wrap}>
            <div className="row mx-0 px-0">
              <div className="col-12 mx-0 px-0">
                <div className={styles.modal_header1 + " pt-4"}>
                  <h5>QR Already Connected</h5>
                  <p className="mt-0">This QR Code is already linked to {getNamesByChannels("sales-board")}. Would you like to disconnect it and link a new chatbot instead?</p>
                  <div className={styles.modal_cancel}
                    onClick={() => {
                      setOpenSalesBoard(false);
                    }}
                  >
                    <CloseOutlinedIcon />
                  </div>
                </div>
                <div className={styles.modal_content}>
                  <div className={styles.connect_wrapper + " d-flex"}>

                    <div className="col-md-12 px-2">
                      <div className={styles.integration_card + " "}>
                        <div className={styles.card_header + " card-header"}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className={styles.integration_logo}>
                              {/* <div className="d-flex align-items-center">
                              </div> */}
                              <div className={styles.card_body + " ms-2 mt-0 mb-0"}> <h2 style={{ marginTop: '5px' }}>QR for Sales Board</h2></div>


                            </div>
                            <div className={styles.connect_wrapper + " d-flex gap-3"}>

                              <button
                                className={chatBotData?.channels?.includes("sales-board") ? styles.integration_btn_green : styles.integration_btn}
                                onClick={async () => {


                                  let updatedChannels = [];
                                  if (chatBotData?.channels?.includes("sales-board")) {
                                    updatedChannels = chatBotData?.channels?.filter(channel => channel !== "sales-board");
                                  } else {

                                    updatedChannels = [...chatBotData?.channels, "sales-board"];
                                  }
                                  connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "sales-board");
                                  setOpenSalesBoard(false)

                                }
                                }
                                disabled={!validIntegration?.telegram}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"     >
                                  <path d={svgPath} />
                                </svg>
                                {chatBotData?.channels?.includes("sales-board") ? "Connected" : "Connect"}

                                {ischatbotLoading === "sales-board" && (
                                  <CircularProgress
                                    styles={{
                                      color: chatBotData?.channels?.includes("sales-board") ? "white" : '#3874ff',
                                      width: '16px',
                                      height: '16px',
                                      position: 'relative',
                                      top: '3px',
                                      marginLeft: '3px',
                                    }}
                                  />
                                )}
                              </button>
                              <button className={styles.integration_Cancel} onClick={() => {
                                setOpenSalesBoard(false);
                              }}>Cancel</button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      }


      {
        openLettingBoard && <Modal
          open={openLettingBoard}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleText} className={styles.modal_wrap}>
            <div className="row mx-0 px-0">
              <div className="col-12 mx-0 px-0">
                <div className={styles.modal_header1 + " pt-4"}>
                  <h5>QR Already Connected</h5>
                  <p className="mt-0">This QR Code is already linked to {getNamesByChannels("lettings-board")}. Would you like to disconnect it and link a new chatbot instead?</p>
                  <div className={styles.modal_cancel}
                    onClick={() => {
                      setOpenLettingBoard(false);
                    }}
                  >
                    <CloseOutlinedIcon />
                  </div>
                </div>
                <div className={styles.modal_content}>
                  <div className={styles.connect_wrapper + " d-flex"}>

                    <div className="col-md-12 px-2">
                      <div className={styles.integration_card + " "}>
                        <div className={styles.card_header + " card-header"}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className={styles.integration_logo}>
                              <div className={styles.card_body + " ms-2 mt-0 mb-0"}> <h2 style={{ marginTop: '5px' }}>QR for Lettings Board</h2></div>


                            </div>
                            <div className={styles.connect_wrapper + " d-flex gap-3"}>

                              <button
                                className={chatBotData?.channels?.includes("lettings-board") ? styles.integration_btn_green : styles.integration_btn}
                                onClick={async () => {


                                  let updatedChannels = [];



                                  if (chatBotData?.channels?.includes("lettings-board")) {
                                    updatedChannels = chatBotData?.channels?.filter(channel => channel !== "lettings-board");
                                  } else {

                                    updatedChannels = [...chatBotData?.channels, "lettings-board"];
                                  }
                                  connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, "lettings-board");
                                  setOpenLettingBoard(false)

                                }
                                }
                                disabled={!validIntegration?.telegram}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"     >
                                  <path d={svgPath} />
                                </svg>
                                {chatBotData?.channels?.includes("lettings-board") ? "Connected" : "Connect"}

                                {ischatbotLoading === "lettings-board" && (
                                  <CircularProgress
                                    styles={{
                                      color: chatBotData?.channels?.includes("lettings-board") ? "white" : '#3874ff',
                                      width: '16px',
                                      height: '16px',
                                      position: 'relative',
                                      top: '3px',
                                      marginLeft: '3px',
                                    }}
                                  />
                                )}
                              </button>
                              <button className={styles.integration_Cancel} onClick={() => {
                                setOpenLettingBoard(false);
                              }}>Cancel</button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      }

      {/* disconnect modal */}
      {
        disconnect?.open && <Modal
          open={disconnect?.open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleText} className={styles.modal_wrap}>
            <div className="row mx-0 px-0">
              <div className="  mx-0 px-0">
                <div className={styles.modal_header1 + " "}>
                  <img src={trash} width={70} />
                  <h5>Are You Sure</h5>
                  <p className="mt-0" >Would you like to disconnect chatbot ?</p>
                  <div className={styles.modal_cancel}
                    onClick={() => {
                      setDisconnect({ open: false, botName: disconnect.botName })
                    }}
                  >
                    <CloseOutlinedIcon />
                  </div>
                </div>
                <div className={styles.modal_content}>
                  <div className={styles.connect_wrapper + " d-flex"}>

                    <div className="col-md-12  ">
                      <div className={styles.integration_card + " "}>
                        <div className={styles.card_header + " card-header"}>
                          <div className="d-flex justify-content-center align-items-center">

                            <div className={" d-flex gap-5"}>

                              <button
                                className={chatBotData?.channels?.includes(disconnect.botName) ? styles.integration_btn_red : styles.integration_btn}
                                onClick={async () => {
                                  let updatedChannels = [];
                                  if (chatBotData?.channels?.includes(disconnect.botName)) {
                                    updatedChannels = chatBotData?.channels?.filter(channel => channel !== disconnect.botName);
                                    connectChatbotChannels(updatedChannels, chatbotId, setchatBotData, disconnect.botName);
                                  }
                                  setDisconnect({ open: false, botName: disconnect.botName })
                                }
                                }
                                disabled={!validIntegration?.telegram}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"     >
                                  <path d={svgPath} />
                                </svg>
                                {chatBotData?.channels?.includes(disconnect.botName) ? "Disconnect" : "Connect"}

                                {ischatbotLoading === disconnect.botName && (
                                  <CircularProgress
                                    styles={{
                                      color: chatBotData?.channels?.includes(disconnect.botName) ? "white" : '#3874ff',
                                      width: '16px',
                                      height: '16px',
                                      position: 'relative',
                                      top: '3px',
                                      marginLeft: '3px',
                                    }}
                                  />
                                )}
                              </button>
                              <button className={styles.integration_Cancel} onClick={() => {
                                setDisconnect({ open: false, botName: disconnect.botName })
                              }}>Cancel</button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      }
      {/* disconnect modal */}

      <div className="p-0">
        <ConnectJinniBotModal
          open={showConnectModal}
          closeModal={() => setShowConnectModal(false)}
          setUserdata={setUserdata}
          userdata={userdata}
          checkIntegration={checkIntegration}
          chatbotId={chatbotId}
          chatBotData={chatBotData}
          connectChatbotChannels={connectChatbotChannels}
          setchatBotData={setchatBotData}
        />
      </div>
      <div className="p-0">
        <ConnecttelegramModal
          open={showtelegrammodal}
          closeModal={() => setshowtelegrammodal(false)}
          setUserdata={setUserdata}
          userdata={userdata}
          checkIntegration={checkIntegration}
          chatbotId={chatbotId}
          chatBotData={chatBotData}
          connectChatbotChannels={connectChatbotChannels}
          setchatBotData={setchatBotData}
        />
      </div>
      <div className="p-0">
        <DisconnectModel
          open={showFacebookmodal}
          closeModal={() => setshowFacebookmodal(false)}
          handleDelete={disConnectFacebook}
          text={"facebook"}
          isLoading={disconnectLoading}

        />
      </div>
      <div className="p-0">
        <DisconnectModel
          open={showInstagrammodal}
          closeModal={() => setshowInstagrammodal(false)}
          handleDelete={disConnectInstagram}
          text={"instagram"}
          isLoading={disconnectLoading}
        />
      </div>
      <div className="p-0">
        <DisconnectModel
          open={showWhatsappmmodal}
          closeModal={() => setshowWhatsappmodal(false)}
          handleDelete={disConnectWhatsapp}
          text={"whatsapp"} n
          isLoading={disconnectLoading}
        />
      </div>

      {!isSubscried && <NoSubscriptionModal userdata={userdata} />}
    </section >
  );
};

export default Channels;
