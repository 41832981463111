import React, { useState, useCallback, useRef, useEffect } from "react";
import Tree from "react-d3-tree";
import DeleteNodeModal from "./modals/DeleteNodeModal";
import CustomNode from "./CustomNode";
import AddNodeModal from "./modals/AddNodeModal";
import EditNodeModal from "./modals/EditNodeModal";
import styles from "./chatbot.module.css";
import styles1 from "src/components/apps/liveChat/chatList/chat.module.css";
import "./styles.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import chatBg from "../../assets/images/chatBuilder/chat_bg.png";
import useChatbotTree from "./hooks/useChatbotTree";
import { ButtonLoader, ButtonLoaderLeft } from "src/components/forms/ButtonLoader";
import ChatbotProfile from "./ChatbotProfile";
import { useSelector } from "react-redux";
import PublishModal from "src/components/custom/PublishModal";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import HistoryIcon from '@mui/icons-material/History';
import VersioningList from "./VersioningList";
import DrawerDynamicWidth from "src/components/custom/DrawerDynamicWidth";
import ConfirmationModal from "src/components/custom/ConfirmationModel";
import { useLocation, useNavigate } from "react-router-dom";
import chatbot from 'src/assets/images/chatBuilder/ChatbotIcon.svg';
import useChatbotVersion from "./hooks/useChatbotVersion";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Channels from "../connectChat/channels";

const ChatBotTree = ({ agencyData, chatbotPageType, chatbotId, versionId, treeData, chatBotData, setchatBotData, getUserListData, isLoading, isAccountSetup = false, handleNext, settings }) => {
  
  const userObj = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
  const [orgData, setOrgData] = useState(treeData);
  const [toggleDelete, setDeleteToggle] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [editorOpen, setEditorOpen] = useState(0);
  const [publishModal, setPublishModal] = useState(false);
  const [toggleAddChildForm, setToggleAddChildForm] = useState(false);
  const [toggleEditChildForm, setToggleEditChildForm] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showdelete, setShowdelete] = useState(false);
  const [isTextField, setIsTextField] = useState(false);
  const [chatbotTitle, setchatbotTitle] = useState(chatBotData?.name);
  const inputRef = useRef(null);
  const location = useLocation()
  const { changeChatbotName } = useChatbotVersion();

  const { handleRestore, isPublishLoading, publishChatbotTree, publishedChatbot, toggleAllChildren, mergeNodetoTree,
    captureFields, getcaptureFields, setPublishedChatbot, getNodeLabel } = useChatbotTree();

  const handleAddButtonClick = useCallback((nodeData) => {
    setSelectedNode(nodeData);
    setToggleAddChildForm(true);
  }, []);

  const handleEditButtonClick = useCallback((nodeData) => {
    setSelectedNode(nodeData);
    setToggleEditChildForm(true);
  }, []);

  const handleDeleteButtonClick = useCallback(
    async (nodeData) => {
      setSelectedNode(nodeData);
      setDeleteToggle(true);
    },
    [orgData]
  );

  const toggleVisibility = (selectedNodeData) => {
    const updatedNodeData = toggleAllChildren(selectedNodeData, selectedNodeData?.children[0].isVisible);
    const updatedNodeTreeData = mergeNodetoTree(orgData, updatedNodeData);
    setOrgData((prevData) => updatedNodeTreeData);
  };

  const renderCustomNodeElement = useCallback(
    ({ nodeDatum, toggleNode }) => (
      <>
        {nodeDatum.isVisible === undefined || nodeDatum.isVisible === true ? (
          <CustomNode
            nodeData={nodeDatum}
            toggleNode={() => { toggleNode() }}
            toggleVisibility={toggleVisibility}
            onAddButtonClick={handleAddButtonClick}
            onEditButtonClick={handleEditButtonClick}
            onDeleteButtonClick={handleDeleteButtonClick}
            getNodeLabel={getNodeLabel}
            chatbotPageType={chatbotPageType}
          />
        ) : null}
      </>
    ),
    [handleAddButtonClick, handleEditButtonClick, handleDeleteButtonClick]
  );

  const onAddNodeSuccess = (newNodeData) => {
    setOrgData(newNodeData);
    setToggleAddChildForm(false);
  };

  const onEditNodeSuccess = (newNodeData) => {
    setOrgData(newNodeData);
    setToggleEditChildForm(false);
  };

  const pathClassFunc = (linkData, orientation) => {
    const { source, target } = linkData;

    if (source.data?.children[0]?.isVisible === false) {
      return 'path-color-1';
    } else {
      return '';
    }
  }

  const handleDrawer = (val) => {
    setIsDrawerOpen(val);
  };

  const handleFocusOut = (event) => {
    // Check if the click is outside the input field
    if (inputRef.current && !inputRef.current.contains(event.relatedTarget)) {
      setIsTextField(false)
      changeChatbotName(chatbotTitle, chatBotData._id, setchatBotData);
    }
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      try {
        setIsTextField(false)
        changeChatbotName(chatbotTitle, chatBotData._id, setchatBotData);
      } catch (error) {
        console.error('Error updating version title:', error);
      }
    }
  };

  useEffect(() => {
    if (chatBotData?.name) {
      setchatbotTitle(chatBotData?.name);
    }
  }, [chatBotData]);

  useEffect(() => {
    getcaptureFields()
  }, [])


  return (
    <>
      <div className="bg-white">
        <div className={styles.custom_header + " App pb-0  pt-0 "}>

          <div className={styles.chatbotheader + " " + ` ${isAccountSetup ? " px-5 " : "px-0"} row mx-0 align-items-center`}>
            <div className={(isAccountSetup || settings ? "col-md-6" : "col-md-4") + " text-start d-flex align-items-center"}>
              {!isAccountSetup && !settings && <div onClick={() => navigate(location.pathname.includes("settings") ? "/settings/bots" : "/bots")} className={styles.backIcon}>
                <ArrowBackIcon style={{ fontSize: "20px", color: "#d7376a" }} />
              </div>}
              {!isAccountSetup && !settings && <div className={styles.chatbotImage}>
                <img src={chatbot} alt="" />
              </div>}
              {<div className={(isAccountSetup || settings) && "ms-4"}>
                {!isTextField ?
                  <div onClick={() => { setIsTextField(true) }} className={styles.chatbotName}>{chatbotTitle}  </div>
                  :
                  <input
                    value={chatbotTitle}
                    className={styles.chatbotnameUpdate}
                    onChange={(e) => { setchatbotTitle(e.target.value) }}
                    onBlur={handleFocusOut} // Handles the focus out event
                    ref={inputRef}
                    onKeyPress={handleKeyPress} // Handles key press events
                    autoFocus
                    type="text" name="" id="" />
                }
              </div>}

            </div>

            {!isAccountSetup && !settings &&
              <div className="col-md-4 text-end" style={{ padding: "0.6rem" }}>
                <div className={styles1.chatfilter_switch + " p-0 justify-content-center"} style={{ height: "2rem" }}>
                  <div
                    className={styles1.chatfilter_switch_tab + " " + styles.chatbotnavigationbar + "  " + (editorOpen === 0 ? styles.chatbot_switch_tab_active : "")}
                    onClick={() => { setEditorOpen(0); }}
                    style={{ width: "unset" }}
                  >
                    Build
                  </div>

                  <div className="text-start" style={{ width: "21px", }}>
                    <NavigateNextIcon className="mt-1" style={{ fontSize: "14px" }} />
                  </div>

                  <div onClick={() => { setEditorOpen(1); }} style={{ width: "unset" }}
                    className={styles1.chatfilter_switch_tab + " " + styles.chatbotnavigationbar + " " + (editorOpen === 1 ? styles.chatbot_switch_tab_active : "")} >
                    Setting

                  </div>
                  {chatbotPageType === "chatbot" && <div className="text-start" style={{ width: "21px", }}>
                    <NavigateNextIcon className="mt-1" style={{ fontSize: "14px" }} />
                  </div>}

                  {chatbotPageType === "chatbot" && <div onClick={() => { setEditorOpen(2); }} style={{ width: "unset" }}
                    className={styles1.chatfilter_switch_tab + " " + styles.chatbotnavigationbar + " " + (editorOpen === 2 ? styles.chatbot_switch_tab_active : "")} >
                    Connections
                  </div>}
                </div>
              </div>}

            <div className={(isAccountSetup || settings ? "col-md-6" : "col-md-4") + " text-end"}>
              {isAccountSetup ?
                <div className="d-flex align-items-center justify-content-end">
                  <div className="mt-1">
                    <ButtonLoaderLeft
                      onClickhandle={() => { handleNext("back") }}
                      // isLoading={isLoading}
                      buttonName="Back"
                      loaderColor={"white"}
                      className={styles.back_btn}
                      icon={<KeyboardArrowLeftIcon />}
                    ></ButtonLoaderLeft>
                  </div>
                  <ButtonLoader
                    buttonName={publishedChatbot ? "Published" : "Publish"}
                    isLoading={isPublishLoading}
                    className={styles.setup_publish_btn + " mt-1"}
                    onClickhandle={() => publishChatbotTree(setPublishModal, chatbotId, setchatBotData)}
                  />
                  <div style={{ marginLeft: "10px" }}>
                    <ButtonLoader
                      onClickhandle={() => { handleNext() }}
                      // isLoading={isLoading}
                      buttonName="Next"
                      loaderColor={"white"}
                      className={styles.save_btn + " mt-1"}
                      icon={<KeyboardArrowRightIcon />}
                    ></ButtonLoader>
                  </div>
                </div>
                :
                chatbotPageType !== 'compare' && <div className="d-flex align-items-center justify-content-end">
                  <div onClick={() => handleDrawer(true)} className={styles.historyIcon + " mx-3"}>
                    <HistoryIcon color="#33405ec8" />
                  </div>
                  {chatbotPageType !== 'restore' ? <ButtonLoader
                    buttonName={publishedChatbot ? "Published" : "Publish"}
                    isLoading={isPublishLoading}
                    className={styles.publish_btn}
                    onClickhandle={() => publishChatbotTree(setPublishModal, chatbotId, setchatBotData)}
                  /> : <>
                    <ButtonLoader
                      buttonName={"Cancel"}
                      isLoading={isPublishLoading}
                      className={styles.cancelButton}
                      onClickhandle={() => navigate(`/chatbot-builder?chatbot=${chatbotId}`)}
                    />
                    <ButtonLoader
                      buttonName={"Restore"}
                      isLoading={isPublishLoading}
                      className={styles.publish_btn}
                      onClickhandle={() => setShowdelete(true)}
                    />
                  </>}
                </div>
              }

            </div>
          </div>

          {editorOpen === 0 && (
            <div
              id="treeWrapper"
              style={{
                width: "100%",
                height: "calc(100vh - 58px)",
                backgroundImage: `url(${chatBg})`,
                paddingTop: "50px"
              }}
            >
              {orgData && orgData?.length ? (
                <Tree
                  className={styles.tree_view}
                  data={orgData}
                  orientation="vertical"
                  allowForeignObjects={true}
                  separation={{ siblings: 2, nonSiblings: 2 }}
                  pathFunc="step"
                  renderCustomNodeElement={renderCustomNodeElement}
                  translate={{ x: window.innerWidth / 2, y: 80 }}
                  collapsible={true}
                  pathClassFunc={pathClassFunc}
                />
              ) : (
                <div>
                  Add Tree
                  <button onClick={() => setToggleAddChildForm(true)}>
                    {" "}
                    a Node
                  </button>
                </div>
              )}
            </div>
          )}

          {editorOpen === 1 && (
            <div style={{
              width: "100%",
              height: "calc(100vh - 58px)",
              backgroundI: 'white',
              paddingTop: "50px"

            }}>
              <ChatbotProfile chatbotPageType={chatbotPageType} chatbotId={chatbotId}
                versionId={versionId} chatBotData={chatBotData} setchatBotData={setchatBotData} getUserListData={getUserListData} isLoading={isLoading} isAccountSetup={isAccountSetup} />
            </div>
          )}

          {editorOpen === 2 && (
            <div style={{
              width: "100%",
              backgroundI: 'white',
              paddingTop: "50px"
            }}>
              <Channels chatbotPageType={chatbotPageType} chatbotId={chatbotId} chatBotData={chatBotData} setchatBotData={setchatBotData} />
            </div>
          )}
        </div>

        {selectedNode && <AddNodeModal
          toggleAddChildForm={toggleAddChildForm}
          orgData={orgData}
          selectedNode={selectedNode}
          closeModal={() => setToggleAddChildForm(false)}
          onSuccess={onAddNodeSuccess}
          setPublishedChatbot={setPublishedChatbot}
          categories={userObj?.departmentRole}
          categoriesProperty={userObj?.departmentType}
          chatbotId={chatbotId}
          chatBotData={chatBotData}
          agencyData={agencyData}
          captureFields={captureFields}
        />}

        {toggleEditChildForm && (
          <EditNodeModal
            toggleEditChildForm={toggleEditChildForm}
            orgData={orgData}
            selectedNode={selectedNode}
            closeModal={() => setToggleEditChildForm(false)}
            onSuccess={onEditNodeSuccess}
            setPublishedChatbot={setPublishedChatbot}
            categories={userObj?.departmentRole}
            categoriesProperty={userObj?.departmentType}
            chatbotId={chatbotId}
            agencyData={agencyData}
            captureFields={captureFields}
          />
        )}

        {toggleDelete && (
          <DeleteNodeModal
            isOpen={toggleDelete}
            setDeleteToggle={setDeleteToggle}
            orgData={orgData}
            setOrgData={setOrgData}
            targetNode={selectedNode}
            setPublishedChatbot={setPublishedChatbot}
          />
        )}

        {publishModal && (
          <PublishModal
            open={publishModal}
            closeModal={() => setPublishModal(false)}
          />
        )}
      </div >

      <ConfirmationModal
        open={showdelete}
        text={"Are you sure you want to restore this version ?"}
        closeModal={() => { setShowdelete(false) }}
        handleComfirm={() => { handleRestore(setShowdelete, chatbotId, versionId); }}
        isLoading={isPublishLoading}
      />

      <DrawerDynamicWidth
        isDrawerOpen={isDrawerOpen}
        anchor={"right"}
        handleDrawer={() => handleDrawer(false)}
        drawerTitle={"Publish history"}
        width={400}
      >
        <VersioningList
          chatBotData={chatBotData}
          setchatBotData={setchatBotData}
          chatbotPageType={chatbotPageType}
          chatbotId={chatbotId}
          versionId={versionId}
        />
      </DrawerDynamicWidth>
    </>
  );
};

export default ChatBotTree;
