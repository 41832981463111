import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useCallback, useState, useEffect } from 'react'
import Style from "../AddProperty/AddProperty.module.css";
import { ButtonLoader } from "src/components/forms/ButtonLoader";
import TextInput from "src/components/forms/TextInput";
import AstericSymbol from "src/components/custom/astericSymbol";
import FormGroup from '@mui/material/FormGroup';
import CommonCheckbox from "src/components/forms/CommonCheckbox";
import { useFormik } from 'formik';
import * as Yup from "yup";
import ErrorMsg from "src/components/custom/ErrorMsg";
import { debounce } from "../../../../../src/helper/commonHelp";
import Axios from 'src/utils/axios';
import { removeNumericBeforeComma } from 'src/utils/Validator';


const UpdatePropertyAddress = ({ setPlacesData, placesData,
    fetchPlacesList, setEditInfo, propertyData, UpddateAddressService, propertyId
}) => {
    const [showList, setShowList] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        formik.setFieldValue('propertyAddress', propertyData?.propertyAddress?.addressLine1);
        formik.setFieldValue('county', propertyData?.propertyAddress?.county);
        formik.setFieldValue('country', propertyData?.propertyAddress?.country);
        formik.setFieldValue('city', propertyData?.propertyAddress?.city);
        formik.setFieldValue('addressLine', propertyData?.propertyAddress?.addressLine1);
        formik.setFieldValue('postalCode', propertyData?.propertyAddress?.postalCode);
        formik.setFieldValue('searchableArea', propertyData?.searchableArea?.name);
        formik.setFieldValue('lat', propertyData?.propertyAddress?.geoCoordinates?.coordinates[1]);
        formik.setFieldValue('lng', propertyData?.propertyAddress?.geoCoordinates?.coordinates[0]);

    }, [])



    const handleBackButton = () => {
        setEditInfo(true)
    }


    const fetchPlaceDetail = (place_id, completeAddress) => {
        Axios.get(`/places/place-detail-by-address-id?place_id=${place_id}`)
            .then((res) => {
                formik.setFieldValue("lat", res?.data?.data?.raw.geometry?.location?.lat);
                formik.setFieldValue("lng", res?.data?.data?.raw.geometry?.location?.lng);
                formik.setFieldValue("city", res?.data?.data?.address?.City);
                formik.setFieldValue("country", res?.data?.data?.address?.Country);
                formik.setFieldValue("postalCode", res?.data?.data?.address?.zip);
            })
            .catch((err) => {
                setError(err);
            });
    };


    const userTxtChangeDebounce = useCallback(
        debounce((txtval) => fetchPlacesList(txtval), 800),
        []
    );

    const onChangeAddress = (e) => {
        formik.setFieldValue("propertyAddress", e.target.value);
        if (e.target.value.length > 0) {
            userTxtChangeDebounce(e.target.value);
            setShowList(true);
        }
    };

    const handleLocationClick = (location) => {
        if (location) {
            fetchPlaceDetail(location.place_id, location.description);
            formik.setFieldValue("propertyAddress", location.description);
            formik.setFieldValue("addressLine", removeNumericBeforeComma(location.description));
        }
        setPlacesData([]);
        setShowList(false);
    };
    const validationSchema = Yup.object({
        propertyAddress: Yup.string().required("Property Address is required.")
            .test(
                "not-start-with-space",
                "Property Address cannot start with a space.",
                (value) => {
                    return !value || !value.startsWith(" ");
                }
            ).min(2, "Property Address must be at least 2 characters long."),

        postalCode: Yup.string().required("postal Code is required."),
        city: Yup.string().required("City is required."),
        country: Yup.string().required("Country is required."),

    });

    const formik = useFormik({
        initialValues: {
            propertyAddress: "",
            postalCode: "",
            city: "",
            country: "",
            county: "",
            searchableArea: "",
            addressLine: "",
            lat: "",
            lng: "",
            displayAddress: ""
        },
        validationSchema,
        validateOnChange: true,
        onSubmit: async (values) => {
            const propertyAddress = {
                addressLine1: values?.propertyAddress + ", " + values?.postalCode,
                postalCode: values?.postalCode,
                city: values?.city,
                country: values?.country,
                county: values?.county,
                addressLine: "",
                geoCoordinates: {
                    type: "Point",
                    coordinates: [values.lng, values.lat]
                },
                displayAddress: values?.addressLine,

            }
            setEditInfo(true)
            const formData = new FormData();
            formData.append("propertyAddress", JSON.stringify(propertyAddress));
            formData.append("searchableArea", values.searchableArea);
            formData.append("propertyId", propertyId);
            UpddateAddressService(formData, 3);
        },
    });

    return (
        <div>
            <div style={{ padding: '10px 40px' }}>
                <Box>
                    <Typography variant="h6" className={Style.listDetailsFont}>Property Address</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormGroup>
                                <CommonCheckbox
                                    label={
                                        <span className="checkboxlabelwithline">
                                            Is the Seller address same
                                        </span>
                                    }
                                // checked={isChecked}
                                // onChange={handleCheckboxChange}
                                />

                            </FormGroup>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <label className={Style.labelform}>
                                Address <AstericSymbol />
                            </label>
                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="propertyAddress"
                                value={formik.values.propertyAddress}
                                onChange={onChangeAddress}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                placeholder="Address"
                                size={"small"}
                            />
                            {showList &&
                                (placesData?.length > 0 ? (
                                    <div className={Style.place_card} onMouseLeave={()=>setShowList(false)}>
                                        {placesData?.map((place, index) => (
                                            <div
                                                className={Style.placeList}
                                                key={index}
                                            >
                                                <p
                                                    className="mb-0"
                                                    key={index}
                                                    onClick={() =>
                                                        handleLocationClick(place)
                                                    }
                                                >
                                                    {place.description}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    error && (
                                        <div className={Style.place_card}>
                                            {" "}
                                            <div className={Style.placeList}>
                                                {error}
                                            </div>
                                        </div>
                                    )
                                ))}
                            <ErrorMsg hasError={formik.touched.propertyAddress && formik.errors.propertyAddress} > {formik.errors.propertyAddress} </ErrorMsg>

                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <label className={Style.labelform}>
                                Display Address <AstericSymbol />
                            </label>
                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="addressLine"
                                value={formik.values.addressLine}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size={"small"}
                            />

                            <ErrorMsg hasError={formik.touched.propertyAddress && formik.errors.propertyAddress} > {formik.errors.propertyAddress} </ErrorMsg>

                        </Grid>

                        <Grid item xs={12} sm={12} md={6} className="pt-0">
                            <label className={Style.labelform}>
                                Post Code <AstericSymbol />
                            </label>


                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="postalCode"
                                value={formik.values.postalCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size={"small"}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="pt-0">
                            <label className={Style.labelform}>
                                Town/City <AstericSymbol />
                            </label>

                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size={"small"}
                            />
                            <ErrorMsg hasError={formik.touched.city && formik.errors.city} > {formik.errors.city} </ErrorMsg>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="pt-0">
                            <label className={Style.labelform}>
                                Country <AstericSymbol />
                            </label>

                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="country"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size={"small"}
                            />
                            <ErrorMsg hasError={formik.touched.country && formik.errors.country} > {formik.errors.country} </ErrorMsg>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="pt-0">
                            <label className={Style.labelform}>
                                County
                            </label>


                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="county"
                                value={formik.values.county}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size={"small"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className="pt-0">
                            <label className={Style.labelform}>
                                Searchable Area
                            </label>


                            <TextInput
                                customClass={Style.input_custom + " w-100 "}
                                type="text"
                                name="searchableArea"
                                value={formik.values.searchableArea}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                variant="outlined"
                                size={"small"}
                            />

                        </Grid>
                    </Grid>
                    <hr />
                    <div className={Style.propertycss1}>
                        <div style={{ marginRight: '12px' }}>
                            <Button style={{ padding: "8px" }} color="secondary"
                                className={Style.delete_btn + " " + Style.edit_btn_Property}
                                onClick={handleBackButton}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div>
                            <ButtonLoader
                                type={"button"}
                                buttonName="Save"
                                loaderColor={"white"}
                                className={Style.Add_btn}
                                onClickhandle={formik.handleSubmit}

                            >
                            </ButtonLoader>
                        </div>
                    </div>
                </Box>
            </div>
        </div>
    )
}

export default UpdatePropertyAddress