import React from "react";
import { Box, Grid } from "@mui/material";

import Style from "../broadcast.module.css";

import CheckIcon from "@mui/icons-material/Check";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { LuReplyAll } from "react-icons/lu";
import { MdOutlineCancelScheduleSend } from "react-icons/md";

const CountCards = ({broadcastCount, setStatus, status}) => {

  const handleBox = (value) => {
    if(status === value){
      setStatus("all");
    }else{
      setStatus(value);
    }
    
  }

  return (
    <div className="mb-4">
          <Box className="">
            <Grid container spacing={3} className="mt-1">
              <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                <div className={Style.overview_box +  ` ${status === "SENT" ? Style.selected: ""}`} onClick={() => {handleBox("SENT")}}>
                  <div className="">
                    <h3>{broadcastCount?.sentCount || 0}</h3>
                    <p>Sent</p>
                  </div>
                  <div className={Style.icon}> <CheckIcon /> </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                <div className={Style.overview_box + ` ${status === "DELIVERED" ? Style.selected: ""}`} onClick={() => {handleBox("DELIVERED")}}>
                  <div className="">
                    <h3>{broadcastCount?.deliveredCount || 0}</h3>
                    <p>Delivered</p>
                  </div>
                  <div className={Style.icon}> <DoneAllIcon /></div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                <div className={Style.overview_box +  ` ${status === "READ" ? Style.selected: ""}`} onClick={() => {handleBox("READ")}}>
                  <div className="">
                    <h3>{broadcastCount?.readCount || 0}</h3>
                    <p>Read</p>
                  </div>
                  <div className={Style.icon}>  <RemoveRedEyeOutlinedIcon /> </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                <div className={Style.overview_box +  ` ${status === "REPLIED" ? Style.selected: ""}`} onClick={() => {handleBox("REPLIED")}}>
                  <div className="">
                    <h3>{broadcastCount?.repliedCount || 0}</h3>
                    <p>Replied</p>
                  </div>
                  <div className={Style.icon}> <LuReplyAll /> </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2.4} lg={2.4}>
                <div className={Style.overview_box +  ` ${status === "FAILED" ? Style.selected: ""}`} onClick={() => {handleBox("FAILED")}}>
                  <div className="">
                    <h3>{broadcastCount?.failedCount || 0}</h3>
                    <p>Failed</p>
                  </div>
                  <div className={Style.icon}> <MdOutlineCancelScheduleSend /> </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
  );
};

export default CountCards;
