import {
  contactCategory,
  leadStatusOption,
  priorityList,
} from "src/constants/formPicker";

export const debounce = (func, timeout = 800) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const getLabelByValue = (type, value) => {
  let data;
  if (type === "lead") {
    data = contactCategory;
  } else if (type === "leadStatus") {
    data = leadStatusOption;
  } else if (type === "leadPriority") {
    data = priorityList;
  }
  const found = data.find((item) => item.value === value);
  return found ? found.label : null;
};

export const getCategoryObject = (array) => {
  return array?.map((item) => {
    const label = item
      ?.split("-")
      ?.map((word) => word?.toString()?.charAt(0)?.toUpperCase() + word?.slice(1))
      ?.join(" ");
    return { label, value: item };
  });
};


export const getCategoryDisplayName = (val) => {
    const label = val
      ?.split("-")
      ?.map((word) => word?.toString()?.charAt(0)?.toUpperCase() + word?.slice(1))
      ?.join(" ");
    return label;

};

export function removeTextInParentheses(input) {
  return input.replace(/\s*\([^)]*\)/g, "").trim();
}

export function shortenFileNameWithMiddleEllipsis(fileName, maxLength) {
  if (fileName.length <= 10) return fileName; // Return as-is if <= 10 characters

  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1) return fileName; // No extension found

  const extension = fileName.slice(lastDotIndex); // Extract extension
  const nameWithoutExtension = fileName.slice(0, lastDotIndex); // Extract name

  if (fileName.length <= maxLength) return fileName; // No need to shorten

  const ellipsis = "...";
  const charsToShow = maxLength - extension.length - ellipsis.length;

  if (charsToShow <= 0) {
      // If the maxLength is too small to even accommodate the ellipsis and extension
      return fileName.slice(0, maxLength - extension.length) + extension;
  }

  const startChars = Math.ceil(charsToShow / 2);
  const endChars = Math.floor(charsToShow / 2);

  return (
      nameWithoutExtension.slice(0, startChars) +
      ellipsis +
      nameWithoutExtension.slice(-endChars) +
      extension
  );
}